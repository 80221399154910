import { connect } from 'react-redux'
import InstitutionMain from './InstitutionMain.js';

const mapStateToProps = (state) => {
  return {
    institution: state.login.bruger.institution,
    bruger: state.login.bruger,
  }
};

const mapDispatchToProps  = () => {
  return {};
};

const InstitutionConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)((InstitutionMain));

export default InstitutionConnector;