import React from 'react';
import {Link} from "react-router-dom";
import {urlRedigerMedarbejder} from "../../util/constants.js";
import MedarbejderOversigt from "./MedarbejderOversigt";
import {isEmptyArray} from "../../util/util";

function Medarbejder(props) {

  function handleClick(){
    const{medarbejderRowClick, medarbejder} = props;
    medarbejderRowClick(medarbejder.id);
  }

  function openModal(){
    const{medarbejder, visMedarbejderStamkortModal} = props;
    visMedarbejderStamkortModal(medarbejder.id);
  }

  function sletMedarbejderen(openSletMedarbejderModal, medarbejder, setMedarbejderAsSelected, resetSelectedMedarbejderRows){
    resetSelectedMedarbejderRows();
    setMedarbejderAsSelected(medarbejder.id)
    openSletMedarbejderModal();
  }

  function importStatus(senesteEksportTid){
    const{medarbejder} = props;
    if(senesteEksportTid && senesteEksportTid > medarbejder.senestAendret) {
      return "opdateringsstatusGreen";
    } else {
      return "opdateringsstatusRed";
    }
  }

  function rolleNavn(rolle) {
    switch(rolle) {
      case "LAERER": return "Lærer";
      case "PAEDAGOG": return "Pædagog";
      case "VIKAR": return "Vikar";
      case "LEDER": return "Leder";
      case "LEDELSE": return "Ledelse";
      case "TAP": return "TAP";
      case "KONSULENT": return "Konsulent";
      default: return "";
    }
  }

    const {medarbejder, selectedMedarbejderRows, senesteEksport, openSletMedarbejderModal, setMedarbejderAsSelected, resetSelectedMedarbejderRows} = props;
    const checked = !isEmptyArray(selectedMedarbejderRows) && selectedMedarbejderRows.indexOf(medarbejder.id) >= 0;

    return(
        <tr>
          <td className="medarbejder-fornavn">{MedarbejderOversigt.getFornavn(medarbejder)}</td>
          <td className="medarbejder-efternavn">{MedarbejderOversigt.getEfternavn(medarbejder)}</td>
          <td className="medarbejder-roller">{medarbejder.medarbejder.roller.map(rolle => rolleNavn(rolle)).join(", ")}</td>
          <td className="centerText"><span className={"opdateringsStatus " + (senesteEksport !== null ? importStatus(senesteEksport.slut) : "")}></span></td>
          <td>&nbsp;
            <Link className="medarbejder-pen" to={urlRedigerMedarbejder + '/' + medarbejder.id}>
              <img src="img/ic_mode_edit_18px.svg" title="Rediger medarbejder"  alt="rediger"/>
            </Link>&nbsp;
            <img className="medarbejder-eye" title="Vis medarbejder" src="img/ic_visibility_24px.svg"style={{cursor: 'pointer'}} onClick={openModal} alt="vis"/>&nbsp;
            <img className="medarbejder-trash" title="Slet medarbejder" src="img/ic_delete_24px.svg"style={{cursor: 'pointer'}} onClick={()=> sletMedarbejderen(openSletMedarbejderModal, medarbejder, setMedarbejderAsSelected, resetSelectedMedarbejderRows)} alt="slet"/>&nbsp;
            <input className="medarbejder-select" type="checkbox" onChange={handleClick} checked={checked}/>
          </td>
        </tr>
    );
}

export default Medarbejder;
