import React, {Component} from 'react';

class OprulningKlasse extends Component {

  render() {
    const { oprulKlasse } = this.props;

    return (
      <tr>
        <td>{oprulKlasse.klasse.niveau}</td>
        <td>{oprulKlasse.nytNiveau}</td>
        <td>{oprulKlasse.klasse.gruppebetegnelse}</td>
        <td>{oprulKlasse.klasse.antalElever}</td>
      </tr>
    );
  }

}

export default OprulningKlasse