import React, {Component} from 'react'
import constants from '../util/constants.js';
import Klasser from "../pages/klasse/Klasser";
import AlertBar from "../util/AlertBar";

class KlasseMain extends Component{
  componentDidMount(){
    const {hentKlasser} = this.props;
    hentKlasser();
  }

  componentDidUpdate(){
    const{status, hentKlasser, sletterKlasser} = this.props;
    if(status === constants.status_gem_klasse_succes ||
      (status === constants.status_slet_klasser_succes && !sletterKlasser)){
      hentKlasser();
    }
  }

  render(){
    const {klasser, opretGemKlasse, instnr, sletKlasser, klasseRowClick, selectedKlasseRows, showKlasseModal, alerts, status, klasseSletMarkeredeClick, klasseSletEnkeltRowClick, klasseSletModalClose, setKlasseAsSelected, validerKlasseFejl, hentKlasser, nyKlasse, retKlasse, klasseId, senesteEksport} = this.props;

    return (
      <div className="stil-main-content">
        <AlertBar alerts={alerts}/>
        <h2>Klasser</h2>
        <Klasser
          klasser={klasser}
          gemKlasse={opretGemKlasse}
          instkode={instnr}
          klasseRowClick={klasseRowClick}
          selectedKlasseRows={selectedKlasseRows}
          sletKlasser={sletKlasser}
          showKlasseModal={showKlasseModal}
          status={status}
          klasseSletMarkeredeClick={klasseSletMarkeredeClick}
          klasseSletEnkeltRowClick={klasseSletEnkeltRowClick}
          klasseSletModalClose={klasseSletModalClose}
          setKlasseAsSelected={setKlasseAsSelected}
          validerKlasseFejl={validerKlasseFejl}
          hentKlasser={hentKlasser}
          nyKlasse={nyKlasse}
          retKlasse={retKlasse}
          klasseId={klasseId}
          senesteEksport={senesteEksport}
        />
      </div>
    )
  }

}

export default KlasseMain;
