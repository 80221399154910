import React, {Component} from 'react';
import {isEmptyArray, isEmpty} from "../../util/util.js";

function editKlasse(klasse, klasseId) {
  if (isEmpty(klasse) || isEmpty(klasseId))
    return false;
  return klasse.id === klasseId;
}

class Klasse extends Component {
  constructor(props){
    super(props);
    const{klasse} = this.props;
    this.state = {
      id: klasse!=null? klasse.id:null,
      niveau: klasse!=null? klasse.niveau:'0',
      gruppebetegnelse: klasse!=null? klasse.gruppebetegnelse:'',
      gruppekode: klasse!=null?klasse.gruppekode:null,
      isEdit: false,
    };
    this.editKlasse = this.editKlasse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.annuller = this.annuller.bind(this);
    this.validate = this.validate.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  editKlasse(){
    this.setState({
      isEdit: true
    });
  }

  handleChange(event){
    switch (event.target.id) {
      case 'niveau':
        this.setState({niveau: event.target.value});
        break;
      case 'gruppebetegnelse':
        this.setState({gruppebetegnelse: event.target.value});
        break;
      default:
        break;
    }
  }

  validate(){
    const fejl = [];
    if(this.state.gruppebetegnelse === null || this.state.gruppebetegnelse.trim().length === 0)
      fejl.push("Klassenavn/bogstav skal angives");
    return fejl;
  }

  handleSave(){
    const{gemKlasse, instkode, klasse, skjulNyKlasse, validerKlasseFejl} = this.props;
    const fejl = this.validate();
    if(fejl.length === 0){
      gemKlasse({
        id: this.state.id,
        gruppebetegnelse: this.state.gruppebetegnelse,
        niveau: this.state.niveau,
        gruppekode: this.state.gruppekode,
        gruppenavn: this.state.niveau + this.state.gruppebetegnelse,
        instkode: instkode,
      });
      if(klasse !== null){
        this.setState({
          isEdit: false,
        });
      }else{
        this.setState({
          isEdit: false,
          id: null,
          niveau: '0',
          gruppebetegnelse: '',
          gruppekode: null,
        });
        skjulNyKlasse();
      }
    }else {
      validerKlasseFejl(fejl);
    }
  }

  annuller(){
    const{klasse, skjulNyKlasse, hentKlasser} = this.props;
    this.setState({
      niveau: klasse!=null? klasse.niveau:'0',
      gruppebetegnelse: klasse!=null? klasse.gruppebetegnelse:'',
      gruppekode: klasse!=null?klasse.gruppekode:null,
      isEdit: false,
    });
    if(skjulNyKlasse !== undefined)
      skjulNyKlasse();
    hentKlasser();
  }

  handleClick(){
    const{klasseRowClick, klasse} = this.props;
    klasseRowClick(klasse.id);
  }

  submit = (klasseOld, sletKlasser) => {
    const{setKlasseAsSelected, klasse, klasseSletEnkeltRowClick} = this.props;
    setKlasseAsSelected(klasse.id);
    klasseSletEnkeltRowClick();
  };

  importStatus(senesteEksportTid){
    const{klasse} = this.props;
    if(senesteEksportTid && senesteEksportTid > klasse.senestAendret) {
      return "opdateringsstatusGreen";
    } else {
      return "opdateringsstatusRed";
    }
  }


  render(){

    const{klasse, show, sletKlasser, selectedKlasseRows, retKlasse, klasseId, senesteEksport} = this.props;
    const checked=!isEmptyArray(selectedKlasseRows) && selectedKlasseRows.indexOf(klasse.id) >= 0;

    if(editKlasse(klasse, klasseId) || (klasse === null && show === true)) {
      return(
        <tr>
          <td>
            <select id="niveau" value={this.state.niveau} onChange={this.handleChange}>
              <option id="klassedt" value="DT">Dagtilbud</option>
              <option id="klasse0" value="0">0</option>
              <option id="klasse1" value="1">1</option>
              <option id="klasse2" value="2">2</option>
              <option id="klasse3" value="3">3</option>
              <option id="klasse4" value="4">4</option>
              <option id="klasse5" value="5">5</option>
              <option id="klasse6" value="6">6</option>
              <option id="klasse7" value="7">7</option>
              <option id="klasse8" value="8">8</option>
              <option id="klasse9" value="9">9</option>
              <option id="klasse10" value="10">10</option>
              <option id="klasseu1" value="U1">Ungdomsuddannelse 1</option>
              <option id="klasseu2" value="U2">Ungdomsuddannelse 2</option>
              <option id="klasseu3" value="U3">Ungdomsuddannelse 3</option>
              <option id="klasseu4" value="U4">Ungdomsuddannelse 4</option>
              <option id="klassevu" value="VU">Voksenuddannelse</option>
              <option id="klasseandet" value="Andet">Andet</option>
            </select>
          </td>
          <td><input id="gruppebetegnelse" type="text" value={this.state.gruppebetegnelse} maxLength='75' onChange={this.handleChange} /></td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <button className="stil-primary-button button" type="button" id="button-klasse-annuller" onClick={this.annuller}>Annuller</button>
            <button className="stil-primary-button button margleft10" type="button" id="button-gem" onClick={this.handleSave}>Gem</button>
          </td>
        </tr>
      );
    } else if (klasse != null) {
      return (
        <tr>
          <td>{klasse.niveau}</td>
          <td>{klasse.gruppebetegnelse}</td>
          <td>{klasse.antalElever}</td>
          <td className="centerText">
            <span className={"opdateringsStatus " + (senesteEksport !== null ? this.importStatus(senesteEksport.slut) : "")}></span>
          </td>
          <td>
            <img className="klasse-pen" title="Rediger klasse" src="img/ic_mode_edit_18px.svg" onClick={() => retKlasse(klasse.id)} style={{cursor: 'pointer'}} alt="rediger"/>&nbsp;
            <img className="klasse-trash" title="Slet klasse" src="img/ic_delete_24px.svg" onClick={() => this.submit(klasse, sletKlasser)} style={{cursor: 'pointer'}} alt="slet"/>&nbsp;
            <input className="klasse-select" type="checkbox" onChange={this.handleClick} checked={checked} />
          </td>
        </tr>
      );
    }
    return null;
  }
}

export default Klasse
