import React from 'react';

export default function SortingChevron(props) {
  const {active, direction} = props;
  if(active){
    if(direction === 'asc')
      return (<img src="img/ic_expand_less_18px_hvid.png" className="margbottom-5" alt=""/>);
    else
      return (<img src="img/ic_expand_more_18px_hvid.png" className="margbottom-5" alt=""/>);
  } else{
    return (<img src="img/ic_chevron_right_18px_hvid.png" className="margbottom-5" alt=""/>);
  }
}