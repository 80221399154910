import React, { useState} from 'react'
import Elev from "./Elev";
import constants from "../../util/constants.js";
import {Link} from "react-router-dom";
import SortingChevron from "../../util/SortingChevron";
import EksportStatus from "../../util/EksportStatus.js";

function ElevOversigt(props) {
  const [activeSort, setActiveSort] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  function sortFornavn(elever) {
    let dir = 'asc';
    if (activeSort === 'fornavn' && sortDirection === 'asc') {
      elever.reverse();
      dir = 'desc';
    } else {
      elever.sort(function (a, b) {
        return getFornavn(a).localeCompare(getFornavn(b));
      });
    }
    setActiveSort('fornavn')
    setSortDirection(dir)
  }

  function sortEfternavn(elever) {
    let dir = 'asc';
    if (activeSort === 'efternavn' && sortDirection === 'asc') {
      elever.reverse();
      dir = 'desc';
    } else {
      elever.sort(function (a, b) {
        return getEfternavn(a).localeCompare(getEfternavn(b));
      });
    }
    setActiveSort('efternavn')
    setSortDirection(dir)
  }

  function sortKlasse(elever) {
    let dir = 'asc';
    if (activeSort === 'klasse' && sortDirection === 'asc') {
      elever.reverse();
      dir = 'desc';
    } else {
      elever.sort(function (a, b) {
        if (a.elev && b.elev) {
          if (a.elev.klasse.niveau === b.elev.klasse.niveau)
            return a.elev.klasse.gruppenavn.localeCompare(b.elev.klasse.gruppenavn);
          else
            return a.elev.klasse.niveau - b.elev.klasse.niveau;
        }
        return 0;
      });
    }
    setActiveSort('klasse')
    setSortDirection(dir)
  }

  function sortImportStatus(elever) {
    let dir = 'asc';
    if (activeSort === 'importstatus' && sortDirection === 'asc') {
      elever.reverse();
      dir = 'desc';
    } else {
      elever.sort(function (a, b) {
        return b.senestAendret - a.senestAendret;
      });
    }
    setActiveSort('importstatus')
    setSortDirection(dir)
  }

  function getFornavn(elev) {
    return elev.stamdata.beskyttet ? elev.stamdata.aliasFornavn : elev.stamdata.fornavn;
  }

  function getEfternavn(elev) {
    return elev.stamdata.beskyttet ? elev.stamdata.aliasEfternavn : elev.stamdata.efternavn;
  }

  const {elever, selectedElevRows, setElevAsSelected, visElevModal, visElevStamkortModal, senesteEksport, elevRowClick, resetSelectedElevRows, openSletElevModal} = props;
  let selectedElevRowsLength = 0;
  if (selectedElevRows !== undefined) {
    selectedElevRowsLength = Object.keys(selectedElevRows).length;
  }

  let antalElever = 0;
  for (const elev of elever) {
    if (elev.elev) {
      ++antalElever;
    }
  }

  if (antalElever === 0) {
    return (
        <div>
          <h2>Elever</h2>
          <div>
            Der er ikke oprettet elever. Brug knappen "Ny elev".
          </div>
          <div className="buttongroup">
            <Link id="button-ny-elev" to={constants.urlOpretElev} className="stil-primary-button">Ny elev</Link>
          </div>
        </div>
    );
  }

  return (
      <div>
        <h2>Elever</h2>
        <div className="buttongroup">
          <Link id="button-ny-elev" to={constants.urlOpretElev} className="stil-primary-button">Ny elev</Link>
        </div>
        <table id={"elev-table"}>
          <tbody>
          <tr>
            <th id="header-Fornavn" onClick={() => sortFornavn(elever)}>Fornavn&nbsp;<SortingChevron
              active={activeSort === 'fornavn'} direction={sortDirection}/></th>
            <th id="header-Efternavn" onClick={() => sortEfternavn(elever)}>Efternavn&nbsp;<SortingChevron
              active={activeSort ==='efternavn'} direction={sortDirection}/></th>
            <th id="header-Klasse" onClick={() => sortKlasse(elever)}>Klasse&nbsp;<SortingChevron
              active={activeSort ==='klasse'} direction={sortDirection}/></th>
            <th>Kontaktperson</th>
            <th>Kontaktperson</th>
            <th id="header-Importstatus" onClick={() => sortImportStatus(elever)}>
              <div className="standard-tooltip">
                Importstatus&nbsp;<SortingChevron active={activeSort === 'importstatus'}
                                                  direction={sortDirection}/>
                <span className="standard-tooltiptext">
                    <EksportStatus senesteEksport={senesteEksport}/>
                  </span>
              </div>
            </th>
            <th>
              <img id="button-vis-elever" title="Vis valgte elever" className="visIkonEleverTop hand"
                   src="img/ic_visibility_24px_hvid.png" onClick={visElevModal} alt="vis valgte"/>&nbsp;
              <img id="button-slet-valgte" title="Slet valgte elever" className="sletIkonEleverTop hand"
                   src="img/ic_delete_24px_hvid.png" onClick={() => openSletElevModal()} alt="slet valgte"/>&nbsp;
              <span className="selectedCounter"> ({selectedElevRowsLength})</span>
            </th>
          </tr>
          {elever.filter(elev => elev.elev)
              .map(elev => {
              return (
                <Elev key={elev.lokalId} elev={elev} selectedElevRows={selectedElevRows}
                      setElevAsSelected={setElevAsSelected} visElevStamkortModal={visElevStamkortModal} elevRowClick={elevRowClick}
                      resetSelectedElevRows={resetSelectedElevRows} senesteEksport={senesteEksport} openSletElevModal={openSletElevModal} />
              );
          })}
          </tbody>
        </table>
      </div>
    );
}

export default ElevOversigt;
