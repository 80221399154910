import React, {Component} from 'react'
import InstitutionConnector from './InstitutionConnector.js';
import LoginBar from "../pages/LoginBar";
import AlertBar from "../util/AlertBar";
import OrgansiationModal from "../modals/OrganisationModal";

class LoginMain extends Component {

  componentDidMount() {
    this.props.hentBrugeroplysninger();
  }

  render() {
    const alerts = AlertBar.getAlertsFromQuery();
    const {login, forside, active, loggedIn, bruger, openSelectInstitutionModal, selectInstitution, hentInstitutioner, institutioner} = this.props;
    return (
      <div>
        <InstitutionConnector/>
        <OrgansiationModal login={login} forside={forside} selectInstitution={selectInstitution} hentInstitutioner={hentInstitutioner} institutioner={institutioner}/>
        <nav className="stil-topmenu">
          <AlertBar alerts={alerts} />
          <LoginBar loggedIn={loggedIn} bruger={bruger} institutioner={institutioner.institutioner} active={active} alerts={alerts} openSelectInstitutionModal={openSelectInstitutionModal}/>
        </nav>
      </div>
    );
  }
}

export default LoginMain;
