import React, {useEffect, useState} from 'react'
import ElevOversigt from "../pages/elever/ElevOversigt";
import ElevOversigtVisModal from "../modals/ElevOversigtVisModal";
import ElevStamkortModal from "../modals/ElevStamkortModal";
import AlertBar from "../util/AlertBar";
import ElevSletModal from "../modals/ElevSletModal";

function ElevOversigtMain(props){
    const [showSletElevModal, setShowSletElevModal] = useState(false);
    const {hentElever} = props;

    useEffect(() => {
        hentElever();
    }, [hentElever]);

    const openSletElevModal =() => {
        setShowSletElevModal(true)
    }

    const closeSletElevModal =() => {
        setShowSletElevModal(false)
    }

    const {elever, selectedElevRows, setElevAsSelected, visElevModal, sletElever, instnr, senesteEksport, elevRowClick, resetSelectedElevRows, closeElevOversigtVisModal} = props;
    return (
      <div className="stil-main-content">

        <AlertBar alerts={props.alerts} />

        <div id="elevOversigt">
        <ElevOversigt
            senesteEksport={senesteEksport}
            elever={elever}
            selectedElevRows={selectedElevRows}
            setElevAsSelected={setElevAsSelected}
            sletElever={sletElever}
            visElevStamkortModal={props.visElevStamkortModal}
            visElevModal={visElevModal}
            openSletElevModal={openSletElevModal}
            elevRowClick={elevRowClick}
            resetSelectedElevRows={resetSelectedElevRows}
        />
        </div>
        <ElevOversigtVisModal
            selectedElevRows={selectedElevRows}
            elever={elever}
            showElevModal={props.showElevModal}
            onElevModalClose={props.onElevModalClose}
            sletElever={sletElever}
            instnr={instnr}
            openSletElevModal={openSletElevModal}
            closeElevOversigtVisModal={closeElevOversigtVisModal}
        />
        <ElevStamkortModal
            elever={elever}
            selectedElevRows={selectedElevRows}
            showElevStamkortModal={props.showElevStamkortModal}
            lukElevStamkortModal={props.lukElevStamkortModal}
            sletElever={sletElever}
            openSletElevModal={openSletElevModal}
        />
        <ElevSletModal
              selectedElevRows={selectedElevRows}
              sletElever={sletElever}
              showSletElevModal={showSletElevModal}
              closeSletElevModal={closeSletElevModal}
        />
      </div>
    );
}

export default ElevOversigtMain;
