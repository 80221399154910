import React, {useEffect} from 'react'
import OpretMedarbejder from "../pages/medarbejdere/OpretMedarbejder";
import {useParams} from "react-router-dom";

function OpretMedarbejderMain(props) {
    let { id } = useParams();
    const{hentMedarbejder, aabenOpretMedarbejderSide} = props;

    useEffect(() => {
        if(id != null){
            hentMedarbejder(id);
        }
        aabenOpretMedarbejderSide();
    }, [hentMedarbejder, aabenOpretMedarbejderSide, id]);

    const{instnr, cprData, opretMedarbejder, opretMedarbejderValideringsfejl, status, match,
      redigerMedarbejder, retMedarbejder, alerts, opretterMedarbejder} = props;
    return (
        <div className="stil-main-content">
          <OpretMedarbejder
              instnr={instnr}
              cprData={cprData}
              opretMedarbejder={opretMedarbejder}
              opretMedarbejderValideringsfejl={opretMedarbejderValideringsfejl}
              status={status}
              match={match}
              hentetMedarbejder={redigerMedarbejder}
              retMedarbejder={retMedarbejder}
              alerts={alerts}
              opretterMedarbejder={opretterMedarbejder}
              id={id}
          />
        </div>
    )
}

export default OpretMedarbejderMain;