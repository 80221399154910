import constants from '../util/constants.js';

function doRensAlerts(prevType) {
    return (
        prevType === constants.HENT_ELEVER_SUCCES ||
        prevType === constants.HENT_ELEVER_FEJL ||
        prevType === constants.HENT_ELEV_FEJL ||
        prevType === constants.OPRET_ELEV_FEJL ||
        prevType === constants.RET_ELEV_FEJL ||
        prevType === constants.SLET_ELEVER_FEJL ||
        prevType === constants.ELEV_MODAL_SHOW_CLICK ||
        prevType === constants.SLET_ELEVER ||
        prevType === constants.RET_ELEV ||
        prevType === constants.SLET_KONTAKT ||
        prevType === constants.SLET_KONTAKT_SUCCES ||
        prevType === constants.RESET_SELECTED_ELEV_ROWS ||
        prevType === constants.SLET_ELEVER_SUCCES
    );
}

export function elevReducer(state = [], action) {
  let rensAlerts=doRensAlerts(state.previousType);

  switch(action.type) {
    case constants.HENT_KLASSER:
      return {...state, alerts: (rensAlerts ? null : state.alerts)  };
    case constants.HENT_ELEVER:
      return {...state, previousType: action.type, status: constants.status_hent_elever_start, henterElever: action.henterElever, redigerElev: undefined, elever: [], alerts: (rensAlerts ? null : state.alerts)  };
    case constants.HENT_ELEVER_SUCCES:
      return {...state, previousType: action.type, status: constants.status_hent_elever_succes, instnr: action.instnr, elever: action.elever, selectedElevRows: [] };
    case constants.HENT_ELEVER_FEJL:
      return {...state, previousType: action.type, status: constants.status_hent_elever_fejl, hentEleverFejl: action.hentEleverFejl, alerts: action.alerts };
    case constants.HENT_ELEV:
      return {...state, previousType: action.type, status: constants.status_hent_elev_start, henterElev: action.henterElev, alerts: null };
    case constants.HENT_ELEV_SUCCES:
      return {...state, previousType: action.type, status: constants.status_hent_elev_succes, instnr: action.instnr, redigerElev: action.hentetElev, selectedElevRows: [], alerts: null };
    case constants.HENT_ELEV_FEJL:
      return {...state, previousType: action.type, status: constants.status_hent_elev_fejl, hentEleverFejl: action.hentEleverFejl, alerts: action.alerts };
    case constants.REDIGER_ELEV:
        return {...state, previousType: action.type, selectedElevRows: [], showElevStamkortModal: false, redigerElev: action.elev, alerts: null };
    case constants.ELEV_ROW_CLICK:{
        let removeElement = function(array, element) {
            const index = array.indexOf(element);
            if (index !== -1) {
                array.splice(index, 1);
            }
        };

      let pushIfNotExistPopIfExists = function(array, element) {
        if (!array.includes(element)) {
          array.push(element);
        } else {
          removeElement(array, element);
        }
      };

      let tempElevRows = [];
      if(state.selectedElevRows.length > 0){
        tempElevRows = [...state.selectedElevRows];
      }
      pushIfNotExistPopIfExists(tempElevRows, action.rowId);

      return {...state, previousType: action.type, selectedElevRows: tempElevRows}
    }
    case constants.ELEV_MODAL_SHOW_CLICK:{
      let result = {...state, previousType: action.type, showElevModal: false};
      if(state.selectedElevRows.length > 0){
        let modalSwitch = !state.showElevModal;
        result = {...state, previousType: action.type, showElevModal: modalSwitch};
      }
      return result;
    }

      case constants.ELEV_STAMKORT_MODAL_SHOW_CLICK:{
          return {...state, previousType: action.type, selectedElevRows: [action.elevId], showElevStamkortModal: !state.showElevStamkortModal};
      }

    case constants.ELEV_MODAL_CLOSE_CLICK:
    case constants.ELEV_MODAL_CLOSE: {
      return {...state, previousType: action.type, showElevModal: false};
    }


    case constants.ELEV_STAMKORT_MODAL_CLOSE_CLICK:{
          return {...state, previousType: action.type, showElevStamkortModal: false, selectedElevRows: [] };
      }

    case constants.SKJUL_SLET_ELEVER:
        return {...state, previousType: action.type, visSlet: action.visSlet, eleverSlet: []};
    case constants.SLET_ELEVER:
        return {...state, previousType: action.type, sletterElever: action.sletterElever};
    case constants.SLET_ELEVER_SUCCES:
        let data = [...state.elever];
        let selectedElevRows = [...state.selectedElevRows];
        for(let i=0; i < selectedElevRows.length; i++) {
          let val = selectedElevRows[i];
          let index = data.findIndex(function(item){
            return item.id === val
          });
          if( index!== -1){
             data.splice(index, 1);
          }
        }
        return {...state, previousType: action.type, status: constants.status_slet_elever_succes, eleverSlet: action.eleverSlet, visSlet: action.visSlet, elever: data, selectedElevRows:[], showElevModal: false, showElevStamkortModal: false, alerts: action.alerts };
    case constants.SLET_ELEVER_FEJL:
        return {...state, previousType: action.type, status: constants.status_slet_elever_fejl, sletEleverFejl: action.sletEleverFejl, alerts: action.alerts };
    case constants.OPRET_ELEV:
      return {...state, previousType: action.type, status: constants.status_opret_elev_start, opretterElev: action.opretterElev, alerts: null };
    case constants.OPRET_ELEV_SUCCES:
      return {...state, previousType: action.type, status: constants.status_opret_elev_succes, nyElev: action.nyElev, alerts: action.alerts };
    case constants.OPRET_ELEV_FEJL:
      return {...state, previousType: action.type, status: constants.status_opret_elev_fejl, opretElevFejl: action.opretElevFejl, alerts: action.alerts };
    case constants.RET_ELEV:
      return {...state, previousType: action.type, status: constants.status_ret_elev_start, opretterElev: action.opretterElev, alerts: null };
    case constants.RET_ELEV_SUCCES:
      return {...state, previousType: action.type, status: constants.status_ret_elev_succes, rettetElev: action.rettetElev, alerts: action.alerts };
    case constants.RET_ELEV_FEJL:
      return {...state, status: constants.status_ret_elev_fejl, opretElevFejl: action.retElevFejl, alerts: action.alerts};
    case constants.OPRET_ELEV_SIDE:
      return {...state, previousType: action.type, alerts: null};

    case constants.SLET_KONTAKT:
      return {...state, previousType: action.type, sletterKontakt: action.sletterKontakt};
    case constants.SLET_KONTAKT_SUCCES:
      let elev = state.redigerElev;
      let kontakter = [];
      for (let i in elev.elev.kontakter) {
        if (elev.elev.kontakter.hasOwnProperty(i) && elev.elev.kontakter[i].id !== action.kontaktId) {
          kontakter.push(elev.elev.kontakter[i]);
        }
      }
      elev.elev.kontakter = kontakter;
      return {...state, previousType: action.type, status: constants.status_slet_kontakt_succes, redigerElev: elev, alerts: action.alerts };
    case constants.SLET_KONTAKT_FEJL:
      return {...state, previousType: action.type, status: constants.status_slet_kontakt_fejl, alerts: action.alerts };

      case constants.RESET_SELECTED_ELEV_ROWS:{
          return{...state, previousType: action.type, selectedElevRows: []}
      }
      case constants.SET_ELEV_AS_SELECTED:{

        let pushIfNotExist = function(array, element) {
          if (!array.includes(element)) {
            array.push(element);
          }
        };
        let tempElevRows = [];
        if(state.selectedElevRows.length > 0){
          tempElevRows = [...state.selectedElevRows];
        }
        pushIfNotExist(tempElevRows, action.rowId);

        return {...state, previousType: action.type, selectedElevRows: tempElevRows}
    }

    default:
      return state;
  }
}

