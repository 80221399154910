import 'whatwg-fetch';
import constants from "../util/constants";

export function henterVejledning(bool) {
  return {
    type: constants.HENT_VEJLEDNING,
    isLoading: bool,
  }
}

export function hentVejledningSuccess(url) {
  return {
    type: constants.HENT_VEJLEDNING_SUCCES,
    vejledningUrl: url,
  }
}

export function hentVejledningFejl(bool, errors) {
  return {
    type: constants.HENT_VEJLEDNING_FEJL,
    harFejl: bool,
    alerts: {fejl: errors, info: []}
  }
}

export function hentVejledning() {
  return dispatch => {
    dispatch(henterVejledning(true));

    fetch(constants.apihentVejledningUrl, {
      method: "GET",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(henterVejledning(false));
        return response;
      })
      .then((response) => response.text())
      .then((item) => dispatch(hentVejledningSuccess(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentVejledningFejl(true, [ "401" ]));
        else
          dispatch(hentVejledningFejl(true, [ "Hent vejlednings url er fejlet, prøv igen senere." ]));
      });
  };
}

export function henterAnnouncement(bool) {
  return {
    type: constants.HENT_ANNOUNCEMENT,
    isLoading: bool,
  }
}

export function hentAnnouncementSuccess(announcement) {
  return {
    type: constants.HENT_ANNOUNCEMENT_SUCCES,
    announcement: announcement,
  }
}

export function hentAnnouncementFejl(bool, errors) {
  return {
    type: constants.HENT_ANNOUNCEMENT_FEJL,
    harFejl: bool,
    alerts: {fejl: errors, info: []}
  }
}

export function hentAnnouncement() {
  return dispatch => {
    dispatch(henterAnnouncement(true));

    fetch(constants.apihentAnnouncement, {
      method: "GET",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
        .then((response) => {
          if (!response.ok)
            throw response;

          dispatch(henterAnnouncement(false));
          return response.json();
        })
        .then((item) => {
          dispatch(hentAnnouncementSuccess(item))
        })
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(hentAnnouncementFejl(true, [ "401" ]));
          else
            dispatch(hentAnnouncementFejl(true, [ "Hent bekendtgørelse er fejlet, prøv igen senere." ]));
        });
  };
}