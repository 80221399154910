import React, {useEffect} from 'react'
import OpretElev from "../pages/elever/OpretElev";
import {useParams} from 'react-router-dom'


function OpretElevMain(props) {
  let { id } = useParams();
  const {hentKlasser, hentElev, aabenOpretElevSide} = props;

  useEffect(() => {
    hentKlasser();
    if(id != null){
      hentElev(id);
    }
    aabenOpretElevSide();
  }, [hentKlasser, hentElev, aabenOpretElevSide, id]);

    const{instnr, klasser, cprData, opretElev, opretElevValideringsfejl, status,
      redigerElev, retElev, alerts, sletKontakt, opretterElev} = props;

    return (
        <div className="stil-main-content">
            <OpretElev
              instnr={instnr}
              klasser={klasser}
              opretElev={opretElev}
              valideringsfejl={opretElevValideringsfejl}
              status={status}
              id={id}
              cprData={cprData}
              hentetElev={redigerElev}
              retElev={retElev}
              alerts={alerts}
              sletKontakt={sletKontakt}
              opretterElev={opretterElev}
          />
        </div>
    )
}

export default OpretElevMain;