import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import constants from '../util/constants.js';
import MenuItem from "../util/MenuItem";

class LoginBar extends Component {

  render() {
    const {loggedIn, bruger, openSelectInstitutionModal, active, alerts} = this.props;

    if (loggedIn === undefined) {
      return (
        <div>
          <nav className="stil-topmenu">
            <ul className="topmenuLeft">
            </ul>
            <ul className="topmenuRight">
              <li>
                <p className="stil-logged-in-user">
                  Henter loginoplysninger ...
                </p>
              </li>
            </ul>
          </nav>
        </div>
      )
    }

    if (loggedIn && alerts.fejl.length <= 0) {

      if (bruger.institution) {
        return (
          <div>
            <nav className="stil-topmenu">
              <ul className="topmenuLeft">
                <MenuItem aktiv={active === 'Elever'} href={constants.urlElever}
                          show={bruger.adgange.includes(constants.adgangElevAdm)}>Elever</MenuItem>
                <MenuItem aktiv={active === 'Klasser'} href={constants.urlKlasser}
                          show={bruger.adgange.includes(constants.adgangElevAdm)}>Klasser</MenuItem>
                <MenuItem aktiv={active === 'Medarbejdere'} href={constants.urlMedarbejdere}
                          show={bruger.adgange.includes(constants.adgangMedarbejderAdm)}>Medarbejdere</MenuItem>
              </ul>
              <ul className="topmenuRight">
                <li>
                  <p className="stil-logged-in-user">
                    <Link id="logud-knap" to={constants.urlLogout} className="stil-primary-button">Log ud</Link>
                  </p>
                </li>
              </ul>
            </nav>
            <div className="everythingOnOneLine">
              {
                bruger.flereInstitutioner ?
                  <Link id="skift-organisation-link"
                        to={`${constants.urlForside}?${constants.selectInstitutionParam}=true`} className="hand">
                    <img src="/elevadministration/img/caret-right.svg" alt=">"/>Logget på
                    som {bruger.navn}, {bruger.institution.navn} ({bruger.institution.kode})
                  </Link> :
                  <span>Logget på som {bruger.navn}, {bruger.institution.navn} ({bruger.institution.kode})</span>
              }
            </div>
          </div>
        );
      }

      return (
        <div>
          <nav className="stil-topmenu">
            <ul className="topmenuRight">
              <li>
                <p className="stil-logged-in-user">
                  <Link id="logud-knap" to={constants.urlLogout} className="stil-primary-button">Log ud</Link>
                </p>
              </li>
            </ul>
          </nav>
          <div className="everythingOnOneLine">
            <span>Logget på som {bruger.navn} - </span>
            <button onClick={openSelectInstitutionModal} className="hand" id="handButton"><img src="/elevadministration/img/caret-right.svg"
                                                                          alt=">"/>Vælg institution</button>
          </div>
        </div>
      );
    }

    if (alerts.fejl.length > 0) {
      return (
        <div>
          <nav className="stil-topmenu">
            <ul className="topmenuLeft">
            </ul>
            <ul className="topmenuRight">
              <li>
                <p className="stil-logged-in-user">
                  <Link id="logud-knap" to={constants.urlLogout} className="stil-primary-button">Log ud</Link>
                </p>
              </li>
            </ul>
          </nav>
        </div>
      )
    }

    return (
      <div>
        <nav className="stil-topmenu">
          <ul className="topmenuLeft">
          </ul>
          <ul className="topmenuRight">
            <li>
              <p className="stil-logged-in-user">
                <a id="button-login" href={constants.urlLogin} className="stil-primary-button">Log ind</a>
              </p>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default LoginBar;
