import React, {Component} from 'react';
import OprulningKlasse from "./OprulningKlasse.js";
import constants from "../../util/constants";
import {Link} from "react-router-dom";

class OprulningKlasser extends Component {
  componentWillReceiveProps(nextProps){
    if(this.props.oprulKlasser.length<nextProps.oprulKlasser.length){
      this.sorterTrin(nextProps.oprulKlasser);
    }
  }

  sorterTrin(oprulKlasser){
    oprulKlasser.sort(function(a, b){
      if(a.klasse.niveau !== b.klasse.niveau){
        return a.klasse.niveau - b.klasse.niveau;
      }else{
        return a.klasse.gruppebetegnelse.localeCompare(b.klasse.gruppebetegnelse);
      }
    });
  }

  render() {

    const {oprulKlasser, oprulKlasserne} = this.props;

    if (oprulKlasser !== null && oprulKlasser !== undefined && oprulKlasser.length !== 0) {
      return (
        <div>
          <h4>Ved oprulning vil der ske følgende ændringer</h4>
          <div className="buttongroup">
            <button type="button" id="oprulKlasserButton" className="stil-primary-button button"
                    onClick={() => oprulKlasserne()}>Udfør oprulning
            </button>
            <Link to={constants.urlKlasser} className="stil-primary-button margleft10">Tilbage til klasser</Link>
          </div>
          <table>
            <tbody>
            <tr>
              <th>Klassetrin</th>
              <th>Nyt Klassetrin</th>
              <th>Klassenavn/bogstav</th>
              <th>Antal elever</th>
            </tr>
            {oprulKlasser.map(oprulKlasse => {
              return <OprulningKlasse oprulKlasse={oprulKlasse} key={oprulKlasse.klasse.id}/>
            })}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }

}

export default OprulningKlasser;