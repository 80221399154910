import { connect } from 'react-redux'
import OprulningMain from "./OprulningMain.js";
import { hentOprulKlasser, oprulKlasserne, skjulOprulKlasserModal } from "../actions/oprulning.js";


const mapStateToProps = (state) => {
  const oprulning = state.oprulning;
  return {
    oprulKlasser: oprulning.oprulKlasser,
    hentOprulKlasserFejl: oprulning.hentOprulKlasserFejl,
    oprullerKlasser: oprulning.oprullerKlasser,
    oprulKlasserFejl: oprulning.oprulKlasserFejl,
    visOprulModal: oprulning.visOprulModal,
    redirect2Klasser: oprulning.redirect2Klasser,
    status: oprulning.status,
    alerts: oprulning.alerts
  }
};

const mapDispatchToProps  = (dispatch) => {
  return {
    hentOprulKlasser: () => dispatch(hentOprulKlasser()),
    oprulKlasserne: () => dispatch(oprulKlasserne()),
    skjulOprulKlasser: () => dispatch(skjulOprulKlasserModal()),
  };
};

const OprulningConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)((OprulningMain));

export default OprulningConnector;