import {connect} from 'react-redux'
import * as loginActions from "../actions/login";
import LoginMain from "./LoginMain.js";

const mapStateToProps = (state) => {
  return {
    login: state.login,
    forside: state.forside,
    loggedIn: state.login.loggedIn,
    bruger: state.login.bruger,
    active: state.app.active,
    institutioner: state.login.institutioner
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    hentBrugeroplysninger: () => dispatch(loginActions.hentBrugeroplysninger()),
    loginClear: () => dispatch(loginActions.loginClear()),
    openSelectInstitutionModal: () => dispatch(loginActions.openSelectInstitutionModal()),
    selectInstitution: (event) => dispatch(loginActions.selectInstitution(event)),
    hentInstitutioner: () => dispatch(loginActions.hentInstitutioner())
  };
};

const LoginConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)((LoginMain));

export default LoginConnector;