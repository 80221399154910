import React, {Component} from "react";
import { Link } from 'react-router-dom';

class MenuItem extends Component {
  render() {
    const {aktiv, href, children, show} = this.props;
    if (!show) {
      return null;
    }
    return <li className={aktiv ? 'aktiv' : ''}><Link id={children} to={href}>{children}</Link></li>;
  }
}
export default MenuItem;