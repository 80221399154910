import constants from '../util/constants.js';

export function forsideReducer(state = [], action) {

    // console.log('forsideReducer: State is now',state);
    // console.log('forsideReducer: Action is now',action);

  switch(action.type) {

    case constants.LOGIN_CLEAR:
      return {login: {...constants.loginStateInit, loggedIn: false}, forside: constants.forsideStateInit};

    case constants.NULSTIL_ALERTS:
      return {
        ...state,
        status: constants.status_nulstiller_alerts,
        alerts: {fejl: [], info: []}
      };

      case constants.SELECT_INSTITUTION:
      return {
        ...state,
        showSelectInstitutionModal: false
      };

    case constants.OPEN_INSTITUTION_MODAL:
      return {
        ...state,
        showSelectInstitutionModal: true
      };

    default:
      return state;
  }
}

