import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class OprulKlasserModal extends Component {

  render() {

    const { oprulKlasser, visOprulModal, skjulOprulKlasser } = this.props;

    if (oprulKlasser === null || oprulKlasser === undefined || oprulKlasser.length === 0)
        return null;

    return (
      <Modal isOpen={visOprulModal} toggle={skjulOprulKlasser} onClosed={skjulOprulKlasser}>
        <ModalHeader>
            Oprulning af alle elever
          <button type="button" className="close" data-dismiss="modal" onClick={skjulOprulKlasser} aria-hidden="true">×</button>
        </ModalHeader>
        <ModalBody>
          <p className="oprulningsrapportHeader">

          </p>
          <h2>Oprulningsrapport</h2>
          <p>Det gik godt</p>
          <table>
            <tbody>
              { oprulKlasser.map( oprulKlasse => {
                return(
                  <tr key={oprulKlasse.klasse.id}>
                    <td>{oprulKlasse.gammeltGruppenavn} er blevet
                        {oprulKlasse.klasse.gruppenavn === 'slettet' ? ' ' : ' til '}
                        {oprulKlasse.klasse.gruppenavn}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
      </ModalBody>
      <ModalFooter>
        <div className="container mybuttons">
          <button id="modal-tilbage-til-klasser-button" className='button stil-primary-button' type="button" onClick={skjulOprulKlasser}>Tilbage til klasser</button>
        </div>
      </ModalFooter>
      </Modal>
    )
  }
}

export default OprulKlasserModal;
