import React, {Component} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import MedarbejderOplysninger from "../pages/medarbejdere/MedarbejderOplysninger";

class MedarbejderOversigtVisModal extends Component{

    constructor(props) {
        super(props);
        this.state = {visible: true};
        this.state = {
          modal: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    render(){
          const { medarbejdere, selectedMedarbejderRows, medarbejderModalClose, showMedarbejderModal, openSletMedarbejderModal} = this.props;
      return(

        <Modal isOpen={showMedarbejderModal} toggle={medarbejderModalClose} onClosed={medarbejderModalClose}>
          <ModalHeader>
           <button type="button" className="close" data-dismiss="modal" onClick={medarbejderModalClose} aria-hidden="true">×</button>
            Valgte medarbejdere
          </ModalHeader>
          <ModalBody>
          <div>
            <table>
              <tbody>
                <tr>
                  <th>Fornavn</th>
                  <th>Efternavn</th>
                  <th>Rolle</th>
                  <th>Mobiltelefon</th>
                  <th>Email</th>
                  <th>Adresse</th>
                  <th>UniId</th>
                </tr>
                {medarbejdere
                    .filter(medarbejder => selectedMedarbejderRows.includes(medarbejder.id))
                    .map(medarbejder => {
                        return(<MedarbejderOplysninger key={medarbejder.id} medarbejder={medarbejder}/>);
                    })
                }
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container mybuttons">
            <button className='button stil-primary-button' onClick={() => openSletMedarbejderModal()}>Slet medarbejdere</button>
            <button className='button margleft10 stil-primary-button' onClick={medarbejderModalClose}>Luk</button>
          </div>
        </ModalFooter>
      </Modal>
      )
    }
}


export default MedarbejderOversigtVisModal
