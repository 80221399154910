import { combineReducers } from 'redux'
import {klasseReducer} from './KlasseReducer.js';
import {elevReducer} from "./ElevReducer.js";
import {oprulningReducer} from "./OprulningReducer.js"
import {loginReducer} from "./LoginReducer.js";
import {appReducer} from "./AppReducer.js";
import {forsideReducer} from "./ForsideReducer";
import {medarbejderReducer} from "./MedarbejderReducer";

export default combineReducers({
  app: appReducer,
  forside: forsideReducer,
  klasser: klasseReducer,
  elever: elevReducer,
  oprulning: oprulningReducer,
  login: loginReducer,
  medarbejdere: medarbejderReducer
});