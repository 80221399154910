import 'whatwg-fetch';
import constants from "../util/constants";
import {isEmptyStr} from "../util/util";
import Cookie from "js-cookie";

export function hentMedarbejdere() {
  return dispatch => {
    dispatch(henterMedarbejdere(true));

    fetch(constants.apiHentMedarbejdere, {
      method: "GET",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
        .then((response) => {
          if (!response.ok)
            throw response;
          dispatch(henterMedarbejdere(false));
          return response;
        })
        .then((response) => response.json())
        .then((item) => dispatch(hentMedarbejdereSuccess(item)))
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(hentMedarbejdereFejl([ "401" ]));
          else
            dispatch(hentMedarbejdereFejl([ "Medarbejdere kan ikke hentes, prøv igen senere." ]));
        });
  };
}

function henterMedarbejdere(bool) {
  return {
    type: constants.HENT_MEDARBEJDERE,
    henterMedarbejder: bool,
  }
}

function hentMedarbejdereSuccess(medarbejdere) {
  return {
    type: constants.HENT_MEDARBEJDERE_SUCCESS,
    medarbejdere: medarbejdere
  }
}

function hentMedarbejdereFejl(errors) {
  return {
    type: constants.HENT_MEDARBEJDERE_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function opretMedarbejder(person) {
  return dispatch => {
    dispatch(opretterMedarbejder(true));

    fetch(constants.apiOpretMedarbejder, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(person),
    })
        .then((response) => {
          if (!response.ok) {
            throw response;
          }
          dispatch(opretterMedarbejder(false));
          return response;
        })
        .then((response) => response.json())
        .then((item) => dispatch(opretMedarbejderSuccess(item)))
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(opretMedarbejderFejl([ "401" ]));
          else
            errorResponse.json().then(data => dispatch(opretMedarbejderFejl(data)));
        });
  };
}

function opretterMedarbejder(bool) {
  return {
    type: constants.OPRET_MEDARBEJDER,
    henterMedarbejder: bool,
  }
}

function opretMedarbejderSuccess(medarbejder) {
  return {
    type: constants.OPRET_MEDARBEJDER_SUCCESS,
    nymedarbejder: medarbejder,
    alerts: {fejl: [], info: [getNavn(medarbejder) + " er oprettet."]}
  }
}

function opretMedarbejderFejl(errors) {
  return {
    type: constants.OPRET_MEDARBEJDER_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function retMedarbejder(person) {
  return dispatch => {
    dispatch(retterMedarbejder(true));

    fetch(constants.apiRetMedarbejder, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(person),
    })
        .then((response) => {
          if (!response.ok)
            throw response;
          dispatch(retterMedarbejder(false));
          return response;
        })
        .then((response) => response.json())
        .then((item) => dispatch(retMedarbejderSuccess(item)))
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(retMedarbejderFejl([ "401" ]));
          else
            errorResponse.json().then(data => dispatch(retMedarbejderFejl(data)));
        });
  };
}

function retterMedarbejder(bool) {
  return {
    type: constants.RET_MEDARBEJDER,
    retterMedarbejder: bool,
  }
}

function retMedarbejderSuccess(medarbejder) {
  return {
    type: constants.RET_MEDARBEJDER_SUCCESS,
    rettetMedarbejder: medarbejder,
    alerts: {fejl: [], info: [getNavn(medarbejder) + " er opdateret."]}
  }
}

function retMedarbejderFejl(errors) {
  return {
    type: constants.RET_MEDARBEJDER_FEJL,
    alerts: {fejl: errors, info: []}
  }
}


export function hentMedarbejder(id) {
  return dispatch => {
    dispatch(henterMedarbejder(true));

    fetch(constants.apiHentMedarbejder + id, {
      method: "GET",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then((response) => {
          if (!response.ok)
            throw response;
          dispatch(henterMedarbejder(false));
          return response;
        })
        .then((response) => response.json())
        .then((item) => dispatch(hentMedarbejderSuccess(item)))
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(hentMedarbejderFejl(true, [ "401" ]));
          else
            dispatch(hentMedarbejderFejl(true, [ "Medarbejderen kan ikke hentes, prøv igen senere." ]));
        });
  }
}

function henterMedarbejder(bool) {
  return {
    type: constants.HENT_MEDARBEJDER,
    henterMedarbejder: bool,
  }
}

function hentMedarbejderSuccess(medarbejder) {
  return {
    type: constants.HENT_MEDARBEJDER_SUCCESS,
    hentetMedarbejder: medarbejder
  }
}

function hentMedarbejderFejl(errors) {
  return {
    type: constants.HENT_MEDARBEJDER_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function sletMedarbejdere(medarbejdere) {

  return dispatch => {
    dispatch(sletterMedarbejdere(true));

    fetch(constants.apiSletMedarbejdere, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(medarbejdere),
    })
        .then((response) => {
          if (!response.ok)
            throw response;
          dispatch(sletterMedarbejdere(false));
          return response;
        })
        .then((response) => response.json())
        .then((item) => dispatch(sletMedarbejdereSucces(item)))
        .catch((errorResponse) => {
          if (errorResponse.status === 401)
            dispatch(sletMedarbejdereFejl([ "401" ]));
          else
            dispatch(sletMedarbejdereFejl([ "Sletning er fejlet, prøv igen senere." ]));
        });
  };
}


function sletterMedarbejdere(bool) {
  return {
    type: constants.SLET_MEDARBEJDERE,
    sletterMedarbejdere: bool,
  }
}

function sletMedarbejdereSucces(antalMedarbejdere) {
  return (dispatch) => {
    dispatch(sletMedarbejdereSucceeded(antalMedarbejdere));
  }
}

function sletMedarbejdereSucceeded(antalMedarbejdere) {

  let tekst = "";
  if (antalMedarbejdere === 0) {
    tekst = "Ingen medarbejdere er slettet."
  } else if (antalMedarbejdere === 1) {
    tekst = "Medarbejderen er slettet.";
  } else {
    tekst = "Der er slettet " + antalMedarbejdere + " medarbejdere.";
  }

  return {
    type: constants.SLET_MEDARBEJDERE_SUCCES,
    visSlet: false,
    alerts: {fejl: [], info: [tekst]}
  };
}

function sletMedarbejdereFejl(errors) {
  return {
    type: constants.SLET_MEDARBEJDERE_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function medarbejderModalClose(){
  return (dispatch, getState) => {
    const { medarbejdere } = getState();
    if (medarbejdere.previousType === constants.MEDARBEJDER_MODAL_SHOW_CLICK) {
      dispatch(closeModalAction());
      return {};
    }
  }
}

function closeModalAction(){
  return {
    type: constants.MEDARBEJDER_MODAL_CLOSE,
  }
}

export function medarbejderRowClick(rowId) {
  return {
    type: constants.MEDARBEJDER_ROW_CLICK,
    rowId: rowId,
  }
}

export function visMedarbejderModal() {
  return {
    type: constants.MEDARBEJDER_MODAL_SHOW_CLICK,
  }
}

export function resetSelectedMedarbejderRows(){
  return {
    type: constants.RESET_SELECTED_MEDARBEJDER_ROWS,
  }
}

export function setMedarbejderAsSelected(medarbejderRowId){
  return {
    type: constants.SET_MEDARBEJDER_AS_SELECTED,
    rowId: medarbejderRowId,
  }
}

export function visMedarbejderStamkortModal(id) {
  return {
    type: constants.MEDARBEJDER_STAMKORT_MODAL_SHOW_CLICK,
    medarbejderId: id,
  }
}

export function lukMedarbejderStamkortModal() {
  return {
    type: constants.MEDARBEJDER_STAMKORT_MODAL_CLOSE_CLICK
  }
}

function getNavn(medarbejder) {
  let navn = "";
  if (!isEmptyStr(medarbejder.stamdata.aliasEfternavn) || !isEmptyStr(medarbejder.stamdata.aliasFornavn)) {
    navn = medarbejder.stamdata.aliasFornavn + " " + medarbejder.stamdata.aliasEfternavn;
  } else {
    navn = medarbejder.stamdata.fornavn + " " + medarbejder.stamdata.efternavn;
  }
  return navn;
}

export function aabenOpretMedarbejderSide() {
  return {
    type: constants.OPRET_MEDARBEJDER_SIDE,
  }
}