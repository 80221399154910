import React, {Component} from "react";

class Footer extends Component {
  render() {

    return (
        <footer className="stil-footer">
            <div className="stil-footerblock">
                <em>Styrelsen for it og læring</em>
                <p>Teglholmsgade 1<br/>
                    2450 København SV<br/>
                    EAN-nr. 5798000555198<br/>
                    CVR-nr. 13223459
                </p>
            </div>
            <div className="stil-footerblock">
                <em>Support</em>
                <p>
                  <a href="https://viden.stil.dk/x/agIGAQ" target="_blank" rel="noreferrer">Vejledning til Elevadministration</a><br/>
                  <a href="tel:+4535878550">TIf. 3587 8550</a><br/>
                  <a href="https://jira.stil.dk/servicedesk/customer/portal/7/create/144" target="_blank" className="stil-mail" rel="noreferrer">Skriv til STIL</a>
                </p>
            </div>
        </footer>
    );
  }
}

export default Footer;