import constants from "./constants";
import Cookie from "js-cookie";

// TODO: Denne her er ikke anvendt andre steder end login.js
const fetchJson = (url, method, body, dispatch) => {
  const jsonBody = body ? JSON.stringify(body) : undefined;
  const init = { ...constants.defaultFetchOptions, method, body: jsonBody};
  if ((method === 'POST' || method === 'DELETE')) {
    init.headers['X-XSRF-TOKEN'] = Cookie.get('XSRF-TOKEN');
  }

  return fetch(url, init).then(response => {
    if (!response.ok) throw response;

    // TODO: Følgende håndteres ikke af reducer og gør intet. Advarsel om timeout er ikke implementeret.
    //       Dette kan kun gennemføres, hvis denne metode bruges alle steder.
    const expires = response.headers.get(constants.tokenExpiresHeader);
    dispatch({ type: constants.UPDATE_EXPIRES, expires})
    return response.json();
  });
};

export default fetchJson;
