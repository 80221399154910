import {connect} from 'react-redux'
import * as medarbejderActions from "../actions/medarbejder";
import OpretMedarbejderMain from "./OpretMedarbejderMain"


const mapStateToProps = (state) => {

  let institutionsnummer;

  if(state.login.bruger.institution !== null){
    institutionsnummer = state.login.bruger.institution.kode;
  }
  else{
    institutionsnummer = null;
  }

  return {
    instnr: institutionsnummer,
    opretMedarbejderValideringsfejl: state.medarbejdere.opretMedarbejderValideringsfejl,
    status: state.medarbejdere.status,
    redigerMedarbejder: state.medarbejdere.redigerMedarbejder,
    cprData: state.medarbejdere.cprData,
    alerts: state.medarbejdere.alerts,
    opretterMedarbejder: state.medarbejdere.opretterMedarbejder
  }
};

const mapDispatchToProps  = (dispatch) => {
  return {
    opretMedarbejder: (person) => dispatch(medarbejderActions.opretMedarbejder(person)),
    retMedarbejder: (person) => dispatch(medarbejderActions.retMedarbejder(person)),
    hentMedarbejder: (id) => dispatch(medarbejderActions.hentMedarbejder(id)),
    aabenOpretMedarbejderSide: () => dispatch(medarbejderActions.aabenOpretMedarbejderSide())
  }
};

const OpretMedarbejderConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpretMedarbejderMain);

export default OpretMedarbejderConnector;