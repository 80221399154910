import {connect} from 'react-redux'
import * as medarbejderActions from "../actions/medarbejder.js";
import MedarbejderOversigtMain from "./MedarbejderOversigtMain";

const mapStateToProps = (state) => {
  const medarbejdere = state.medarbejdere;

  let institutionsnummer;
  let jobKoersel;

  if(state.login.bruger.institution !== null){
    institutionsnummer = state.login.bruger.institution.kode;
    jobKoersel = state.login.bruger.institution.jobKoersel
  }
  else{
    institutionsnummer = null;
    jobKoersel = null;
  }

  return {
    instnr: institutionsnummer,
    senesteEksport: jobKoersel,
    medarbejdere: medarbejdere.medarbejdere,
    selectedMedarbejderRows: medarbejdere.selectedMedarbejderRows,
    showMedarbejderModal: medarbejdere.showMedarbejderModal,
    showMedarbejderStamkortModal: medarbejdere.showMedarbejderStamkortModal,
    alerts: medarbejdere.alerts
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    hentMedarbejdere: () => dispatch(medarbejderActions.hentMedarbejdere()),
    sletMedarbejdere: (medarbejdere) => dispatch(medarbejderActions.sletMedarbejdere(medarbejdere)),
    medarbejderModalClose: () => dispatch(medarbejderActions.medarbejderModalClose()),
    medarbejderRowClick: (id) => dispatch(medarbejderActions.medarbejderRowClick(id)),
    visMedarbejderModal: () => dispatch(medarbejderActions.visMedarbejderModal()),
    resetSelectedMedarbejderRows: () => dispatch(medarbejderActions.resetSelectedMedarbejderRows()),
    setMedarbejderAsSelected: (rowId) => dispatch(medarbejderActions.setMedarbejderAsSelected(rowId)),
    visMedarbejderStamkortModal: (id) => dispatch(medarbejderActions.visMedarbejderStamkortModal(id)),
    lukMedarbejderStamkortModal: () => dispatch(medarbejderActions.lukMedarbejderStamkortModal())
  }
};

const MedarbejderOversigtConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(MedarbejderOversigtMain);

export default MedarbejderOversigtConnector;
