import { connect } from 'react-redux'
import KlasseMain from "./KlasseMain.js";
import {hentKlasser, nyKlasse, retKlasse, opretGemKlasse, skjulSletKlasser, sletKlasser, klasseRowClick, klasseSletMarkeredeClick, klasseSletEnkeltRowClick, klasseSletModalClose, setKlasseAsSelected, validerKlasseFejl} from "../actions/klasse";

const mapStateToProps = (state) => {
  const klasser = state.klasser;

  let institutionsnummer;
  let jobKoersel;

  if(state.login.bruger.institution !== null){
    institutionsnummer = state.login.bruger.institution.kode;
    jobKoersel = state.login.bruger.institution.jobKoersel
  }
  else{
    institutionsnummer = null;
    jobKoersel = null;
  }

  return {
    instnr: institutionsnummer,
    senesteEksport: jobKoersel,
    klasser: klasser.klasser,
    status: klasser.status,
    importStatus: klasser.importStatus,
    henterKlasser: klasser.henterKlasser,
    hentKlasserFejl: klasser.hentKlasserFejl,
    visSlet: klasser.visSlet,
    klasserSlet: klasser.klasserSlet,
    selectedKlasseRows: klasser.selectedKlasseRows,
    sletterKlasser: klasser.sletterKlasser,
    showKlasseModal: klasser.showKlasseModal,
    alerts: klasser.alerts,
    klasseId: klasser.klasseId,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    hentKlasser: () => dispatch(hentKlasser(false)),
    nyKlasse: () => dispatch(nyKlasse()),
    retKlasse: (id) => dispatch(retKlasse(id)),
    opretGemKlasse: (klasse) => dispatch(opretGemKlasse(klasse)),
    sletKlasser: (klasser) => dispatch(sletKlasser(klasser)),
    klasseRowClick: (rowId) => dispatch(klasseRowClick(rowId)),
    setKlasseAsSelected: (rowId) => dispatch(setKlasseAsSelected(rowId)),
    skjulSletKlasser: (klasser) => dispatch(skjulSletKlasser()),
    klasseSletMarkeredeClick: () => dispatch(klasseSletMarkeredeClick()),
    klasseSletEnkeltRowClick: () => dispatch(klasseSletEnkeltRowClick()),
    klasseSletModalClose: () => dispatch(klasseSletModalClose()),
    validerKlasseFejl: (errors) => dispatch(validerKlasseFejl(errors))
  };
};

const KlasseConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)((KlasseMain));

export default KlasseConnector;
