import React from 'react'
import constants from '../util/constants.js';
import {Navigate, useLocation} from 'react-router-dom'

function InstitutionMain(props) {
    let { pathname } = useLocation();

    const {bruger, institution} = props;

        // Der skal være indhold i institutionen (institution.navn<>null), før vi redirecter.
        if ((pathname === "/" || pathname === "/oidc/login") && institution !== null && institution !== undefined && institution.navn !== null) {
            if (bruger.adgange.includes(constants.adgangElevAdm)) {
                return <Navigate to={constants.urlElever} />
            }
            if (bruger.adgange.includes(constants.adgangMedarbejderAdm)) {
                return <Navigate to={constants.urlMedarbejdere} />
            }
        }
        return null;
}
export default InstitutionMain
