import React, {Component} from 'react'

class MedarbejderOplysninger extends Component {

  fornavn(){
    const {stamdata} = this.props.medarbejder;
    if(stamdata.beskyttet)
        return(
        <div className="standard-tooltip">
            {stamdata.aliasFornavn}
            <span className="standard-tooltiptext">
            {stamdata.fornavn}
            </span>
        </div>);
    else
        return stamdata.fornavn;
  }

  efternavn(){
    const {stamdata} = this.props.medarbejder;
      if(stamdata.beskyttet)
        return(
        <div className="standard-tooltip">
            {stamdata.aliasEfternavn}
            <span className="standard-tooltiptext">
            {stamdata.efternavn}
            </span>
        </div>);
    else
        return stamdata.efternavn;
  }

  mobiltelefon(){
    const {stamdata} = this.props.medarbejder;
    const {telefoner} = this.props.medarbejder.stamdata;
    let mobiltelefoner = telefoner.filter(telefon => telefon.type==='Mobil');

    if (mobiltelefoner !== null && mobiltelefoner !== undefined && mobiltelefoner.length > 0) {
        if(mobiltelefoner[0].beskyttet === true){
          return(
          <div className="standard-tooltip">
              Beskyttet
            <span className="standard-tooltiptext">
                {mobiltelefoner[0].nummer}
            </span>
          </div>);
        }else if (stamdata.beskyttet){
          return(
            <div className="standard-tooltip">
              Navnebeskyttet
              <span className="standard-tooltiptext">
                {mobiltelefoner[0].nummer}
              </span>
            </div>
            );
        }else{
          return mobiltelefoner[0].nummer;
        }
    }else{
      return "";
    }
  }

  email(){
    const {stamdata} = this.props.medarbejder;

    if(stamdata.beskyttet)
        return(
            <div className="standard-tooltip">
                Navnebeskyttet
            <span className="standard-tooltiptext">{stamdata.email}</span>
              </div>);
    else
        return stamdata.email;
  }

  adresse(){
    const {stamdata} = this.props.medarbejder;

        if(stamdata.beskyttet)
          return(
            <div className="standard-tooltip">
                Navnebeskyttet
                <span className="standard-tooltiptext">
                    {stamdata.vej + " - " + stamdata.postnr + " " + stamdata.postby}
                </span>
            </div>);
        else
            return stamdata.vej + " - " + stamdata.postnr + " " + stamdata.postby;
  }

  rolleNavn(rolle) {
    switch(rolle) {
      case "LAERER": return "Lærer";
      case "PAEDAGOG": return "Pædagog";
      case "VIKAR": return "Vikar";
      case "LEDER": return "Leder";
      case "LEDELSE": return "Ledelse";
      case "TAP": return "TAP";
      case "KONSULENT": return "Konsulent";
      default: return "";
    }
  }

  render(){
    const {medarbejder} = this.props;
    return(
      <tr>
        <td>{this.fornavn()}</td>
        <td>{this.efternavn()}</td>
        <td>{medarbejder.medarbejder.roller.map(rolle => this.rolleNavn(rolle)).join(", ")}</td>
        <td>{this.mobiltelefon()}</td>
        <td>{this.email()}</td>
        <td>{this.adresse()}</td>
        <td>{medarbejder.uniId ? medarbejder.uniId : ""}</td>
      </tr>
    );
  }
}

export default MedarbejderOplysninger;
