import constants from '../util/constants.js';

export function loginReducer(state = [], action) {
    // console.log('loginReducer: State is now',state);
    // console.log('loginReducer: Action is now',action);
    switch (action.type) {
        case constants.HENT_BRUGEROPLYSNINGER_SUCCESS:
            return {...state, bruger: action.bruger, loggedIn: true};
        case constants.HENT_BRUGEROPLYSNINGER_FEJL_UNAUTHORIZED:
        case constants.HENT_BRUGEROPLYSNINGER_FEJL_ANDET: // TODO: Andre fejl end 401 bør give en meddelelse.
            return {...constants.loginStateInit, loggedIn: false};
        case constants.HENT_ELEVER_SUCCES:
            return {...state, status: constants.status_hent_elever_succes, elever: action.elever};
        case constants.SELECT_INSTITUTION:
            return {...state, bruger: action.bruger};
        case constants.HENT_VEJLEDNING:
            return {...state, status: constants.status_hent_vejledning_start, henterVejledning: action.isLoading};
        case constants.HENT_VEJLEDNING_SUCCES:
            return {...state, status: constants.status_hent_vejledning_succes, vejledningUrl: action.vejledningUrl};
        case constants.HENT_VEJLEDNING_FEJL:
            return {...state, status: constants.status_hent_vejledning_fejl};
        case constants.OPEN_INSTITUTION_MODAL:
            return {...state, bruger: {...state.bruger, institution: null}, institutionSelect: { fejl: null }};
        case constants.SET_SELECT_INSTITUTION_FEJL:
            return {...state, institutionSelect: { fejl: action.errorMessage }}
        case constants.HENT_INSTITUTIONER_FEJL:
            return {...state, status:constants.status_hent_institutioner_fejl, institutioner: {institutioner: [], alerts: action.error}}
        case constants.HENT_INSTITUTIONER_SUCCESS:
            return {...state, status:constants.status_hent_institutioner_success, institutioner: {institutioner: action.institutioner, alerts: null}}
        default:
            return state;
    }
}
