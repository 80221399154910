import constants from '../util/constants.js';

export function oprulningReducer(state = [], action) {
  switch(action.type) {
      case constants.HENT_OPRUL_KLASSER:
          return {...state, status: constants.status_hent_oprul_klasser_start, redirect2Klasser: false, alerts: null };
    case constants.HENT_OPRUL_KLASSER_SUCCES:
      return {...state, status: constants.status_hent_oprul_klasser_succes, oprulKlasser: action.oprulKlasser};
    case constants.HENT_OPRUL_KLASSER_FEJL:
      return {...state, status: constants.status_hent_oprul_klasser_fejl, visOprulModal: false, oprulKlasser: [], hentOprulKlasserFejl: true, alerts: action.alerts };
      case constants.OPRUL_KLASSER:
      return {...state, status: constants.status_oprul_klasser_start, oprullerKlasser: action.oprullerKlasser};
    case constants.OPRUL_KLASSER_SUCCES:
      return {...state, status: constants.status_oprul_klasser_succes, visOprulModal: true, oprulKlasser: action.oprulKlasser};
    case constants.OPRUL_KLASSER_FEJL:
      return {...state, status: constants.status_oprul_klasser_fejl, oprulKlasserFejl: action.oprulKlasserFejl, alerts: action.alerts };
    case constants.SKJUL_OPRUL_KLASSER:
      return {...state, visOprulModal: false, oprulKlasser: [], redirect2Klasser: true };
    default:
      return state;
  }
}
