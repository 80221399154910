import {connect} from 'react-redux'
import HeaderMain from './HeaderMain.js'

const mapStateToProps = (state) => {
  return {
    loggedIn: state.login.loggedIn,
    bruger: state.login.bruger,
    active: state.app.active
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const HeaderConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)((HeaderMain));

export default HeaderConnector;