import 'whatwg-fetch';
import constants from "../util/constants";
import Cookie from "js-cookie";

export function henterKlasser(bool, forRediger){
  return {
    type: constants.HENT_KLASSER,
    henterKlasser: bool,
    forRediger: forRediger
  }
}

export function nyKlasse(){
  return {
    type: constants.NY_KLASSE
  }
}

export function retKlasse(id){
  return {
    type: constants.RET_KLASSE,
    klasseId: id
  }
}

export function hentKlasserSuccess(klasser) {
  return {
    type: constants.HENT_KLASSER_SUCCES,
    klasser: klasser
  }
}

export function hentKlasserFejl(bool, errors) {
  return {
    type: constants.HENT_KLASSER_FEJL,
    hentKlasserFejl: bool,
    alerts: { fejl: errors, info: [] }
  }
}

export function gemKlasse(bool){
  return {
    type: constants.GEM_KLASSE,
    gemmerKlasse: bool,
  }
}

export function gemKlasseSucces(klasse) {
  return {
    type: constants.GEM_KLASSE_SUCCES,
    nyKlasse: klasse,
    alerts: { fejl: [], info: [ klasse.gruppenavn + " er gemt." ] }
  }
}

export function gemKlasseFejl(bool, errors) {
    return{
        type: constants.GEM_KLASSE_FEJL,
        gemKlasseFejl: bool,
        alerts: { fejl: errors, info: [] }
    }
}

export function validerKlasseFejl(errors) {
  return{
    type: constants.VALIDER_KLASSE_FEJL,
    alerts: { fejl: errors, info: [] }
  }
}

export function skjulSletKlasser(){
  return {
    type: constants.SKJUL_SLET_KLASSER,
    visSlet: false,
    klasserSlet: [],
  }
}

export function sletterKlasser(bool){
  return {
    type: constants.SLET_KLASSER,
    sletterKlasser: bool,
  }
}

export function sletKlasserSucces(klasser) {

  let tekst = "Ingen klasser er slettet.";
  if (klasser !== null && klasser !== undefined) {
    if (klasser.length === 1)
      tekst = "Klassen er slettet.";
    else if (klasser.length > 1) {
      tekst = "Der er slettet " + klasser.length + " klasser.";
    }
  }

  return {
    type: constants.SLET_KLASSER_SUCCES,
    klasserSlet: [],
    visSlet: false,
    klasser: klasser,
    alerts: { fejl: [], info: [ tekst ] }
  }
}

export function sletKlasserFejl(bool, errors){
  return {
    type: constants.SLET_KLASSER_FEJL,
    sletKlasserFejl: bool,
    alerts: { fejl: errors, info: [] }
  }
}

export function sletKlasser(klasser){
  return dispatch => {
    dispatch(sletterKlasser(true));

    fetch(constants.apiSletKlasser, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(klasser),
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(sletterKlasser(false));
        return response;
      })
      .then(dispatch(sletKlasserSucces(klasser)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(sletKlasserFejl(true, [ "401" ]));
        else
          dispatch(sletKlasserFejl(true, [ "Sletning er fejlet, prøv igen senere." ]));
      });
  };
}

export function opretGemKlasse(klasse) {
  return dispatch => {
    dispatch(gemKlasse(true));

    fetch(constants.apiGemKlasse, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(klasse),
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(gemKlasse(false));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(gemKlasseSucces(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(gemKlasseFejl(true, [ "401" ]));
        else if(errorResponse.status === 400) //valideringsfejl
          errorResponse.json().then(data => dispatch(gemKlasseFejl(true, data)));
        else
          dispatch(gemKlasseFejl(true, [ "Kunne ikke gemme klassen, prøv igen senere." ]));
      });
  };
}


export function hentKlasser(forRediger) {
  return dispatch => {
    dispatch(henterKlasser(true,forRediger));

    fetch(constants.apiHentKlasser, {
      method: "GET",
      credentials: 'same-origin',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(henterKlasser(false,forRediger));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(hentKlasserSuccess(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentKlasserFejl(true, [ "401" ]));
        else
          dispatch(hentKlasserFejl(true, [ "Kunne ikke hente klasserne, prøv igen senere." ]));
      });
  };
}

export function klasseRowClick(rowId){
  return {
    type: constants.KLASSE_ROW_CLICK,
    rowId: rowId,
  }
}

export function resetSelectedKlasserRows(){
  return {
    type: constants.RESET_SELECTED_KLASSER_ROWS,
  }
}


export function klasseSletMarkeredeClick(){
  return {
    type: constants.KLASSE_SLET_MARKEREDE_CLICK,
  }
}

export function klasseSletEnkeltRowClick(){
  return {
    type: constants.KLASSE_SLET_ENKELT_ROW_CLICK,
  }
}

export function klasseSletModalClose(){
  return (dispatch, getState) => {
    const { klasser } = getState();
    switch(klasser.previousType){
      case constants.KLASSE_SLET_MARKEREDE_CLICK:{
        dispatch(closeModalAction());
        return {};
      }
      case constants.KLASSE_SLET_ENKELT_ROW_CLICK:{
        dispatch(resetSelectedKlasseRows());
        dispatch(closeModalAction());
        return {};
      }
      default:{
        return {};
      }
    }
  }
}

export function closeModalAction(){
  return {
    type: constants.KLASSE_SLET_MODAL_CLOSE,
  }
}
export function resetSelectedKlasseRows(){
  return {
    type: constants.RESET_SELECTED_KLASSER_ROWS,
  }
}

export function setKlasseAsSelected(klasseRowId){
  return {
    type: constants.SET_KLASSE_AS_SELECTED,
    rowId: klasseRowId,
  }
}
