import React, {useEffect, useState} from 'react'
import AlertBar from "../util/AlertBar";
import MedarbejderOversigt from "../pages/medarbejdere/MedarbejderOversigt";
import MedarbejderOversigtVisModal from "../modals/MedarbejderOversigtVisModal";
import MedarbejderStamkortModal from "../modals/MedarbejderStamkortModal";
import MedarbejderSletModal from "../modals/MedarbejderSletModal";

function MedarbejderOversigtMain(props) {
    const [showSletMedarbejderModal, setShowSletMedarbejderModal] = useState(false);
    const {hentMedarbejdere} = props;

    useEffect(() => {
        hentMedarbejdere();
    }, [hentMedarbejdere]);

    const openSletMedarbejderModal =() => {
        setShowSletMedarbejderModal(true)
    }

    const closeSletMedarbejderModal =() => {
        setShowSletMedarbejderModal(false)
    }

    const {medarbejdere, senesteEksport, selectedMedarbejderRows, showMedarbejderModal, medarbejderModalClose,
      sletMedarbejdere, medarbejderRowClick, visMedarbejderModal, resetSelectedMedarbejderRows, setMedarbejderAsSelected,
        showMedarbejderStamkortModal, lukMedarbejderStamkortModal, visMedarbejderStamkortModal} = props;

    return (
      <div className="stil-main-content">

        <AlertBar alerts={props.alerts} />

        <MedarbejderOversigt
            medarbejdere={medarbejdere}
            senesteEksport={senesteEksport}
            medarbejderRowClick={medarbejderRowClick}
            selectedMedarbejderRows={selectedMedarbejderRows}
            visMedarbejderModal={visMedarbejderModal}
            resetSelectedMedarbejderRows={resetSelectedMedarbejderRows}
            setMedarbejderAsSelected={setMedarbejderAsSelected}
            sletMedarbejdere={sletMedarbejdere}
            visMedarbejderStamkortModal={visMedarbejderStamkortModal}
            openSletMedarbejderModal={openSletMedarbejderModal}
        />

        <MedarbejderOversigtVisModal
            selectedMedarbejderRows={selectedMedarbejderRows}
            medarbejdere={medarbejdere}
            showMedarbejderModal={showMedarbejderModal}
            medarbejderModalClose={medarbejderModalClose}
            sletMedarbejdere={sletMedarbejdere}
            openSletMedarbejderModal={openSletMedarbejderModal}
        />

        <MedarbejderStamkortModal
            medarbejdere={medarbejdere}
            selectedMedarbejderRows={selectedMedarbejderRows}
            showMedarbejderStamkortModal={showMedarbejderStamkortModal}
            lukMedarbejderStamkortModal={lukMedarbejderStamkortModal}
            sletMedarbejdere={sletMedarbejdere}
            openSletMedarbejderModal={openSletMedarbejderModal}
        />

        <MedarbejderSletModal
            selectedMedarbejderRows={selectedMedarbejderRows}
            sletMedarbejdere={sletMedarbejdere}
            showSletMedarbejderModal={showSletMedarbejderModal}
            closeSletMedarbejderModal={closeSletMedarbejderModal}
        />

      </div>
    );
}

export default MedarbejderOversigtMain;
