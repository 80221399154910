
import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function MedarbejderSletModal(props) {

    function sletMedarbejderKnap(closeSletMedarbejderModal, selectedMedarbejderRows, sletMedarbejdere){
        sletMedarbejdere(selectedMedarbejderRows);
        closeSletMedarbejderModal();
    }

    const {selectedMedarbejderRows, sletMedarbejdere, showSletMedarbejderModal, closeSletMedarbejderModal} = props;
    if(selectedMedarbejderRows.length <= 1){
        //enkelt medarbejder
        return(
            <Modal isOpen={showSletMedarbejderModal} toggle={closeSletMedarbejderModal}>
                <ModalHeader>
                    <button type="button" className="close" data-dismiss="modal" onClick={closeSletMedarbejderModal} aria-hidden="true">×</button>
                    Bekræft sletning
                </ModalHeader>
                <ModalBody>
                    <div>
                        Er du sikker på, at du vil slette medarbejderen?
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="container mybuttons">
                        <button id="button-modal-annuller-slet-elev" className='button stil-primary-button' onClick={closeSletMedarbejderModal}>Annuller</button>
                        <button id="button-modal-slet-elev" className='button margleft10 stil-primary-button' onClick={() => sletMedarbejderKnap(closeSletMedarbejderModal, selectedMedarbejderRows, sletMedarbejdere)}>Slet medarbejder</button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
    else{
        //flere medarbejdere
        return(
            <Modal isOpen={showSletMedarbejderModal} toggle={closeSletMedarbejderModal}>
                <ModalHeader>
                    <button type="button" className="close" data-dismiss="modal" onClick={closeSletMedarbejderModal} aria-hidden="true">×</button>
                    Bekræft sletning
                </ModalHeader>
                <ModalBody>
                    <div>
                        Er du sikker på, at du vil slette medarbejderne?
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="container mybuttons">
                        <button id="button-modal-annuller-slet-elev" className='button stil-primary-button' onClick={closeSletMedarbejderModal}>Annuller</button>
                        <button id="button-modal-slet-elev" className='button margleft10 stil-primary-button' onClick={() => sletMedarbejderKnap(closeSletMedarbejderModal, selectedMedarbejderRows, sletMedarbejdere)}>Slet medarbejdere</button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}

export default MedarbejderSletModal;
