import 'whatwg-fetch';
import constants from "../util/constants";
import Cookie from "js-cookie";

export function henterOprulKlasser() {
  return {
    type: constants.HENT_OPRUL_KLASSER
  }
}

export function hentOprulKlasserSuccess(klasser) {
  return {
    type: constants.HENT_OPRUL_KLASSER_SUCCES,
    oprulKlasser: klasser
  }
}

export function hentOprulKlasserFejl(errors) {
  return {
    type: constants.HENT_OPRUL_KLASSER_FEJL,
    alerts: { fejl: errors, info: [] }
  }
}

export function oprullerKlasser(bool){
  return {
    type: constants.OPRUL_KLASSER,
    oprullerKlasser: bool,
  }
}

export function oprulKlasserSucces(klasser) {
  return {
    type: constants.OPRUL_KLASSER_SUCCES,
    oprulKlasser: klasser,
  }
}

export function oprulKlasserFejl(bool, errors) {
  return{
    type: constants.OPRUL_KLASSER_FEJL,
    oprulKlasserFejl: bool,
    alerts: { fejl: errors, info: [] }
  }
}

export const skjulOprulKlasserModal = () => ({
    type: constants.SKJUL_OPRUL_KLASSER
});

export function oprulKlasserne() {
  return dispatch => {
    dispatch(oprullerKlasser(true));

    fetch(constants.apiOprulKlasser, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      }
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(oprullerKlasser(false));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(oprulKlasserSucces(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(oprulKlasserFejl(true, [ "401" ]));
        else
          dispatch(oprulKlasserFejl(true, [ "Oprulning fejlede, prøv igen senere." ]));
      });
  };
}

export function hentOprulKlasser() {

  return dispatch => {
    dispatch(henterOprulKlasser());

    fetch(constants.apiHentOprulKlasser, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(hentOprulKlasserSuccess(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentOprulKlasserFejl([ "401" ]));
        else
          dispatch(hentOprulKlasserFejl([ "Klasserne der skal oprulles kan ikke hentes, prøv igen senere." ]));
      });
  };
}