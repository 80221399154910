import React, {useEffect} from 'react'
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Link} from "react-router-dom";
import constants from "../util/constants";

function OrgansiationModal(props){
    const { login, forside, selectInstitution, hentInstitutioner, institutioner} = props;

    useEffect(() => {
        if(forside.showSelectInstitutionModal || (login.loggedIn && !login.bruger.institution)){
            hentInstitutioner();
        }
    }, [forside.showSelectInstitutionModal, login.loggedIn, login.bruger.institution]);

    if (institutioner.institutioner.length !== 0) {
        return(
        <Modal isOpen={forside.showSelectInstitutionModal || (login.loggedIn && !login.bruger.institution)}>
            <ModalHeader>Vælg aktiv institution</ModalHeader>
            <ModalBody>
                <select name="institution" id="institutioner-dropdown" className="margleft10 margright10 stretch"
                        defaultValue={"default"} onChange={selectInstitution}>
                    <option key="default" value="default" disabled={true}>Vælg institution</option>
                    {institutioner.institutioner.map((inst) => {
                        let regnr_navn = inst.split("#");  // inst er af encoded "555001#Blåvandshuk skole"
                        return (<option key={regnr_navn[1]} value={regnr_navn[0]}>{regnr_navn[1]} ({regnr_navn[0]})</option>)
                    })}
                </select>
                {login.institutionSelect.fejl &&
                    <div className="alert alert-danger">{login.institutionSelect.fejl}</div>
                }
            </ModalBody>
        </Modal>
        )
    }

    if (institutioner.institutioner.length === 0 && institutioner.alerts) {
        return(
        <Modal isOpen={forside.showSelectInstitutionModal || (login.loggedIn && !login.bruger.institution)}>
            <ModalHeader>Vælg aktiv institution</ModalHeader>
            <ModalBody>
                <div className="alert alert-danger">{institutioner.alerts}</div>
                <p className="logout-in-modal">
                    <Link id="logud-knap" to={constants.urlLogout} className="stil-primary-button">Log ud</Link>
                </p>
            </ModalBody>
        </Modal>
        )
    }
}

export default OrgansiationModal
