import React, {Component} from 'react'
import OpretStamdata from "./OpretStamdata";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function RensKnap(props) {

  const kontaktId=props.kontaktId;

  if (kontaktId === null || kontaktId === undefined || kontaktId === 0) {
    return (
      <div className="buttongroup">
        <button onClick={props.handleClear} className="stil-primary-button button">Rens kontakt</button>
      </div>
    );
  }

  return (
    <div className="buttongroup">
      <button onClick={props.submit} className="stil-primary-button button">Slet kontakt</button>
    </div>
  );

}

class OpretKontakt extends Component {

  submit = (id, sletKontakt) => {
    confirmAlert({
      title: 'Bekræft sletning',
      message: 'Er du sikker på at du vil slette kontaktpersonen?',
      buttons: [
        {
          label: 'Annuller',
        },
        {
          label: 'Slet kontakt',
          onClick: () =>  sletKontakt(id) ,
        }
      ],
    })
  };

  render() {
    const {kontaktperson, handleChange, handleStamdataChange, handleClear, sletKontakt, status, index} = this.props;

    return (
      <div>
        <RensKnap submit={() => this.submit(kontaktperson.id, sletKontakt)} kontaktId={kontaktperson.id} handleClear={handleClear}/>
        <table>
          <tbody>
          <tr>
            <td>Relation:</td>
            <td>
              <select name={"kontaktrolle" + index} value={kontaktperson.kontaktrolle} onChange={handleChange}>
                <option value="">Vælg rolle</option>
                <option id={"kontakt-" + index + "-Far"} value={"FAR"}>Far</option>
                <option id={"kontakt-" + index + "-Mor"} value={"MOR"}>Mor</option>
                <option id={"kontakt-" + index + "-Officielt_tilknyttet_person"} value={"OFFICIELT_TILKNYTTET_PERSON"}>Officielt tilknyttet person</option>
                <option id={"kontakt-" + index + "-Andet"} value={"ANDET"}>Andet</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Forældremyndighed:</td>
            <td><input name="myndighed" type="checkbox" checked={kontaktperson.myndighed} onChange={handleChange}/></td>
          </tr>
          <OpretStamdata stamdata={kontaktperson.stamdata} handleChange={handleStamdataChange} idName={"kontakt-" + index} status={status} />
          </tbody>
        </table>
      </div>
    )
  }
}

export default OpretKontakt;