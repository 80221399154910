import 'whatwg-fetch';
import constants from "../util/constants";
import Cookie from "js-cookie";

function isEmpty(tekst) {
  return tekst === null || tekst === undefined || tekst === "";
}

function getNavn(elev) {
  let navn = "";
  if (!isEmpty(elev.stamdata.aliasEfternavn) || !isEmpty(elev.stamdata.aliasFornavn)) {
    navn = elev.stamdata.aliasFornavn + " " + elev.stamdata.aliasEfternavn;
  } else {
    navn = elev.stamdata.fornavn + " " + elev.stamdata.efternavn;
  }
  return navn;
}

export function henterElever(bool) {
  return {
    type: constants.HENT_ELEVER,
    henterElever: bool,
  }
}

export function hentEleverSuccess(instnr, elever) {
  return {
    type: constants.HENT_ELEVER_SUCCES,
    instnr: instnr,
    elever: elever
  }
}

export function hentEleverFejl(bool,errors) {
  return {
    type: constants.HENT_ELEVER_FEJL,
    hentEleverFejl: bool,
    alerts: {fejl: errors, info: []}
  }
}

export function henterElev(bool) {
  return {
    type: constants.HENT_ELEV,
    henterElev: bool,
  }
}

export function hentElevSuccess(elev) {
  return {
    type: constants.HENT_ELEV_SUCCES,
    hentetElev: elev
  }
}

export function hentElevFejl(bool, errors) {
  return {
    type: constants.HENT_ELEV_FEJL,
    hentElevFejl: bool,
    alerts: {fejl: errors, info: []}
  }
}

export function opretNyElev(bool) {
  return {
    type: constants.OPRET_ELEV,
    opretterElev: bool,
  }
}

export function opretElevSuccess(elev) {

  let navn = getNavn(elev);

  return {
    type: constants.OPRET_ELEV_SUCCES,
    nyElev: elev,
    alerts: {fejl: [], info: [navn + " er oprettet."]}
  }
}

export function opretElevFejl(bool, errors) {
  return {
    type: constants.OPRET_ELEV_FEJL,
    opretElevfejl: bool,
    alerts: {fejl: errors, info: []}
  }
}

export function retterElev(bool) {
  return {
    type: constants.RET_ELEV,
    retterElev: bool,
  }
}

export function retElevSuccess(elev) {

  let navn = getNavn(elev);

  return {
    type: constants.RET_ELEV_SUCCES,
    rettetElev: elev,
    alerts: {fejl: [], info: [navn + " er opdateret."]}
  }
}

export function retElevFejl(bool, errors) {
  return {
    type: constants.RET_ELEV_FEJL,
    retElevfejl: bool,
    alerts: {fejl: errors, info: []}
  }
}

export function hentElev(id) {
  return dispatch => {
    dispatch(henterElev(true));

    fetch(constants.apiHentElev + id, {
      method: "GET",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(henterElev(false));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(hentElevSuccess(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentElevFejl(true, [ "401" ]));
        else
          dispatch(hentElevFejl(true, [ "Eleven kan ikke hentes, prøv igen senere." ]));
      });
  }
}

export function hentElever(instnr) {
  return dispatch => {
    dispatch(henterElever(true));

    fetch(constants.apiHentElever, {
      method: "GET",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(henterElever(false));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(hentEleverSuccess(instnr, item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(hentEleverFejl(true, [ "401" ]));
        else
          dispatch(hentEleverFejl(true, [ "Eleverne kan ikke hentes, prøv igen senere." ]));
      });
  };
}

export function sletterKontakt(bool) {
  return {
    type: constants.SLET_KONTAKT,
    sletterKontakt: bool
  }
}

export function sletKontaktSucces(id) {
  return {
    type: constants.SLET_KONTAKT_SUCCES,
    kontaktId: id,
    alerts: {fejl: [], info: [ "Kontakten er slettet." ]}
  };
}

export function sletKontaktFejl(bool, errors) {
  return {
    type: constants.SLET_KONTAKT_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function sletKontakt(id) {
  return dispatch => {
    dispatch(sletterKontakt(true));

    fetch(constants.apiSletKontakt, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(id),
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(sletterKontakt(false));
        return response;
      })
      .then(dispatch(sletKontaktSucces(id)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(sletKontaktFejl(true, [ "401" ]));
        else
          dispatch(sletKontaktFejl(true, [ "Sletning er fejlet, prøv igen senere." ]));
      });
  };

}

export function sletterElever(bool) {
  return {
    type: constants.SLET_ELEVER,
    sletterElever: bool,
  }
}

export function sletEleverSucces(antalElever) {
  return (dispatch) => {
    dispatch(sletEleverSucceeded(antalElever));
  }
}

export function sletEleverSucceeded(antalElever) {

  let tekst = "";
  if (antalElever === 0) {
    tekst = "Ingen elever er slettet."
  } else if (antalElever === 1) {
    tekst = "Eleven er slettet.";
  } else {
    tekst = "Der er slettet " + antalElever + " elever.";
  }

  return {
    type: constants.SLET_ELEVER_SUCCES,
    eleverSlet: [],
    visSlet: false,
    alerts: {fejl: [], info: [tekst]}
  };
}

export function sletEleverFejl(bool, errors) {
  return {
    type: constants.SLET_ELEVER_FEJL,
    sletEleverFejl: bool,
    alerts: {fejl: errors, info: []}
  }
}

export function sletElever(elever) {

  return dispatch => {
    dispatch(sletterElever(true));

    fetch(constants.apiSletElever, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(elever),
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(sletterElever(false));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(sletEleverSucces(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(sletEleverFejl(true, [ "401" ]));
        else
          dispatch(sletEleverFejl(true, [ "Sletning er fejlet, prøv igen senere." ]));
      });
  };
}

export function elevRowClick(rowId2) {
  return {
    type: constants.ELEV_ROW_CLICK,
    rowId: rowId2,
  }
}

export function visElevModal() {
  return {
    type: constants.ELEV_MODAL_SHOW_CLICK,
  }
}

export const elevModalCloseClick = () => ({
  type: 'ELEV_MODAL_CLOSE_CLICK'
});

export function visElevStamkortModal(id) {
  return {
    type: constants.ELEV_STAMKORT_MODAL_SHOW_CLICK,
    elevId: id,
  }
}

export const elevStamkortModalCloseClick = () => ({
  type: 'ELEV_STAMKORT_MODAL_CLOSE_CLICK'
});

export function opretElev(person) {
  return dispatch => {
    dispatch(opretNyElev(true));

    fetch(constants.apiOpretElev, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(person),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        dispatch(opretNyElev(false));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(opretElevSuccess(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(retElevFejl(true, [ "401" ]));
        else
          errorResponse.json().then(data => dispatch(opretElevFejl(true, data)));
        dispatch(opretNyElev(false));
      });
  };
}

export function retElev(person) {

  return dispatch => {
    dispatch(retterElev(true));

    fetch(constants.apiRetElev, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
      },
      body: JSON.stringify(person),
    })
      .then((response) => {
        if (!response.ok)
          throw response;
        dispatch(retterElev(false));
        return response;
      })
      .then((response) => response.json())
      .then((item) => dispatch(retElevSuccess(item)))
      .catch((errorResponse) => {
        if (errorResponse.status === 401)
          dispatch(retElevFejl(true, [ "401" ]));
        else
          errorResponse.json().then(data => dispatch(retElevFejl(true, data)));
        dispatch(retterElev(false));
      });
  };
}


export function closeElevOversigtVisModal(){
  return (dispatch, getState) => {
    const { elever } = getState();
    if (elever.previousType === constants.ELEV_MODAL_SHOW_CLICK) {
        dispatch(closeModalAction());
        return {};
    }
  }
}

export function closeModalAction(){
  return {
    type: constants.ELEV_MODAL_CLOSE,
  }
}

export function resetSelectedElevRows(){
  return {
    type: constants.RESET_SELECTED_ELEV_ROWS,
  }
}

export function setElevAsSelected(elevRowId){
  return {
    type: constants.SET_ELEV_AS_SELECTED,
    rowId: elevRowId,
  }
}

export function aabenOpretElevSide() {
  return {
    type: constants.OPRET_ELEV_SIDE,
  }
}
