module.exports = {

  tokenExpiresHeader: 'Auth-Token-Expires',
  defaultFetchOptions: {
    credentials: 'same-origin',
    headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
  },

  // URLs
  urlForside: '/',
  urlLogin: '/elevadministration/oidc/login',
  urlLogout: '/logoutFE',
  urlBELogout: '/elevadministration/logout',
  urlKlasser: '/klasser',
  urlOprulning: '/oprulning',
  urlElever: '/elever',
  urlOpretElev: '/elever/opret',
  urlRedigerElev: '/elever/rediger',
  urlMedarbejdere: '/medarbejdere',
  urlOpretMedarbejder: '/medarbejdere/opret',
  urlRedigerMedarbejder: '/medarbejdere/rediger',

  // Parametre:
  postLogoutParam: 'logout',
  selectInstitutionParam: 'select-institution',

  // Actions
  // Login/out actions
  UPDATE_EXPIRES: "UPDATE_EXPIRES",
  HENT_BRUGEROPLYSNINGER_SUCCESS: "HENT_BRUGEROPLYSNINGER_SUCCESS",
  HENT_BRUGEROPLYSNINGER_FEJL_UNAUTHORIZED: "HENT_BRUGEROPLYSNINGER_FEJL_UNAUTHORIZED",
  HENT_BRUGEROPLYSNINGER_FEJL_ANDET: "HENT_BRUGEROPLYSNINGER_FEJL_ANDET",
  LOGIN_CLEAR: "LOGIN_CLEAR",
  SELECT_INSTITUTION: "SELECT_INSTITUTION",
  OPEN_INSTITUTION_MODAL: "OPEN_INSTITUTION_MODAL",
  SET_SELECT_INSTITUTION_FEJL: "SET_SELECT_INSTITUTION_FEJL",
  // Actions
  NULSTIL_ALERTS: 'NULSTIL_ALERTS',
  // Andre actions
  HENT_INSTITUTIONER_FEJL: "HENT_INSTITUTIONER_FEJL",
  HENT_INSTITUTIONER_SUCCESS: "HENT_INSTITUTIONER_SUCCESS",
  HENT_KLASSER: 'HENT_KLASSER',
  HENT_KLASSER_SUCCES: 'HENT_KLASSER_SUCCES',
  HENT_KLASSER_FEJL: 'HENT_KLASSER_FEJL',
  NY_KLASSE: 'NY_KLASSE',
  RET_KLASSE: 'RET_KLASSE',
  GEM_KLASSE: 'GEM_KLASSE',
  GEM_KLASSE_SUCCES: 'GEM_KLASSE_SUCCES',
  GEM_KLASSE_FEJL: 'GEM_KLASSE_FEJL',
  VALIDER_KLASSE_FEJL: 'VALIDER_KLASSE_FEJL',
  SLET_KLASSER: 'SLET_KLASSER',
  SLET_KLASSER_SUCCES: 'SLET_KLASSER_SUCCES',
  SLET_KLASSER_FEJL: 'SLET_KLASSER_FEJL',
  VIS_SLET_KLASSER: 'VIS_SLET_KLASSER',
  SKJUL_SLET_KLASSER: 'SKJUL_SLET_KLASSER',
  GEM_FEJL: 'GEM_FEJL',
  HENT_FEJL: 'HENT_FEJL',
  SLET_FEJL: 'SLET_FEJL',
  HENT_ELEVER: 'HENT_ELEVER',
  HENT_ELEVER_SUCCES: 'HENT_ELEVER_SUCCES',
  HENT_ELEVER_FEJL: 'HENT_ELEVER_FEJL',
  HENT_ELEV: 'HENT_ELEV',
  HENT_ELEV_SUCCES: 'HENT_ELEV_SUCCES',
  HENT_ELEV_FEJL: 'HENT_ELEV_FEJL',
  SLET_ELEVER: 'SLET_ELEVER',
  SLET_ELEVER_SUCCES: 'SLET_ELEVER_SUCCES',
  SLET_ELEVER_FEJL: 'SLET_ELEVER_FEJL',
  VIS_SLET_ELEVER: 'VIS_SLET_ELEVER',
  SKJUL_SLET_ELEVER: 'SKJUL_SLET_ELEVER',
  ELEV_ROW_CLICK: 'ELEV_ROW_CLICK',
  KLASSE_ROW_CLICK: 'KLASSE_ROW_CLICK',
  ELEV_MODAL_SHOW_CLICK: 'ELEV_MODAL_SHOW_CLICK',
  ELEV_MODAL_CLOSE_CLICK: 'ELEV_MODAL_CLOSE_CLICK',
  ELEV_STAMKORT_MODAL_SHOW_CLICK: 'ELEV_STAMKORT_MODAL_SHOW_CLICK',
  ELEV_STAMKORT_MODAL_CLOSE_CLICK: 'ELEV_STAMKORT_MODAL_CLOSE_CLICK',
  REDIGER_ELEV: 'REDIGER_ELEV',
  OPRET_ELEV: 'OPRET_ELEV',
  OPRET_ELEV_SUCCES: 'OPRET_ELEV_SUCCES',
  OPRET_ELEV_FEJL: 'OPRET_ELEV_FEJL',
  RET_ELEV: 'RET_ELEV',
  RET_ELEV_SUCCES: 'RET_ELEV_SUCCES',
  RET_ELEV_FEJL: 'RET_ELEV_FEJL',

  HENT_OPRUL_KLASSER: 'HENT_OPRUL_KLASSER',
  HENT_OPRUL_KLASSER_SUCCES: 'HENT_OPRUL_KLASSER_SUCCES',
  HENT_OPRUL_KLASSER_FEJL: 'HENT_OPRUL_KLASSER_FEJL',
  OPRUL_KLASSER: 'OPRUL_KLASSER',
  OPRUL_KLASSER_SUCCES: 'OPRUL_KLASSER_SUCCES',
  OPRUL_KLASSER_FEJL: 'OPRUL_KLASSER_FEJL',
  VIS_OPRUL_KLASSER: 'OPRUL_KLASSER',
  SKJUL_OPRUL_KLASSER: 'SKJUL_OPRUL_KLASSER',

  SLET_KONTAKT: 'SLET_KONTAKT',
  SLET_KONTAKT_SUCCES: 'SLET_KONTAKT_SUCCES',
  SLET_KONTAKT_FEJL: 'SLET_KONTAKT_FEJL',

  RESET_SELECTED_ELEV_ROWS: 'RESET_SELECTED_ELEV_ROWS',
  SET_ELEV_AS_SELECTED: 'SET_ELEV_AS_SELECTED',
  ELEV_MODAL_CLOSE: 'ELEV_MODAL_CLOSE',

  RESET_SELECTED_KLASSER_ROWS: 'RESET_SELECTED_KLASSER_ROWS',
  SET_KLASSE_AS_SELECTED: 'SET_KLASSE_AS_SELECTED',
  KLASSE_SLET_ENKELT_ROW_CLICK: 'KLASSE_SLET_ENKELT_ROW_CLICK',
  KLASSE_SLET_MARKEREDE_CLICK: 'KLASSE_SLET_MARKEREDE_CLICK',
  KLASSE_SLET_MODAL_CLOSE: 'KLASSE_SLET_MODAL_CLOSE',

  HENT_VEJLEDNING: 'HENT_VEJLEDNING',
  HENT_VEJLEDNING_SUCCES: 'HENT_VEJLEDNING_SUCCESS',
  HENT_VEJLEDNING_FEJL: 'HENT_VEJLEDNING_FEJL',

  HENT_ANNOUNCEMENT: 'HENT_ANNOUNCEMENT',
  HENT_ANNOUNCEMENT_SUCCES: 'HENT_ANNOUNCEMENT_SUCCES',
  HENT_ANNOUNCEMENT_FEJL: 'HENT_ANNOUNCEMENT_FEJL',

  HENT_MEDARBEJDERE: 'HENT_MEDARBEJDERE',
  HENT_MEDARBEJDERE_SUCCESS: 'HENT_MEDARBEJDERE_SUCCESS',
  HENT_MEDARBEJDERE_FEJL: 'HENT_MEDARBEJDERE_FEJL',
  OPRET_MEDARBEJDER: 'OPRET_MEDARBEJDER',
  OPRET_MEDARBEJDER_SUCCESS: 'OPRET_MEDARBEJDER_SUCCESS',
  OPRET_MEDARBEJDER_FEJL: 'OPRET_MEDARBEJDER_FEJL',
  RET_MEDARBEJDER: 'RET_MEDARBEJDER',
  RET_MEDARBEJDER_SUCCESS: 'RET_MEDARBEJDER_SUCCESS',
  RET_MEDARBEJDER_FEJL: 'RET_MEDARBEJDER_FEJL',
  HENT_MEDARBEJDER: 'HENT_MEDARBEJDER',
  HENT_MEDARBEJDER_SUCCESS: 'HENT_MEDARBEJDER_SUCCESS',
  HENT_MEDARBEJDER_FEJL: 'HENT_MEDARBEJDER_FEJL',
  SLET_MEDARBEJDERE: 'SLET_MEDARBEJDERE',
  SLET_MEDARBEJDERE_SUCCES: 'SLET_MEDARBEJDERE_SUCCES',
  SLET_MEDARBEJDERE_FEJL: 'SLET_MEDARBEJDERE_FEJL',
  MEDARBEJDER_MODAL_SHOW_CLICK: 'MEDARBEJDER_MODAL_SHOW_CLICK',
  MEDARBEJDER_MODAL_CLOSE_CLICK: 'MEDARBEJDER_MODAL_CLOSE_CLICK',
  MEDARBEJDER_MODAL_CLOSE: 'MEDARBEJDER_MODAL_CLOSE',
  MEDARBEJDER_ROW_CLICK: 'MEDARBEJDER_ROW_CLICK',
  RESET_SELECTED_MEDARBEJDER_ROWS: 'RESET_SELECTED_MEDARBEJDER_ROWS',
  SET_MEDARBEJDER_AS_SELECTED: 'SET_MEDARBEJDER_AS_SELECTED',
  MEDARBEJDER_STAMKORT_MODAL_SHOW_CLICK: 'MEDARBEJDER_STAMKORT_MODAL_SHOW_CLICK',
  MEDARBEJDER_STAMKORT_MODAL_CLOSE_CLICK: 'MEDARBEJDER_STAMKORT_MODAL_CLOSE_CLICK',
  OPRET_ELEV_SIDE: 'OPRET_ELEV_SIDE',
  OPRET_MEDARBEJDER_SIDE: 'OPRET_MEDARBEJDER_SIDE',

  // Stati
  status_login: 'Logger ind',
  status_login_fejl: 'Fejl i login',
  status_nulstiller_alerts: 'Nulstiller alerts',
  status_hent_institution_start: 'Henter institution',
  status_hent_institution_succes: 'Institution hentet',
  status_hent_institution_fejl: 'Fejl under hentning af Institution',
  status_ny_klasse: 'Ny klasse',
  status_ret_klasse: 'Ret klasse',
  status_hent_klasser_start: 'Henter klasser',
  status_hent_klasser_succes: 'Klasser hentet',
  status_hent_klasser_fejl: 'Fejl under hentning af Klasser',
  status_slet_klasser_start: 'Sletter klasser',
  status_slet_klasser_succes: 'Klasser slettet',
  status_slet_klasser_fejl: 'Fejl mens klasserne blev slettet',
  status_gem_klasse_start: 'Gemmer klasse',
  status_gem_klasse_succes: 'Klasse gemt',
  status_gem_klasse_fejl: 'Fejl da klassen blev gemt',
  status_fejl: 'fejl ved action',
  status_hent_elever_start: 'Henter elever',
  status_hent_elever_succes: 'elever hentet',
  status_hent_elever_fejl: 'Fejl under hentning af elever',
  status_hent_elev_start: 'Henter elev',
  status_hent_elev_succes: 'elev hentet',
  status_hent_elev_fejl: 'Fejl under hentning af elev',
  status_slet_elever_start: 'Sletter elever',
  status_slet_elever_succes: 'Elever slettet',
  status_slet_elever_fejl: 'Fejl mens eleverne blev slettet',
  status_hent_oprul_klasser_start: 'Henter klasser',
  status_hent_oprul_klasser_succes: 'Klasser hentet',
  status_hent_oprul_klasser_fejl: 'Fejl under hentning af Klasser',
  status_oprul_klasser_start: 'Opruller klasser',
  status_oprul_klasser_succes: 'Klasser oprullet',
  status_oprul_klasser_fejl: 'Fejl mens klasserne blev oprullet',
  status_opret_elev_start: 'Opretter elev',
  status_opret_elev_succes: 'Elev oprettet',
  status_opret_elev_fejl: 'Fejl da eleven blev oprettet',
  status_ret_elev_start: 'Retter elev',
  status_ret_elev_succes: 'Elev rettet',
  status_ret_elev_fejl: 'Fejl da eleven blev rettet',
  status_slet_kontakt_succes: 'Kontakt slettet',
  status_slet_kontakt_fejl: 'Fejl ved sletning af kontakt',
  status_hent_vejledning_start: 'Henter vejledning',
  status_hent_vejledning_succes: 'vejledning hentet',
  status_hent_vejledning_fejl: 'Fejl under hentning af vejledning',
  status_hent_announcement_start: 'Henter announcement',
  status_hent_announcement_succes: 'Announcement hentet',
  status_hent_announcement_fejl: 'Fejl under hentning af announcement',
  status_hent_medarbejdere_start: 'Henter medarbejdere',
  status_hent_medarbejdere_success: 'Medarbejdere hentet',
  status_hent_medarbejdere_fejl: 'Fejl ved hentning af medarbejdere',
  status_opret_medarbejder_start: 'Opretter medarbejder',
  status_opret_medarbejder_success: 'Medarbejder oprettet',
  status_opret_medarbejder_fejl: 'Fejl ved oprettelse af medarbejder',
  status_ret_medarbejder_start: 'Retter medarbejder',
  status_ret_medarbejder_success: 'Medarbejder rettet',
  status_ret_medarbejder_fejl: 'Fejl ved rettelse af medarbejder',
  status_hent_medarbejder_start: 'Henter medarbejder',
  status_hent_medarbejder_success: 'Medarbejder hentet',
  status_hent_medarbejder_fejl: 'Fejl ved hentning af medarbejder',
  status_slet_medarbejdere_start: 'Sletter medarbejdere',
  status_slet_medarbejdere_succes: 'Medarbejdere slettet',
  status_slet_medarbejdere_fejl: 'Fejl ved sletning af medarbejdere',
  status_hent_institutioner_fejl: 'Fejl ved henting af instittuioner',
  status_hent_institutioner_success: 'Instituioner hentet',
  // api points

  apiBrugeroplysninger: '/elevadministration/api/common/brugeroplysninger',
  apiSkiftInstitution: '/elevadministration/api/common/active-institution',
  apiHentInstitutioner: '/elevadministration/api/common/institutioner/hent',
  apiHentKlasser: '/elevadministration/api/elevadm/klasse/hent',
  apiGemKlasse: '/elevadministration/api/elevadm/klasse/gem',
  apiSletKlasser: '/elevadministration/api/elevadm/klasse/slet',
  apiHentElever: '/elevadministration/api/elevadm/elever/hent',
  apiHentElev: '/elevadministration/api/elevadm/elever/',
  apiSletElever: '/elevadministration/api/elevadm/elever/slet',
  apiOpretElev: '/elevadministration/api/elevadm/elever/opret',
  apiRetElev: '/elevadministration/api/elevadm/elever/ret',
  apiSletKontakt: '/elevadministration/api/elevadm/elever/sletkontakt',
  apiHentOprulKlasser: 'api/elevadm/oprulklasse/hent',
  apiOprulKlasser: 'api/elevadm/oprulklasse/oprulklasser',
  apihentVejledningUrl: '/elevadministration/api/public/vejlening/url/',
  apihentAnnouncement: '/elevadministration/api/public/announcement',
  apiHentMedarbejdere: '/elevadministration/api/medarbejderadm/medarbejdere/hent',
  apiHentMedarbejder: '/elevadministration/api/medarbejderadm/medarbejdere/',
  apiOpretMedarbejder: '/elevadministration/api/medarbejderadm/medarbejdere/opret',
  apiRetMedarbejder: '/elevadministration/api/medarbejderadm/medarbejdere/ret',
  apiSletMedarbejdere: '/elevadministration/api/medarbejderadm/medarbejdere/slet',

  // adgang

  adgangElevAdm: 'ELEVADM',
  adgangMedarbejderAdm: 'MEDARBEJDERADM',

  //misc. constants

  // State
  loginStateInit: {
    loggedIn: undefined, // true/false, eller undefined for state under hentning af login-state.
    bruger: {
      brugerid: null,
      navn: null,
      cvr: null,
      institutioner: [],
      institution: {jobKoersel: null},
      adgange: [],
      flereInstitutioner: false,
    },
    institutioner: {
      institutioner: [],
      alerts: null,
    },
    announcement: null,
    institutionSelect: {
      status: null,
      fejl: null
    }
  },
  forsideStateInit: {
    status: false,
    alerts: {fejl: [], info: []},
    showSelectInstitutionModal: false
  },

  // **************************************************************************************

};
