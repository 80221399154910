import React, {Component} from 'react'
import constants from "../util/constants"; // Import css
import {Link} from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function isArrayEmpty(arr) {
  return arr === null || arr === undefined || !Array.isArray(arr) || arr.length === 0;
}

function isEmpty(streng) {
  return streng === null || streng === undefined || streng.trim().length === 0;
}

function Telefoner(props) {
  const stamdata = props.stamdata;
  if (isArrayEmpty(stamdata.telefoner))
    return <tr key="0"><td>Telefon</td><td/></tr>;

  const telefoner = stamdata.telefoner.map((telefon) => {
    if(telefon.beskyttet === true){
      return(
        <tr key={telefon.type}><td>Telefon - {telefon.type}</td><td>
          <div className="standard-tooltip">
              Beskyttet
            <span className="standard-tooltiptext">
                {telefon.nummer}
            </span>
          </div>
      </td></tr>
      );
    }else if (stamdata.beskyttet){
      return(
        <tr key={telefon.type}><td>Telefon - {telefon.type}</td><td>
          <div className="standard-tooltip">
            Navnebeskyttet
            <span className="standard-tooltiptext">
              {telefon.nummer}
            </span>
          </div>
        </td></tr>
        );
    }else{
      return(<tr key={telefon.type}><td>Telefon - {telefon.type}</td><td>{telefon.nummer}</td></tr>);
    }
  });

  return [telefoner];
}

function KontaktTelefoner(props) {
  const kontakter = props.kontakter;
  if (isArrayEmpty(kontakter))
    return <tr key="0"><td>Telefon</td><td/><td/></tr>;

  const stamdata1 = kontakter[0].stamdata;
  const stamdata2 = kontakter.length > 1 ? kontakter[1].stamdata : null;

  let stamdata1Sikkerhed = "";
  let stamdata2Sikkerhed = "";

  if (stamdata1.beskyttet){
    stamdata1Sikkerhed = 'navnebeskyttet';
  }
  if (stamdata2 !== null && stamdata2.beskyttet){
    stamdata2Sikkerhed = 'navnebeskyttet';
  }

  if (isArrayEmpty(stamdata1.telefoner) && (stamdata2 === null || isArrayEmpty(stamdata2.telefoner)))
    return <tr key="0"><td>Telefon</td><td/><td/></tr>;

  let data = new Array(3);
  for (let i = 0; i < 3; i++)
    data[i]=new Array(3);

  if (!isArrayEmpty(stamdata1.telefoner)) {
    for (let i = 0; i < stamdata1.telefoner.length; i++) {
      data[i][0] = " - " + stamdata1.telefoner[i].type;
      data[i][1] = stamdata1.telefoner[i].nummer;
      data[i][3] = stamdata1.telefoner[i].beskyttet ? "beskyttet" : stamdata1Sikkerhed;
    }

  } else {
    data[0][0] = "";
    data[0][1] = "";
  }

  if (stamdata2 !== null && !isArrayEmpty(stamdata2.telefoner)) {
    for (let i = 0; i < stamdata2.telefoner.length; i++) {
      let j = 0;
      while ( j < data.length ) {
        if (data[j][0] === undefined || data[j][0] === "" || data[j][0] === " - " + stamdata2.telefoner[i].type)
          break;
        ++j;
      }
      data[j][0] = stamdata2.telefoner[i].type;
      data[j][2] = stamdata2.telefoner[i].nummer;
      data[j][4] = stamdata2.telefoner[i].beskyttet ? "beskyttet" : stamdata2Sikkerhed;
    }
  } else {
    if (data[0][0] === undefined){
      data[0][0] = "";
    }
    data[0][2] = "";
  }

  const telefoner = data.filter((element) => element[0] !== undefined)
    .map((element) => {

    if(element[3] === '' && element[4] === ''){
      return(<tr key={element[0]}><td>Telefon - {element[0]}</td><td>{element[1]}</td><td>{element[2]}</td></tr>);
    }
    else{

    var result = [];
    result.push(
      <td>Telefon - {element[0]}</td>
    );

    if(element[3] === 'navnebeskyttet'){
      result.push(
        <td>
          <div className="standard-tooltip">
            Navnebeskyttet
            <span className="standard-tooltiptext">
                {element[1]}
            </span>
          </div>
        </td>
      );
    }
    if(element[4] === 'navnebeskyttet'){
      result.push(
        <td>
          <div className="standard-tooltip">
            Navnebeskyttet
            <span className="standard-tooltiptext">
                {element[2]}
            </span>
          </div>
        </td>
      );
    }
    if(element[3] === 'beskyttet'){
      result.push(
        <td>
          <div className="standard-tooltip">
            Beskyttet
            <span className="standard-tooltiptext">
                {element[1]}
            </span>
          </div>
        </td>
      );
    }
    if(element[4] === 'beskyttet'){
      result.push(
        <td>
          <div className="standard-tooltip">
            Beskyttet
            <span className="standard-tooltiptext">
                {element[2]}
            </span>
          </div>
        </td>
      );
    }
    if(element[3] === '' || element[3] === undefined){
      result.push(
        <td>
          {element[1]}
        </td>
      );
    }
    if(element[4] === '' || element[4] === undefined){
      result.push(
        <td>
          {element[2]}
        </td>
      );
    }

    return (<tr key={element[0]}>{result}</tr>);
}
  });

  return [telefoner];
}


class ElevStamkortModal extends Component {

  constructor(props) {
    super(props);
    this.state = {visible: true};
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  //TODO:Husk stamkort slet til bootstrap confirm vindue.
  static formatNavn(stamdata) {
    if(stamdata.beskyttet){
      return(
        <div className="standard-tooltip">
        {ElevStamkortModal.aliasnavn(stamdata)}
        <span className="standard-tooltiptext">
        {ElevStamkortModal.navn(stamdata)}
        </span>
        </div>);
    }else{
      return ElevStamkortModal.navn(stamdata);
    }
  }

  static formatAdresse(stamdata){
    if(stamdata.beskyttet)
    return(
      <div className="standard-tooltip">
      Navnebeskyttet
      <span className="standard-tooltiptext">
      {ElevStamkortModal.adresse(stamdata)};
      </span>
      </div>);
    else
      return (ElevStamkortModal.adresse(stamdata));
  }

  static formatMail(stamdata){
    if(stamdata.beskyttet)
    return(
      <div className="standard-tooltip">
        Navnebeskyttet
        <span className="standard-tooltiptext">{stamdata.email}</span>
      </div>);
    else
      return stamdata.email;
  }

  static adresse(stamdata) {
    const v = stamdata.vej;
    const p = stamdata.postnr;
    const b = stamdata.postby;
    if (isEmpty(v))
      return isEmpty(p) ? isEmpty(b) ? "" : b : isEmpty(b) ? p : p + " " + b;
      return isEmpty(p) ? isEmpty(b) ? v : v + ", " + b : isEmpty(b) ? v + ", " + p : v + ", " + p + " " + b;
  }

  static kontaktnavn(kontakter, idx) {
    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";
    return ElevStamkortModal.navn(kontakter[idx].stamdata);
  }

  static navn(stamdata) {
    const f = stamdata.fornavn;
    const e = stamdata.efternavn;
    return isEmpty(f) ? isEmpty(e) ? "" : e : isEmpty(e) ? f : f + " " + e;
  }

  static aliasnavn(stamdata) {
    const f = stamdata.aliasFornavn;
    const e = stamdata.aliasEfternavn;
    return isEmpty(f) ? isEmpty(e) ? "" : e : isEmpty(e) ? f : f + " " + e;
  }

  static kontaktcpr(kontakter, idx) {
    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";
    return kontakter[idx].stamdata.cpr;
  }

  static kontaktalias(kontakter, idx) {
    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";
    return ElevStamkortModal.aliasnavn(kontakter[idx].stamdata);
  }

  static kontaktadresse(kontakter, idx) {
    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";
    return ElevStamkortModal.adresse(kontakter[idx].stamdata);
  }

  static kontaktemail(kontakter, idx) {
    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";
    return kontakter[idx].stamdata.email;
  }

  static formatKontaktnavn(kontakter, idx) {

    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";

    if(kontakter[idx].stamdata.beskyttet && !(kontakter[idx].stamdata.fornavn === "" || kontakter[idx].stamdata.fornavn === null || kontakter[idx].stamdata.fornavn === undefined)){
      return(
        <div className="standard-tooltip">
        {ElevStamkortModal.aliasnavn(kontakter[idx].stamdata)}
        <span className="standard-tooltiptext">
        {ElevStamkortModal.navn(kontakter[idx].stamdata)}
        </span>
        </div>
      );
    }else{
      return ElevStamkortModal.navn(kontakter[idx].stamdata);
    }
  }

  static formatKontaktadresse(kontakter, idx) {
    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";
    if(kontakter[idx].stamdata.beskyttet && !(kontakter[idx].stamdata.vej === "" || kontakter[idx].stamdata.vej === null || kontakter[idx].stamdata.vej === undefined))
    return(
      <div className="standard-tooltip">
      Navnebeskyttet
      <span className="standard-tooltiptext">
      {ElevStamkortModal.adresse(kontakter[idx].stamdata)};
      </span>
      </div>);
    else
      return (ElevStamkortModal.adresse(kontakter[idx].stamdata));
  }

  static formatKontaktemail(kontakter, idx) {
    if (isArrayEmpty(kontakter) || idx >= kontakter.length)
      return "";
    if(kontakter[idx].stamdata.beskyttet && !(kontakter[idx].stamdata.email === "" || kontakter[idx].stamdata.email === null || kontakter[idx].stamdata.email === undefined))
    return(
      <div className="standard-tooltip">
        Navnebeskyttet
        <span className="standard-tooltiptext">{kontakter[idx].stamdata.email}</span>
      </div>);
    else
      return kontakter[idx].stamdata.email;
  }

    render() {
        const {elever, selectedElevRows, showElevStamkortModal, lukElevStamkortModal, openSletElevModal} = this.props;

        if (isArrayEmpty(selectedElevRows) || isArrayEmpty(elever))
            return null;

        let elev = null;
        for (let i = 0; elev === null && i < elever.length; i++) {
            if (elever[i].id === selectedElevRows[0])
                elev = elever[i];
        }

        if (elev === null) {
            return null;
        }


        return (
          <Modal isOpen={showElevStamkortModal} toggle={lukElevStamkortModal} onClosed={lukElevStamkortModal}>
            <ModalHeader>
             <Button type="button" className="close" data-dismiss="modal" onClick={lukElevStamkortModal} aria-hidden="true">×</Button>
              <p>
              Elevens stamkort
              </p>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Navn</td><td>{ElevStamkortModal.formatNavn(elev.stamdata)}</td>
                                </tr>
                                <tr>
                                    <td>CPR-nummer</td><td>{elev.stamdata.cpr}</td>
                                </tr>
                                <tr>
                                    <td>Adresse</td><td>{ElevStamkortModal.formatAdresse(elev.stamdata)}</td>
                                </tr>
                                <Telefoner stamdata={elev.stamdata} />
                                <tr>
                                    <td>Email</td><td>{ElevStamkortModal.formatMail(elev.stamdata)}</td>
                                </tr>
                                <tr>
                                    <td>Klasse</td><td>{elev.elev.klasse.gruppenavn}</td>
                                </tr>
                                <tr>
                                  <td>UniId</td><td>{elev.uniId ? elev.uniId : ""}</td>
                                </tr>
                            </tbody>
                        </table>

                        <hr/>

                        <table>
                            <tbody>
                                <tr>
                                    <th> </th><th>1. Kontaktperson</th><th>2. Kontaktperson</th>
                                </tr>
                                <tr>
                                    <td>Navn</td><td>{ElevStamkortModal.formatKontaktnavn(elev.elev.kontakter, 0)}</td><td>{ElevStamkortModal.formatKontaktnavn(elev.elev.kontakter, 1)}</td>
                                </tr>
                                <tr>
                                    <td>CPR-nummer</td><td>{ElevStamkortModal.kontaktcpr(elev.elev.kontakter, 0)}</td><td>{ElevStamkortModal.kontaktcpr(elev.elev.kontakter, 1)}</td>
                                </tr>
                                <tr>
                                    <td>Adresse</td><td>{ElevStamkortModal.formatKontaktadresse(elev.elev.kontakter, 0)}</td><td>{ElevStamkortModal.formatKontaktadresse(elev.elev.kontakter, 1)}</td>
                                </tr>
                                <KontaktTelefoner kontakter={elev.elev.kontakter} />
                                <tr>
                                    <td>Email</td><td>{ElevStamkortModal.formatKontaktemail(elev.elev.kontakter, 0)}</td><td>{ElevStamkortModal.formatKontaktemail(elev.elev.kontakter, 1)}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div>
                        </div>
                    </div>
                </div>
            </ModalBody>
          <ModalFooter>
            <div className="buttongroup">
              <button className="margleft10 stil-primary-button button" onClick={lukElevStamkortModal}>Tilbage til Elever</button>
              <button className="margleft10 stil-primary-button button" onClick={() =>  openSletElevModal()}>Slet elev</button>
              <Link className="margleft10 stil-primary-button" to={constants.urlRedigerElev+'/'+elev.id}>Rediger elev</Link>
            </div>
          </ModalFooter>
      </Modal>
    )
  }
}

export default ElevStamkortModal
