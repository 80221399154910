import 'whatwg-fetch';
import React from 'react';
import'babel-polyfill';
import './css/main.css';
import './css/stil-baseline.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import configureStore from "./store/configureStore";
import * as constants from "./util/constants";
import {createRoot} from "react-dom/client";

const state = {
  myInitState: {
    app: {
      active: "Elever"
    },
    login: constants.loginStateInit,
    forside: constants.forsideStateInit,
    klasser: {
      previousType: null,
      klasser: [],
      henterKlasser: false,
      hentKlasserFejl: false,
      gemmerKlasse: false,
      gemKlasseFejl: false,
      sletterKlasser: false,
      sletKlasseFejl: false,
      visSlet: false,
      klasserSlet: [],
      status: null,
      selectedKlasseRows: [],
      showKlasseModal: false,
      klasseId: null,
      alerts: { fejl: [], info: [] },
    },
    oprulning: {
      oprulKlasser: [],
      hentOprulKlasserFejl: false,
      oprullerKlasser: false,
      oprulKlasserFejl: false,
      visOprulModal: false,
      redirect2Klasser: false,
      status: false,
      alerts: { fejl: [], info: [] },
    },
    elever: {
      instnr: null,
      elever: [],
      henterElever: false,
      hentEleverFejl: false,
      status: null,
      selectedElevRows: [],
      showElevModal: false,
      opretterElev: false,
      opretterElevFejl: false,
      opretElevValideringsfejl: [],
      showElevStamkortModal: false,
      alerts: { fejl: [], info: [] },
      cprData: {
        elev: null,
        kontakt1: null,
        kontakt2: null,
        medarbejder: null
      }
    },
    medarbejdere: {
      medarbejdere: [],
      selectedMedarbejderRows: [],
      showMedarbejderModal: false,
      showMedarbejderStamkortModal: false,
      alerts: { fejl: [], info: [] },
      cprData: {
        elev: null,
        kontakt1: null,
        kontakt2: null,
        medarbejder: null
      }
    }
  }
};

const store = configureStore(state.myInitState);

const container = document.getElementById('react');
const root = createRoot(container);

root.render(
    <BrowserRouter basename="/elevadministration">
    <Provider store={store}>
      <App/>
    </Provider>
    </BrowserRouter>
);
