import React, {Component} from 'react'

class EksportStatus extends Component {

  formatDate(milliseconds) {
    if(milliseconds){
      let date = new Date(milliseconds);
      let months = ["januar", "februar", "marts", "april", "maj", "juni",
        "juli", "august", "september", "oktober", "november", "december"];

      let dag = date.getDate();
      let maaned = months[date.getMonth()];
      let aar = date.getFullYear();
      let time = this.prependZero(date.getHours());
      let minut = this.prependZero(date.getMinutes());
      return dag + ". " + maaned + " " + aar + " klokken " + time + ":" + minut;
    }
    return "Endnu ikke importeret.";
  }

  prependZero(num){
    return num < 10 ? "0" + num : num;
  }

  render(){
    const{senesteEksport} = this.props;
    if(senesteEksport){
      return(
        <p>
          Seneste OK import til UNI-Login:<br/>
          {this.formatDate(senesteEksport.slut)}<br/>
          Næste import til UNI-Login:<br/>
          {this.formatDate(senesteEksport.nextEksport)}
        </p>
      );
    }
    return "Endnu ikke eksporteret.";
  }
}

export default EksportStatus;