import React, {Component} from 'react'
import {deepEquals, nulls2Empty, isEmptyStr, isEmpty, isEmptyOrZero, isEmptyArray} from "../../util/util.js";
import OpretStamdata from "./OpretStamdata";
import OpretKontakt from "./OpretKontakt";
import {Navigate, Link} from "react-router-dom";
import constants from "../../util/constants";
import AlertBar from "../../util/AlertBar";

// Erstatningspersonnummer sættes altid til false, da den ikke benyttes mere
const erstatningspersonnummer = false;

const stamdataTemplate = {
  cpr: '',
  erstatningspersonnummer: erstatningspersonnummer,
  beskyttet: false,
  fornavn: '',
  efternavn: '',
  aliasFornavn: '',
  aliasEfternavn: '',
  vej: '',
  postnr: '',
  postby: '',
  email: '',
  telefontype: "Mobil",
  telefonnummer: '',
  telefonBeskyttet: false
};

function getTelefoner(kontakt) {

  let tlf = kontakt.stamdata.telefonnummer;

  if (!isEmptyStr(tlf)) {
    return [{
      beskyttet: kontakt.stamdata.telefonBeskyttet,
      nummer: tlf,
      type: kontakt.stamdata.telefontype,
    }];
  }
  return [];
}

function erKontaktFjernet(hentetElev, kontakt) {

  if (isEmptyOrZero(kontakt.id) || isEmpty(hentetElev) || isEmpty(hentetElev.elev))
    return false;

  if (isEmptyArray(hentetElev.elev.kontakter))
    return true;

  if (hentetElev.elev.kontakter.length === 2)
    return false;

  return kontakt.id !== hentetElev.elev.kontakter[0].id;

}

function harValideringsfejl(val) {
  if (isEmpty(val))
    return false;
  return !isEmptyArray(val.elev) || !isEmptyArray(val.kontakt1) || !isEmptyArray(val.kontakt2);
}

function getValideringsfejl(val) {

  let fejl = [];

  if (!isEmptyArray(val.elev)) {
    for (let i in val.elev) {
      if (val.elev.hasOwnProperty(i))
        fejl.push("Elev. " + val.elev[i]);
    }
  }

  if (!isEmptyArray(val.kontakt1)) {
    for (let i in val.kontakt1) {
      if (val.kontakt1.hasOwnProperty(i))
        fejl.push("Første kontaktperson. " + val.kontakt1[i]);
    }
  }

  if (!isEmptyArray(val.kontakt2)) {
    for (let i in val.kontakt2) {
      if (val.kontakt2.hasOwnProperty(i))
        fejl.push("Anden kontaktperson. " + val.kontakt2[i]);
    }
  }

  return {fejl: fejl, info: []};
}

class OpretElev extends Component {
  constructor(props) {
    super(props);
    const {instnr} = this.props;
    this.state = {
      klasser: [],
      //Person
      lokalId: null,
      instnr: instnr,
      //Elev
      rolle: "Elev",//Defaulter til Elev, intet andet bruges lige nu.
      niveau: null, //Ikke vigtigt, bliver overruled i mapper.
      //klasse
      gruppekode: '',
      stamdata: {...stamdataTemplate},
      //Kontakter
      kontakt1: {
        id: 0,
        kontaktrolle: '',
        myndighed: false,
        stamdata: {...stamdataTemplate},
      },
      kontakt2: {
        id: 0,
        kontaktrolle: '',
        myndighed: false,
        stamdata: {...stamdataTemplate},
      },
      validation: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStamdataChange = this.handleStamdataChange.bind(this);
    this.handleKontakt1Change = this.handleKontakt1Change.bind(this);
    this.handleKontakt1StamdataChange = this.handleKontakt1StamdataChange.bind(this);
    this.handleKontakt1Clear = this.handleKontakt1Clear.bind(this);
    this.handleKontakt2Change = this.handleKontakt2Change.bind(this);
    this.handleKontakt2StamdataChange = this.handleKontakt2StamdataChange.bind(this);
    this.handleKontakt2Clear = this.handleKontakt2Clear.bind(this);
    this.sletKontakten = this.sletKontakten.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
    this.validateElev = this.validateElev.bind(this);
  }

  componentDidUpdate() {
    const {id, hentetElev} = this.props;

    if (id && hentetElev && Number(id) === hentetElev.id && hentetElev.lokalId !== this.state.lokalId) {
      this.setState({
        lokalId: hentetElev.lokalId,
        gruppekode: hentetElev.elev.klasse.gruppekode,
        stamdata: {
          ...hentetElev.stamdata,
          //Sætter nullable felter eksplicit, da react ikke bryder sig om skift fra managed -> unmanaged form element.
          aliasFornavn: hentetElev.stamdata.aliasFornavn !== null ? hentetElev.stamdata.aliasFornavn : '',
          aliasEfternavn: hentetElev.stamdata.aliasEfternavn !== null ? hentetElev.stamdata.aliasEfternavn : '',
          email: hentetElev.stamdata.email !== null ? hentetElev.stamdata.email : '',
          telefonnummer: hentetElev.stamdata.telefoner.length > 0 ? hentetElev.stamdata.telefoner[0].nummer : '',
          telefonBeskyttet: hentetElev.stamdata.telefoner.length > 0 ? hentetElev.stamdata.telefoner[0].beskyttet : false,
          telefontype: "Mobil",
        },
        kontakt1: hentetElev.elev.kontakter.length < 1 ? {...this.state.kontakt1} : {
          id: hentetElev.elev.kontakter[0].id,
          kontaktrolle: hentetElev.elev.kontakter[0].kontaktrolle,
          myndighed: hentetElev.elev.kontakter[0].myndighed,
          stamdata: {
            ...hentetElev.elev.kontakter[0].stamdata,
            //Sætter nullable felter eksplicit, da react ikke bryder sig om skift fra managed -> unmanaged form element.
            aliasFornavn: hentetElev.elev.kontakter[0].stamdata.aliasFornavn !== null ? hentetElev.elev.kontakter[0].stamdata.aliasFornavn : '',
            aliasEfternavn: hentetElev.elev.kontakter[0].stamdata.aliasEfternavn !== null ? hentetElev.elev.kontakter[0].stamdata.aliasEfternavn : '',
            email: hentetElev.elev.kontakter[0].stamdata.email !== null ? hentetElev.elev.kontakter[0].stamdata.email : '',
            telefonnummer: hentetElev.elev.kontakter[0].stamdata.telefoner.length > 0 ? hentetElev.elev.kontakter[0].stamdata.telefoner[0].nummer : '',
            telefonBeskyttet: hentetElev.elev.kontakter[0].stamdata.telefoner.length > 0 ? hentetElev.elev.kontakter[0].stamdata.telefoner[0].beskyttet : false,
            telefontype: "Mobil",
          }
        },
        kontakt2: hentetElev.elev.kontakter.length < 2 ? {...this.state.kontakt2} : {
          id: hentetElev.elev.kontakter[1].id,
          kontaktrolle: hentetElev.elev.kontakter[1].kontaktrolle,
          myndighed: hentetElev.elev.kontakter[1].myndighed,
          stamdata: {
            ...hentetElev.elev.kontakter[1].stamdata,
            //Sætter nullable felter eksplicit, da react ikke bryder sig om skift fra managed -> unmanaged form element.
            aliasFornavn: hentetElev.elev.kontakter[1].stamdata.aliasFornavn !== null ? hentetElev.elev.kontakter[1].stamdata.aliasFornavn : '',
            aliasEfternavn: hentetElev.elev.kontakter[1].stamdata.aliasEfternavn !== null ? hentetElev.elev.kontakter[1].stamdata.aliasEfternavn : '',
            email: hentetElev.elev.kontakter[1].stamdata.email !== null ? hentetElev.elev.kontakter[1].stamdata.email : '',
            telefonnummer: hentetElev.elev.kontakter[1].stamdata.telefoner.length > 0 ? hentetElev.elev.kontakter[1].stamdata.telefoner[0].nummer : '',
            telefonBeskyttet: hentetElev.elev.kontakter[1].stamdata.telefoner.length > 0 ? hentetElev.elev.kontakter[1].stamdata.telefoner[0].beskyttet : false,
            telefontype: "Mobil",
          }
        },
      });
    } else {
      if (erKontaktFjernet(hentetElev, this.state.kontakt1)) {
        this.setState({
          kontakt1: {
            kontaktrolle: '',
            myndighed: false,
            stamdata: {...stamdataTemplate}
          }
        });
      }
      if (erKontaktFjernet(hentetElev, this.state.kontakt2)) {
        this.setState({
          kontakt2: {
            kontaktrolle: '',
            myndighed: false,
            stamdata: {...stamdataTemplate}
          }
        });
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleStamdataChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      stamdata: {...this.state.stamdata, [name]: value}
    });
  }

  handleKontakt1Change(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name === 'kontaktrolle1' ? 'kontaktrolle' : target.name;
    this.setState({
      kontakt1: {...this.state.kontakt1, [name]: value}
    });
  }

  handleKontakt1StamdataChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const kontakt = {...this.state.kontakt1};
    kontakt.stamdata[name] = value;
    this.setState({
      kontakt1: {...this.state.kontakt1, stamdata: kontakt.stamdata}
    });
  }

  handleKontakt1Clear() {
    this.setState({
      kontakt1: {
        kontaktrolle: '',
        myndighed: false,
        stamdata: {...stamdataTemplate}
      }
    });
  }


  handleKontakt2Change(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name === 'kontaktrolle2' ? 'kontaktrolle' : target.name;
    this.setState({
      kontakt2: {...this.state.kontakt2, [name]: value}
    });
  }

  handleKontakt2StamdataChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const kontakt = {...this.state.kontakt2};
    kontakt.stamdata[name] = value;
    this.setState({
      kontakt2: {...this.state.kontakt2, stamdata: kontakt.stamdata}
    });
  }

  handleKontakt2Clear() {
    this.setState({
      kontakt2: {
        kontaktrolle: '',
        myndighed: false,
        stamdata: {...stamdataTemplate}
      }
    });
  }

  sletKontakten(id) {
    const {sletKontakt} = this.props;
    sletKontakt(id);
  }

  validate() {
    const validation = {};
    //Omvejen med at assigne først, er for at kunne returnere en boolean til slut, ved at tjekke for tomt object.
    const elev = this.validateElev();
    if (elev.length !== 0)
      validation.elev = elev;
    const kontakt1 = OpretElev.validateKontakt(this.state.kontakt1);
    if (kontakt1.length !== 0)
      validation.kontakt1 = kontakt1;
    const kontakt2 = OpretElev.validateKontakt(this.state.kontakt2);
    if (kontakt2.length !== 0)
      validation.kontakt2 = kontakt2;

    this.setState({validation: validation});
    return Object.keys(validation).length === 0;
  }

  validateElev() {
    const validation = [];
    if (this.state.gruppekode === '')
      validation.push('Klasse skal angives');

    OpretElev.validateStamdata(this.state.stamdata, validation);
    return validation;
  }

  static validateKontakt(kontakt) {
    const validation = [];

    if (kontakt.stamdata.cpr === '') {
      if (kontakt.kontaktrolle !== '' ||
        kontakt.myndighed ||
        kontakt.stamdata.beskyttet ||
        kontakt.stamdata.fornavn !== '' ||
        kontakt.stamdata.efternavn !== '' ||
        kontakt.stamdata.aliasFornavn !== '' ||
        kontakt.stamdata.aliasEfternavn !== '' ||
        kontakt.stamdata.vej !== '' ||
        kontakt.stamdata.postnr !== '' ||
        kontakt.stamdata.postby !== '' ||
        kontakt.stamdata.email !== '' ||
        kontakt.stamdata.telefonnummer !== '' ||
        kontakt.stamdata.telefonBeskyttet) {
        validation.push('CPR skal angives.');
        return validation;
      }
      return [];
    }
    if (kontakt.stamdata.cpr !== '' && kontakt.kontaktrolle === '')
      validation.push('Relation skal sættes på kontaktperson.');
    OpretElev.validateStamdata(kontakt.stamdata, validation);
    return validation;
  }

  static validateStamdata(stamdata, validation) {
    OpretElev.validateCpr(stamdata, validation);
    if (stamdata.beskyttet && (stamdata.aliasFornavn === '' || stamdata.aliasEfternavn === '' || stamdata.aliasFornavn.trim().length === 0 || stamdata.aliasEfternavn.trim().length === 0))
      validation.push('Alias fornavn og -efternavn skal angives, når personen er navne- og adressebeskyttet');
    if (stamdata.vej === '' || stamdata.vej.trim().length === 0)
      validation.push('Adresse skal angives.');
    if (stamdata.postnr === '' || stamdata.postnr.trim().length === 0)
      validation.push('Postnr skal angives');
    if (stamdata.postby === '' || stamdata.postby.trim().length === 0)
      validation.push("By skal angives");
    if (stamdata.telefonBeskyttet && (stamdata.telefonnummer === '' || stamdata.telefonnummer.trim().length === 0))
      validation.push('Telefonnummer skal være angivet, hvis beskyttet telefon er valgt.');
    if (stamdata.fornavn === '' || stamdata.fornavn.trim().length === 0)
      validation.push('Fornavn skal angives.');
    if (stamdata.efternavn === '' || stamdata.efternavn.trim().length === 0)
      validation.push('Efternavn skal angives.');
  }

  static validateCpr(stamdata, validation) {
    if (stamdata.cpr === '' || stamdata.cpr.trim().length === 0)
      validation.push('CPR skal angives.');
  }

  handleSave() {
    const {opretElev, retElev, opretterElev, instnr} = this.props;
    if (opretterElev)
      return;

    const kontakter = [];
    if (!this.validate())
      return;
    if (this.state.kontakt1.stamdata.cpr !== "") {
      const kontakt1 = {
        id: this.state.kontakt1.id,
        kontaktrolle: this.state.kontakt1.kontaktrolle,
        myndighed: this.state.kontakt1.myndighed,
        stamdata: {
          aliasEfternavn:this.state.kontakt1.stamdata.aliasEfternavn,
          aliasFornavn:this.state.kontakt1.stamdata.aliasFornavn,
          beskyttet: this.state.kontakt1.stamdata.beskyttet,
          cpr: this.state.kontakt1.stamdata.cpr,
          efternavn: this.state.kontakt1.stamdata.efternavn,
          email: this.state.kontakt1.stamdata.email,
          erstatningspersonnummer: erstatningspersonnummer,
          fornavn: this.state.kontakt1.stamdata.fornavn,
          postby: this.state.kontakt1.stamdata.postby,
          postnr: this.state.kontakt1.stamdata.postnr,
          vej: this.state.kontakt1.stamdata.vej,
          telefoner: getTelefoner(this.state.kontakt1)
        },
      }
      kontakter.push(kontakt1);
    }
    if (this.state.kontakt2.stamdata.cpr !== "") {
      const kontakt2 = {
        id: this.state.kontakt2.id,
        kontaktrolle: this.state.kontakt2.kontaktrolle,
        myndighed: this.state.kontakt2.myndighed,
        stamdata: {
          aliasEfternavn:this.state.kontakt2.stamdata.aliasEfternavn,
          aliasFornavn:this.state.kontakt2.stamdata.aliasFornavn,
          beskyttet: this.state.kontakt2.stamdata.beskyttet,
          cpr: this.state.kontakt2.stamdata.cpr,
          efternavn: this.state.kontakt2.stamdata.efternavn,
          email: this.state.kontakt2.stamdata.email,
          erstatningspersonnummer: erstatningspersonnummer,
          fornavn: this.state.kontakt2.stamdata.fornavn,
          postby: this.state.kontakt2.stamdata.postby,
          postnr: this.state.kontakt2.stamdata.postnr,
          vej: this.state.kontakt2.stamdata.vej,
          telefoner: getTelefoner(this.state.kontakt2)
        },
      }
      kontakter.push(kontakt2);
    }

    const person = {
      lokalId: this.state.lokalId,
      instnr: instnr,
      stamdata: {
        aliasEfternavn: this.state.stamdata.aliasEfternavn,
        aliasFornavn: this.state.stamdata.aliasFornavn,
        beskyttet: this.state.stamdata.beskyttet,
        cpr: this.state.stamdata.cpr,
        efternavn: this.state.stamdata.efternavn,
        email: this.state.stamdata.email,
        erstatningspersonnummer: erstatningspersonnummer,
        fornavn: this.state.stamdata.fornavn,
        postby: this.state.stamdata.postby,
        postnr: this.state.stamdata.postnr,
        vej: this.state.stamdata.vej,
        telefoner: [{
          beskyttet: this.state.stamdata.telefonBeskyttet,
          nummer: this.state.stamdata.telefonnummer,
          type: this.state.stamdata.telefontype,
        }]
      },
      elev: {
        rolle: this.state.rolle,
        klasse: {
          instkode: this.state.instnr,
          gruppekode: this.state.gruppekode
        },
        kontakter: kontakter,
      }
    };
    if (isEmpty(this.state.lokalId)) {
      opretElev(person);
    } else {
      retElev(person);
    }
  }

  componentWillReceiveProps(nextProps) {
    // Opdater, hvis CPR-data ændres.
    if (!isEmpty(nextProps.cprData.elev) && !deepEquals(this.props.cprData.elev, nextProps.cprData.elev))
      this.setState({
        stamdata: Object.assign(
          {...this.state.stamdata},
          nulls2Empty(nextProps.cprData.elev),
        )
      });

    ["kontakt1", "kontakt2"].forEach(key => {

      const nextCprData = nextProps.cprData[key];
      if (!isEmpty(nextCprData) && !deepEquals(this.props.cprData[key], nextCprData)) {
        const kontakt = {...this.state[key]};
        kontakt.myndighed = nextCprData.myndighed !== null ? nextCprData.myndighed : kontakt.myndighed;
        kontakt.kontaktrolle = nextCprData.kontaktrolle !== null ? nextCprData.kontaktrolle : kontakt.kontaktrolle;
        // Stamdata skal merges, ikke overskrives.
        Object.assign(
          kontakt.stamdata,
          nulls2Empty(nextCprData.data)
        );
        this.setState({[key]: kontakt});
      }
    });
  }

  render() {
    const {klasser, status, id, hentetElev} = this.props;
    let {alerts} = this.props;

    if (harValideringsfejl(this.state.validation))
      alerts = getValideringsfejl(this.state.validation);

    if (id && hentetElev && Number(id) !== hentetElev.id) { //Nødt til at køre check med konvertering. Ser ud til at være forskellige typer
      return (
        <p>Indlæser elev...</p>
      );
    }
    if (status === constants.status_opret_elev_succes || status === constants.status_ret_elev_succes)
      return <Navigate to={constants.urlElever}/>;

    return (
      <div>
        <AlertBar alerts={alerts}/>
        <h2>{id ? "Rediger elev" : "Opret ny elev"}</h2>
        <div className="buttongroup">
          <button id="button-gem" className="stil-primary-button button" onClick={this.handleSave}>Gem</button>
          <Link to={constants.urlElever} className="stil-primary-button margleft10">Annullér</Link>
        </div>
        <table>
          <tbody>
          <tr>
            <td>Klasse:</td>
            <td><select name="gruppekode" value={this.state.gruppekode} onChange={this.handleChange}>
              <option value="">Vælg klasse</option>
              {klasser.map(klasse => {
                return <option id={klasse.gruppenavn} key={klasse.gruppekode}
                               value={klasse.gruppekode}>{klasse.gruppenavn}</option>
              })}
            </select></td>
          </tr>
          <OpretStamdata stamdata={this.state.stamdata}
                         handleChange={this.handleStamdataChange}
                         idName="elev"
                         status={status}/>
          </tbody>
        </table>
        <h3>Kontaktperson</h3>
        <OpretKontakt kontaktperson={this.state.kontakt1}
                      handleChange={this.handleKontakt1Change}
                      handleStamdataChange={this.handleKontakt1StamdataChange}
                      handleClear={this.handleKontakt1Clear}
                      sletKontakt={this.sletKontakten}
                      status={status}
                      index="1"/>
        <h3>Kontaktperson</h3>
        <OpretKontakt kontaktperson={this.state.kontakt2}
                      handleChange={this.handleKontakt2Change}
                      handleStamdataChange={this.handleKontakt2StamdataChange}
                      handleClear={this.handleKontakt2Clear}
                      sletKontakt={this.sletKontakten}
                      status={status}
                      index="2"/>
        <div className="buttongroup">
          <button id="gem-elev-2-button" className="stil-primary-button button" onClick={this.handleSave}>Gem</button>
          <Link to={constants.urlElever} className="stil-primary-button margleft10">Annullér</Link>
        </div>
      </div>
    );
  }
}

export default OpretElev
