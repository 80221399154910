import React from 'react';
import {Route, Routes} from 'react-router-dom'
import constants from './util/constants.js';
import ElevOversigtConnector from "./containers/ElevOversigtConnector.js";
import OpretElevConnector from "./containers/OpretElevConnector.js";
import KlasseConnector from "./containers/KlasseConnector.js";
import OprulningConnector from "./containers/OprulningConnector.js";
import LoginConnector from "./containers/LoginConnector.js";
import MedarbejderOversigtConnector from "./containers/MedarbejderOversigtConnector";
import OpretMedarbejderConnector from "./containers/OpretMedarbejderConnector";
import ForsideConnector from "./containers/ForsideConnector";

function Content() {

    function Logout()  {
        return window.location.replace(constants.urlBELogout);
    }

    return (
      <Routes>
        <Route path={constants.urlForside} element={<ForsideConnector/>}/>
        <Route path={constants.urlLogin} element={<LoginConnector/>} />
        <Route path={constants.urlLogout} element={<Logout/>} />
        <Route path={constants.urlKlasser} element={<KlasseConnector/>}/>
        <Route path={constants.urlOprulning} element={<OprulningConnector/>}/>
        <Route path={constants.urlElever} element={<ElevOversigtConnector/>}/>
        <Route path={constants.urlOpretElev} element={<OpretElevConnector/>}/>
        <Route path={constants.urlRedigerElev + '/:id'} name="redigerElev" element={<OpretElevConnector/>}/>
        <Route path={constants.urlMedarbejdere} element={<MedarbejderOversigtConnector/>}/>
        <Route path={constants.urlOpretMedarbejder} element={<OpretMedarbejderConnector/>}/>
        <Route path={constants.urlRedigerMedarbejder + '/:id'} name="redigerMedarbejder" element={<OpretMedarbejderConnector/>}/>
      </Routes>
    )
}

export default Content;