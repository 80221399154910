import React, {Component} from 'react'
import constants from '../util/constants.js';
import LoginConnector from "./LoginConnector";
import logo from '../img/UVM_web_STIL163.png' // relative path to image

class HeaderMain extends Component {

  render() {
    const {loggedIn, bruger} = this.props;
    const overskrift = loggedIn && !bruger.adgange.includes(constants.adgangElevAdm) ? "MedarbejderAdministrationen" : "ElevAdministrationen";
    return (
        <div className="stil-header">
          <header className="stil-header-content">
            <em>{overskrift}</em>
            <img src={logo} alt="Logo for Styrelsen for it og læring" />
          </header>
          <LoginConnector />
        </div>
    );
  }
}

export default HeaderMain;
