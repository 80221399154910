import React, {Component} from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import queryString from 'query-string';
import constants from "./constants";
import {isEmptyStr} from "./util.js";

class AlertBar extends Component {

  /**
   * Hjælpemetode, der returnerer alerts fra query-string til brug med AlertBar
   *
   * @returns {{fejl: *, info: *}}
   */
  static getAlertsFromQuery() {
    const emptyValue = {
      fejl: [],
      info: []
    };

    try {
      // Forhindr kode udefra i at angive beskeder.
      // Referrer kan overstyres i forhold til, hvor meget den afslører
      // (https://www.w3.org/TR/referrer-policy/#referrer-policies), men kan ikke spoofes uden
      // lokal indsats. Dvs. en angriber ikke vil kunne guide ofre ind på siden med os selv som referrer.
      const refOrigin = document.referrer.replace(/^(https:\/\/.+?)\/.*/, '$1');
      if (!(refOrigin === window.location.origin || refOrigin === "https://nemlog-in.mitid.dk" || refOrigin === "https://test-nemlog-in.pp.mitid.dk" || refOrigin === "https://et-stilauth.stil.dk" || refOrigin === "https://stilauth.stil.dk")) {
          return emptyValue;
      }
    } catch (e) {
      // F.eks. ved tom streng (f.eks. ved request fra a-element med rel="noreferrer").
      return emptyValue;
    }

    const params = queryString.parse(window.location.search);
    const error = params['error'];
    const info = params['info'];
    const errorAlerts = isEmptyStr(error) ? [] : [error];
    const infoAlerts = isEmptyStr(info) ? [] : [info];

    return {
      fejl: errorAlerts,
      info: infoAlerts
    }
  }

  render() {

    const {alerts} = this.props;

    if (alerts === null || alerts === undefined)
      return null;

    const {fejl, info} = alerts;

    if (fejl !== null && fejl !== undefined && fejl.length > 0) {
      if (fejl[0] === "401") {
        const open=true;
        return (
            <Modal isOpen={open}>
              <ModalHeader>
                Inaktiv
              </ModalHeader>
              <ModalBody>
                <div>
                  <p>Du har været inaktiv for længe. Log venligst på igen.</p>
                </div>
              </ModalBody>
              <ModalFooter>
                <a href={constants.urlLogin} className="stil-primary-button" title="Log ind i Tilslutning">Log ind</a>
              </ModalFooter>
            </Modal>
        );
      }

      return (
          <div id="alert-fejl" className="alert alert-danger">
            <table className="noborder">
              <tbody>
              {fejl.map((tekst, index) => {
                return (
                    <tr key={index} className="noborder">
                      <td className="noborder">{tekst}</td>
                    </tr>
                );
              })}
              </tbody>
            </table>
          </div>
      );
    }

    if (info !== null && info !== undefined && info.length > 0) {
      return (
          <div id="alert-info" className="alert alert-info">
            <table className="noborder">
              <tbody>
              {info.map((tekst, index) => {
                return (
                    <tr key={index} className="noborder">
                      <td className="noborder">{tekst}</td>
                    </tr>
                );
              })}
              </tbody>
            </table>
          </div>
      );
    }

    return null;
  }
}

export default AlertBar;