import { connect } from 'react-redux'
import OpretElevMain from "./OpretElevMain";
import {hentKlasser} from "../actions/klasse";
import {
  hentElev,
  opretElev,
  retElev,
  sletKontakt,
  aabenOpretElevSide
} from "../actions/elev";

const mapStateToProps = (state) => {
  let institutionsnummer;

  if(state.login.bruger.institution !== null){
    institutionsnummer = state.login.bruger.institution.kode;
  }
  else{
    institutionsnummer = null;
  }

  return {
    instnr: institutionsnummer,
    klasser: state.klasser.klasser,
    klasserStatus: state.klasser.status,
    opretElevValideringsfejl: state.elever.opretElevValideringsfejl,
    status: state.elever.status,
    redigerElev: state.elever.redigerElev,
    cprData: state.elever.cprData,
    alerts: state.elever.alerts,
    opretterElev: state.elever.opretterElev
  }
};

const mapDispatchToProps  = (dispatch) => {
  return {
    hentKlasser: () => dispatch(hentKlasser(true)),
    opretElev: (person) => dispatch(opretElev(person)),
    retElev: (person) => dispatch(retElev(person)),
    hentElev: (id) => dispatch(hentElev(id)),
    sletKontakt: (id) => dispatch(sletKontakt(id)),
    aabenOpretElevSide: () => dispatch(aabenOpretElevSide())
  }
};

const OpretElevConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpretElevMain);

export default OpretElevConnector;