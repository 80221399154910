import React, {Component} from 'react';
import HeaderConnector from './containers/HeaderConnector.js';
import Footer from './util/Footer.js';
import Content from "./Content";

class App extends Component {

  render() {
    return (
      <div className="stil-site clearfix">
        <HeaderConnector />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default App;
