import React, {Component} from 'react'
import constants from "../util/constants"; // Import css
import {Link} from "react-router-dom";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

function isArrayEmpty(arr) {
  return arr === null || arr === undefined || !Array.isArray(arr) || arr.length === 0;
}

function isEmpty(streng) {
  return streng === null || streng === undefined || streng.trim().length === 0;
}

function Telefoner(props) {
  const stamdata = props.stamdata;
  if (isArrayEmpty(stamdata.telefoner))
    return <tr key="0">
      <td>Telefon</td>
      <td/>
    </tr>;

  const telefoner = stamdata.telefoner.map((telefon) => {
    if (telefon.beskyttet === true) {
      return (
          <tr key={telefon.type}>
            <td>Telefon - {telefon.type}</td>
            <td>
              <div className="standard-tooltip">
                Beskyttet
                <span className="standard-tooltiptext">
                {telefon.nummer}
            </span>
              </div>
            </td>
          </tr>
      );
    } else if (stamdata.beskyttet) {
      return (
          <tr key={telefon.type}>
            <td>Telefon - {telefon.type}</td>
            <td>
              <div className="standard-tooltip">
                Navnebeskyttet
                <span className="standard-tooltiptext">
              {telefon.nummer}
            </span>
              </div>
            </td>
          </tr>
      );
    } else {
      return (<tr key={telefon.type}>
        <td>Telefon - {telefon.type}</td>
        <td>{telefon.nummer}</td>
      </tr>);
    }
  });

  return [telefoner];
}

class MedarbejderStamkortModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  //TODO:Husk stamkort slet til bootstrap confirm vindue.
  static formatNavn(stamdata) {
    if (stamdata.beskyttet) {
      return (
          <div className="standard-tooltip">
            {MedarbejderStamkortModal.aliasnavn(stamdata)}
            <span className="standard-tooltiptext">
        {MedarbejderStamkortModal.navn(stamdata)}
        </span>
          </div>);
    } else {
      return MedarbejderStamkortModal.navn(stamdata);
    }
  }

  static formatAdresse(stamdata) {
    if (stamdata.beskyttet)
      return (
          <div className="standard-tooltip">
            Navnebeskyttet
            <span className="standard-tooltiptext">
      {MedarbejderStamkortModal.adresse(stamdata)};
      </span>
          </div>);
    else
      return (MedarbejderStamkortModal.adresse(stamdata));
  }

  static formatMail(stamdata) {
    if (stamdata.beskyttet)
      return (
          <div className="standard-tooltip">
            Navnebeskyttet
            <span className="standard-tooltiptext">{stamdata.email}</span>
          </div>);
    else
      return stamdata.email;
  }

  static adresse(stamdata) {
    const v = stamdata.vej;
    const p = stamdata.postnr;
    const b = stamdata.postby;
    if (isEmpty(v))
      return isEmpty(p) ? isEmpty(b) ? "" : b : isEmpty(b) ? p : p + " " + b;
    return isEmpty(p) ? isEmpty(b) ? v : v + ", " + b : isEmpty(b) ? v + ", " + p : v + ", " + p + " " + b;
  }


  static navn(stamdata) {
    const f = stamdata.fornavn;
    const e = stamdata.efternavn;
    return isEmpty(f) ? isEmpty(e) ? "" : e : isEmpty(e) ? f : f + " " + e;
  }

  static aliasnavn(stamdata) {
    const f = stamdata.aliasFornavn;
    const e = stamdata.aliasEfternavn;
    return isEmpty(f) ? isEmpty(e) ? "" : e : isEmpty(e) ? f : f + " " + e;
  }

  rolleNavn(rolle) {
    switch(rolle) {
      case "LAERER": return "Lærer";
      case "PAEDAGOG": return "Pædagog";
      case "VIKAR": return "Vikar";
      case "LEDER": return "Leder";
      case "LEDELSE": return "Ledelse";
      case "TAP": return "TAP";
      case "KONSULENT": return "Konsulent";
      default: return "";
    }
  }

  render() {
    const {medarbejdere, selectedMedarbejderRows, showMedarbejderStamkortModal, lukMedarbejderStamkortModal, openSletMedarbejderModal} = this.props;

    if (isArrayEmpty(selectedMedarbejderRows) || isArrayEmpty(medarbejdere)) {
      return null;
    }

    let medarbejder = null;
    for (let i = 0; medarbejder === null && i < medarbejdere.length; i++) {
      if (medarbejdere[i].id === selectedMedarbejderRows[0])
        medarbejder = medarbejdere[i];
    }

    if (medarbejder === null) {
      return null;
    }

    return (
        <Modal isOpen={showMedarbejderStamkortModal} toggle={lukMedarbejderStamkortModal} onClosed={lukMedarbejderStamkortModal}>
          <ModalHeader>
            <Button type="button" className="close" data-dismiss="modal" onClick={lukMedarbejderStamkortModal}
                    aria-hidden="true">×</Button>
            <p>
              Medarbejderens stamkort
            </p>
          </ModalHeader>
          <ModalBody>
            <div>
              <div>
                <table>
                  <tbody>
                  <tr>
                    <td>Navn</td>
                    <td>{MedarbejderStamkortModal.formatNavn(medarbejder.stamdata)}</td>
                  </tr>
                  <tr>
                    <td>CPR-nummer</td>
                    <td>{medarbejder.stamdata.cpr}</td>
                  </tr>
                  <tr>
                    <td>Adresse</td>
                    <td>{MedarbejderStamkortModal.formatAdresse(medarbejder.stamdata)}</td>
                  </tr>
                  <Telefoner stamdata={medarbejder.stamdata}/>
                  <tr>
                    <td>Email</td>
                    <td>{MedarbejderStamkortModal.formatMail(medarbejder.stamdata)}</td>
                  </tr>
                  <tr>
                    <td>Rolle</td>
                    <td>{medarbejder.medarbejder.roller.map(rolle => this.rolleNavn(rolle)).join(", ")}</td>
                  </tr>
                  <tr>
                    <td>UniId</td>
                    <td>{medarbejder.uniId ? medarbejder.uniId : ""}</td>
                  </tr>
                  </tbody>
                </table>

                <div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="buttongroup">
              <button className="margleft10 stil-primary-button button" onClick={lukMedarbejderStamkortModal}>
                Tilbage til Medarbejdere
              </button>
              <button className="margleft10 stil-primary-button button" onClick={() => openSletMedarbejderModal()}>Slet medarbejder
              </button>
              <Link className="margleft10 stil-primary-button button" to={constants.urlRedigerMedarbejder + '/' + medarbejder.id}>
                Rediger medarbejder
              </Link>
            </div>
          </ModalFooter>
        </Modal>
    )
  }
}

export default MedarbejderStamkortModal
