import 'whatwg-fetch';
import fetchJson from '../util/fetch';
import constants from "../util/constants";

export function loginClear() {
  return {
    type: constants.LOGIN_CLEAR
  }
}

export function hentBrugeroplysninger() {
  return dispatch => {
    // institution[0] er regnr
    fetchJson(constants.apiBrugeroplysninger, "GET", null, dispatch)
      .then(bruger => {
        dispatch(hentBrugeroplysningerSuccess(bruger));
      })
      .catch(errorResponse => {
        if (errorResponse.status === 401) {
          dispatch(hentBrugeroplysningerUnauthorized());
        } else {
          errorResponse.json().then(data => dispatch(hentBrugeroplysningerFejl(data)))
          dispatch(hentBrugeroplysningerFejl(errorResponse))
        }
      });
  };
}

function hentBrugeroplysningerSuccess(bruger) {
  return {
    type: constants.HENT_BRUGEROPLYSNINGER_SUCCESS, bruger: bruger
  }
}

function hentBrugeroplysningerUnauthorized() {
  return {
    type: constants.HENT_BRUGEROPLYSNINGER_FEJL_UNAUTHORIZED
  }
}

function hentBrugeroplysningerFejl(errors) {
  return {
    type: constants.HENT_BRUGEROPLYSNINGER_FEJL_ANDET,
    errors: errors
  }
}

export function selectInstitution(event) {
  const institution = {
    kode: event.target.value
  };
  return dispatch => {
    fetchJson(constants.apiSkiftInstitution, "POST", institution, dispatch)
      .then(bruger => {
        dispatch(selectInstitutionSuccess(bruger));
      })
      .catch(error => {
        error.json().then(data =>{
          dispatch(selectInstitutionFejl(`Kunne ikke vælge aktiv institution: ${data.error}. Prøv igen senere.`))});
      });
  };
}

export function openSelectInstitutionModal() {
  return {
    type: constants.OPEN_INSTITUTION_MODAL
  }
}

function selectInstitutionSuccess(bruger) {
  return {
    type: constants.SELECT_INSTITUTION, bruger: bruger
  }
}

function selectInstitutionFejl(errorMessage) {
  return {
    type: constants.SET_SELECT_INSTITUTION_FEJL, errorMessage: errorMessage
  }
}

export function hentInstitutioner() {
  return dispatch => {
    fetchJson(constants.apiHentInstitutioner, "GET", null, dispatch)
        .then((institutioner) => {
          dispatch(hentInstitutionerSuccess(institutioner));
        }).catch((errorResponse) => {
      dispatch(hentInstitutionerFejl([errorResponse.status + `: Kunne ikke hente institutioner.`]));
    });
  };
}

function hentInstitutionerSuccess(institutioner) {
  return {
    type: constants.HENT_INSTITUTIONER_SUCCESS,
    institutioner: institutioner
  }
}

function hentInstitutionerFejl(error) {
  return {
    type: constants.HENT_INSTITUTIONER_FEJL,
    error: error
  }
}
