module.exports = {

  /**
   * Dyb sammenligning; afhænger af rækkefølge!
   */
  deepEquals: function(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  },

  /**
   * Returnerer nyt objekt med null erstattet med tom streng, rekursiv
   */
  nulls2Empty: function recur(obj) {
    if (obj === null)
      return "";
    if (obj instanceof Object && !(obj instanceof Array))
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = recur(obj[key]);
        return newObj;
      }, {});
    return obj;
  },

  isEmpty: function(obj) {
    return obj === null || obj === undefined;
  },

  isEmptyArray: function(obj) {
    return obj === null || obj === undefined || obj.length === 0;
  },

  isEmptyStr: function(obj) {
    return obj === null || obj === undefined || obj.trim() === "";
  },

  isEmptyOrZero: function(obj) {
    return obj === null || obj === undefined || obj === 0;
  },

  getStringAorB: function(str1, str2) {
    if (str1 === null || str1 === undefined || str1.trim() === "")
      return str2 === null || str2 === undefined ? "" : str2;
    return str1;
  }
};
