import React from 'react';
import {Link} from "react-router-dom";
import {urlRedigerElev} from "../../util/constants.js";
import {isEmptyArray} from "../../util/util.js";

function Elev(props){

 function fornavn(){
    const {stamdata} = props.elev;
    if(stamdata.beskyttet)
        return stamdata.aliasFornavn;
    else
        return stamdata.fornavn;
  }

  function efternavn(){
    const {stamdata} = props.elev;
    if(stamdata.beskyttet)
        return stamdata.aliasEfternavn;
    else
        return stamdata.efternavn;
  }

  function kontakt(index){
    const{kontakter} = props.elev.elev;
    if(kontakter.length >= index){
      let kontakt = kontakter[index-1];
      if(kontakt.stamdata.beskyttet)
        return kontakt.stamdata.aliasFornavn + " " + kontakt.stamdata.aliasEfternavn;
      else
        return kontakt.stamdata.fornavn + " " + kontakt.stamdata.efternavn;
    }
  }

  function handleClick(){
    const{elevRowClick, elev} = props;
     elevRowClick(elev.id);
  }

  function sletEleven(openSletElevModal, elev, setElevAsSelected, resetSelectedElevRows){
    resetSelectedElevRows();
    setElevAsSelected(elev.id);
    openSletElevModal();
  }

  function openModal(){
    const{elev, visElevStamkortModal} = props;
    visElevStamkortModal(elev.id);
  }

  function importStatus(senesteEksportTid){
    const{elev} = props;
    if(senesteEksportTid && senesteEksportTid > elev.senestAendret){
      return "opdateringsstatusGreen";
    }else{
      return "opdateringsstatusRed";
    }
  }

    const {elev, selectedElevRows, setElevAsSelected, resetSelectedElevRows, senesteEksport, openSletElevModal} = props;
    const checked=!isEmptyArray(selectedElevRows) && selectedElevRows.indexOf(elev.id) >= 0;

    return(
      <tr>
        <td className="elev-fornavn">{fornavn()}</td>
        <td className="elev-efternavn">{efternavn()}</td>
        <td className="elev-klasse">{elev.elev.klasse.gruppenavn}</td>
        <td>{kontakt(1)}</td>
        <td>{kontakt(2)}</td>
        <td className="centerText"><span className={"opdateringsStatus " + (senesteEksport !== null ? importStatus(senesteEksport.slut) : "")}></span></td>
        <td>&nbsp;
          <Link className="elev-pen" to={urlRedigerElev+'/' + elev.id}>
            <img src="img/ic_mode_edit_18px.svg" title="Rediger elev"  alt="rediger"/>
          </Link>&nbsp;
          <img className="elev-eye" title="Vis elev" src="img/ic_visibility_24px.svg" onClick={() => openModal()} style={{cursor: 'pointer'}} alt="info"/>&nbsp;
          <img className="elev-trash" title="Slet elev" src="img/ic_delete_24px.svg" onClick={() => sletEleven(openSletElevModal, elev, setElevAsSelected, resetSelectedElevRows)} style={{cursor: 'pointer'}} alt="slet"/>&nbsp;
          <input className="elev-select" type="checkbox" onChange={() => handleClick()} checked={checked} />
        </td>
      </tr>
    );
}

export default Elev;
