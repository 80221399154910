import React, { Component } from 'react'
import ElevOplysninger from '../pages/elever/ElevOplysninger.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ElevOversigtVisModal extends Component{

    constructor(props) {
        super(props);
        this.state = {visible: true};
        this.state = {
          modal: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    render() {
        const {elever, selectedElevRows, showElevModal, openSletElevModal, closeElevOversigtVisModal} = this.props;

        return (
            <Modal isOpen={showElevModal} toggle={closeElevOversigtVisModal} onClosed={closeElevOversigtVisModal}>
                <ModalHeader>
                    <button type="button" className="close" data-dismiss="modal" onClick={closeElevOversigtVisModal}
                            aria-hidden="true">×
                    </button>
                    Valgte elever
                </ModalHeader>
                <ModalBody>
                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <th>Fornavn</th>
                                <th>Efternavn</th>
                                <th>Klasse</th>
                                <th>Mobiltelefon</th>
                                <th>Email</th>
                                <th>Adresse</th>
                                <th>UniId</th>
                            </tr>
                            {elever
                                .filter(elev => selectedElevRows.includes(elev.id))
                                .map(elev => {
                                    return (
                                        <ElevOplysninger key={elev.id} elev={elev} />
                                    );
                            })}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="container mybuttons">
                        <button className='button stil-primary-button' onClick={() => openSletElevModal()}>Slet
                            elever
                        </button>
                        <button className='button margleft10 stil-primary-button' onClick={closeElevOversigtVisModal}>Luk
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}


export default ElevOversigtVisModal
