import React, {Component} from 'react'
import {deepEquals, isEmpty, isEmptyArray, nulls2Empty} from "../../util/util.js";
import OpretStamdata from "../elever/OpretStamdata";
import {Link, Navigate} from "react-router-dom";
import constants from "../../util/constants";
import AlertBar from "../../util/AlertBar";

// Erstatningspersonnummer sættes altid til false, da den ikke benyttes mere
const erstatningspersonnummer = false;

const stamdataTemplate = {
  cpr: '',
  erstatningspersonnummer: erstatningspersonnummer,
  beskyttet: false,
  fornavn: '',
  efternavn: '',
  aliasFornavn: '',
  aliasEfternavn: '',
  vej: '',
  postnr: '',
  postby: '',
  email: '',
  telefontype: "Mobil",
  telefonnummer: '',
  telefonBeskyttet: false
};

function harValideringsfejl(val) {
  if (isEmpty(val))
    return false;
  return !isEmptyArray(val.medarbejder);
}

function getValideringsfejl(val) {
  let fejl = [];
  for (let i in val.medarbejder) {
    if (val.medarbejder.hasOwnProperty(i))
      fejl.push("Medarbejder. " + val.medarbejder[i]);
  }

  return {fejl: fejl, info: []};
}

class OpretMedarbejder extends Component {
  constructor(props) {
    super(props);
    const {instnr} = this.props;
    this.state = {
      lokalId: null,
      instnr: instnr,
      rolle: null,
      stamdata: {...stamdataTemplate},
      validation: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStamdataChange = this.handleStamdataChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
    this.validateMedarbejder = this.validateMedarbejder.bind(this);
    this.selectRolle = this.selectRolle.bind(this);
  }

  componentDidUpdate() {
    const {id, hentetMedarbejder} = this.props;

    if (id && hentetMedarbejder && Number(id) === hentetMedarbejder.id && hentetMedarbejder.lokalId !== this.state.lokalId) {
      this.setState({
        lokalId: hentetMedarbejder.lokalId,
        stamdata: {
          ...hentetMedarbejder.stamdata,
          //Sætter nullable felter eksplicit, da react ikke bryder sig om skift fra managed -> unmanaged form element.
          aliasFornavn: hentetMedarbejder.stamdata.aliasFornavn !== null ? hentetMedarbejder.stamdata.aliasFornavn : '',
          aliasEfternavn: hentetMedarbejder.stamdata.aliasEfternavn !== null ? hentetMedarbejder.stamdata.aliasEfternavn : '',
          email: hentetMedarbejder.stamdata.email !== null ? hentetMedarbejder.stamdata.email : '',
          telefonnummer: hentetMedarbejder.stamdata.telefoner.length > 0 ? hentetMedarbejder.stamdata.telefoner[0].nummer : '',
          telefonBeskyttet: hentetMedarbejder.stamdata.telefoner.length > 0 ? hentetMedarbejder.stamdata.telefoner[0].beskyttet : false,
          telefontype: "Mobil",
        },
        rolle: hentetMedarbejder.medarbejder.roller[0]
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleStamdataChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      stamdata: {...this.state.stamdata, [name]: value}
    });
  }

  validate() {
    const validation = {};
    //Omvejen med at assigne først, er for at kunne returnere en boolean til slut, ved at tjekke for tomt object.
    const medarbejder = this.validateMedarbejder();
    if (medarbejder.length !== 0)
      validation.medarbejder = medarbejder;

    this.setState({validation: validation});
    return Object.keys(validation).length === 0;
  }

  validateMedarbejder() {
    const validation = [];
    OpretMedarbejder.validateStamdata(this.state.stamdata, validation);
    return validation;
  }

  static validateStamdata(stamdata, validation) {
    OpretMedarbejder.validateCpr(stamdata, validation);
    if (stamdata.beskyttet && (stamdata.aliasFornavn === '' || stamdata.aliasEfternavn === '' || stamdata.aliasFornavn.trim().length === 0 || stamdata.aliasEfternavn.trim().length === 0))
      validation.push('Alias fornavn og -efternavn skal angives, når personen er navne- og adressebeskyttet');
    if (stamdata.vej === '' || stamdata.vej.trim().length === 0)
      validation.push('Adresse skal angives.');
    if (stamdata.postnr === '' || stamdata.postnr.trim().length === 0)
      validation.push('Postnr skal angives');
    if (stamdata.postby === '' || stamdata.postby.trim().length === 0)
      validation.push("By skal angives");
    if (stamdata.telefonBeskyttet && (stamdata.telefonnummer === '' || stamdata.telefonnummer.trim().length === 0))
      validation.push('Telefonnummer skal være angivet, hvis beskyttet telefon er valgt.');
    if (stamdata.fornavn === '' || stamdata.fornavn.trim().length === 0)
      validation.push('Fornavn skal angives.');
    if (stamdata.efternavn === '' || stamdata.efternavn.trim().length === 0)
      validation.push('Efternavn skal angives.');
  }

  static validateCpr(stamdata, validation) {
    if (stamdata.cpr === '' || stamdata.cpr.trim().length === 0)
      validation.push('CPR skal angives.');
  }

  handleSave() {
    const {opretMedarbejder, retMedarbejder, opretterMedarbejder, instnr} = this.props;
    if (opretterMedarbejder)
      return;

    if (!this.validate())
      return;

    const person = {
      lokalId: this.state.lokalId,
      instnr: instnr,
      stamdata: {
        aliasEfternavn: this.state.stamdata.aliasEfternavn,
        aliasFornavn: this.state.stamdata.aliasFornavn,
        beskyttet: this.state.stamdata.beskyttet,
        cpr: this.state.stamdata.cpr,
        efternavn: this.state.stamdata.efternavn,
        email: this.state.stamdata.email,
        erstatningspersonnummer: erstatningspersonnummer,
        fornavn: this.state.stamdata.fornavn,
        postby: this.state.stamdata.postby,
        postnr: this.state.stamdata.postnr,
        vej: this.state.stamdata.vej,
        telefoner: [{
          beskyttet: this.state.stamdata.telefonBeskyttet,
          nummer: this.state.stamdata.telefonnummer,
          type: this.state.stamdata.telefontype,
        }]
      },
      medarbejder: {
        roller: this.state.rolle ? [this.state.rolle] : []
      }
    };
    if (isEmpty(this.state.lokalId)) {
      opretMedarbejder(person);
    } else {
      retMedarbejder(person);
    }
  }

  selectRolle(event) {
    let rolle = event.target.value;
    this.setState({
      rolle: rolle
    })
  }

  componentWillReceiveProps(nextProps) {
    // Opdater, hvis CPR-data ændres.
    if (!isEmpty(nextProps.cprData.medarbejder) && !deepEquals(this.props.cprData.medarbejder, nextProps.cprData.medarbejder))
      this.setState({
        stamdata: Object.assign(
            {...this.state.stamdata},
            nulls2Empty(nextProps.cprData.medarbejder),
        )
      });
  }

  render() {
    const {status, id, hentetMedarbejder} = this.props;
    let {alerts} = this.props;

    if (harValideringsfejl(this.state.validation))
      alerts = getValideringsfejl(this.state.validation);

    if (id && hentetMedarbejder && Number(id) !== hentetMedarbejder.id) {
      return (
          <p>Indlæser medarbejder...</p>
      );
    }
    if (status === constants.status_opret_medarbejder_success || status === constants.status_ret_medarbejder_success)
      return <Navigate to={constants.urlMedarbejdere}/>;

    const roller = [
      {navn: "Lærer", key: "LAERER"},
      {navn: "Pædagog", key: "PAEDAGOG"},
      {navn: "Vikar", key: "VIKAR"},
      {navn: "Leder", key: "LEDER"},
      {navn: "Ledelse", key: "LEDELSE"},
      {navn: "TAP", key: "TAP"},
      {navn: "Konsulent", key: "KONSULENT"}
    ];

    return (
        <div>
          <AlertBar alerts={alerts}/>
          <h2>{id ? "Rediger Medarbejder" : "Opret ny medarbejder"}</h2>
          <div className="buttongroup">
            <button id="button-gem" className="stil-primary-button button" onClick={this.handleSave}>Gem</button>
            <Link to={constants.urlMedarbejdere} className="stil-primary-button margleft10">Annullér</Link>
          </div>
          <table>
            <tbody>
            <tr>
              <td>Rolle:</td>
              <td>
                <select name="rolle" value={this.state.rolle ? this.state.rolle : ""} onChange={this.selectRolle}>
                  <option value="">Vælg rolle</option>
                  {roller.map(rolle => {
                    return <option id={rolle.key} key={rolle.key} value={rolle.key}>{rolle.navn}</option>
                  })}
                </select>
              </td>
            </tr>
            <OpretStamdata stamdata={this.state.stamdata}
                           handleChange={this.handleStamdataChange}
                           idName="medarbejder"/>
            </tbody>
          </table>
          <div className="buttongroup">
            <button id="gem-elev-2-button" className="stil-primary-button button" onClick={this.handleSave}>Gem
            </button>
            <Link to={constants.urlMedarbejdere} className="stil-primary-button margleft10">Annullér</Link>
          </div>
        </div>
    );
  }
}

export default OpretMedarbejder
