
import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class KlasseSletModal extends Component{

    constructor(props) {
        super(props);
        this.state = {visible: true};
        this.state = {
          modal: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    submit(klasseSletModalClose,sletteList,sletKlasser){
      klasseSletModalClose();
      sletKlasser(sletteList);
    }


    render(){
        const {klasser, selectedKlasseRows, sletKlasser, showKlasseModal, klasseSletModalClose} = this.props;
        let sletteList = [];
        let warningList = [];
        let klasseRows = [];
        for (let i = 0; i < selectedKlasseRows.length; i++) {
          let val = selectedKlasseRows[i];
          let klasse = klasser.find(function (item) {
            return item.id === val
          });
          if (klasse !== -1) {
            klasseRows.push(<tr key={klasse.id}>
              <td>{klasse.niveau}</td>
              <td>{klasse.gruppebetegnelse}</td>
              <td>{klasse.antalElever}</td>
            </tr>);
            if (klasse.antalElever === 0) {
              sletteList.push(klasse.id)
            }
            else {
              warningList.push(klasse.id)
            }

          }
        }
        if(selectedKlasseRows.length === 1){
      //enkelt klasse valgt
          if(sletteList.length === 1){
            //klasse tom
            return(
              <Modal isOpen={showKlasseModal} toggle={klasseSletModalClose}>
                <ModalHeader>
                 <button type="button" className="close" data-dismiss="modal" onClick={klasseSletModalClose} aria-hidden="true">×</button>
                  Bekræft sletning
                </ModalHeader>
                <ModalBody>
                <div>
                  Er du sikker på, at du vil slette Klassen?
                  <table>
                    <tbody>
                      <tr>
                        <th>Klassetrin</th>
                        <th>Klasse</th>
                        <th>Antal elever</th>
                      </tr>
                      {klasseRows}
                    </tbody>
                  </table>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container mybuttons">
                  <button id="button-modal-annuller-slet-klasse" className='button stil-primary-button' onClick={klasseSletModalClose}>Annuller</button>
                  <button id="button-modal-slet-klasse" className='button margleft10 stil-primary-button' onClick={() => this.submit(klasseSletModalClose,sletteList,sletKlasser)}>Slet klasse</button>
                </div>
              </ModalFooter>
            </Modal>
            );
          }
          else {
            //enkelt slet valgt og ikke tom
            return(
              <Modal isOpen={showKlasseModal} toggle={klasseSletModalClose}>
                <ModalHeader>
                <div>
                  <button type="button" className="close" data-dismiss="modal" onClick={klasseSletModalClose} aria-hidden="true">×</button>
                  Ingen sletning
                </div>
                </ModalHeader>
                <ModalBody>
                <div>
                  Den markerede klasse har elever og vil derfor ikke blive slettet.
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container mybuttons">
                  <button id="button-modal-OK-ej-slet-klasse" className='button margleft10 stil-primary-button' onClick={klasseSletModalClose}>OK</button>
                </div>
              </ModalFooter>
            </Modal>
            );
        }
      }
        else{
          if (Object.keys(warningList).length > 0) {
            if (Object.keys(sletteList).length === 0) {
              //markerede og ingen tomme
              //->ingenSletningAlert
              return(
                <Modal isOpen={showKlasseModal} toggle={klasseSletModalClose}>
                  <ModalHeader>
                  <div>
                    <button type="button" className="close" data-dismiss="modal" onClick={klasseSletModalClose} aria-hidden="true">×</button>
                    Ingen sletning
                  </div>
                  </ModalHeader>
                  <ModalBody>
                  <div>
                    Alle markerede klasser har elever og vil derfor ikke blive slettet.
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="container mybuttons">
                    <button id="button-modal-OK-ej-slet-klasse" className='button margleft10 stil-primary-button' onClick={klasseSletModalClose}>OK</button>
                  </div>
                </ModalFooter>
              </Modal>
              );
            } else {
              //markerede og nogen tomme
              //->sletterIkkeTommeWarning, herefter sletPopup eller annuller
              return(
              <Modal isOpen={showKlasseModal} toggle={klasseSletModalClose}>
                <ModalHeader>
                 <button type="button" className="close" data-dismiss="modal" onClick={klasseSletModalClose} aria-hidden="true">×</button>
                  Bekræft sletning
                </ModalHeader>
                <ModalBody>
                <div>
                  Der er markeret mindst een klasse som stadig indeholder elever og vil derfor ikke blive slettet. Er du sikker på at du vil slette de resterende klasser?
                  <table>
                    <tbody>
                      <tr>
                        <th>Klassetrin</th>
                        <th>Klasse</th>
                        <th>Antal elever</th>
                      </tr>
                      {klasseRows}
                    </tbody>
                  </table>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container mybuttons">
                  <button id="button-modal-annuller-slet-klasse" className='button stil-primary-button' onClick={klasseSletModalClose}>Annuller</button>
                  <button id="button-modal-slet-klasse" className='button margleft10 stil-primary-button' onClick={() => this.submit(klasseSletModalClose,sletteList,sletKlasser)}>Slet klasser</button>
                </div>
              </ModalFooter>
            </Modal>
            );}
          } else {
            if (Object.keys(sletteList).length > 0) {
            //markerede og alle tomme
            //->sletPopup
            return(
              <Modal isOpen={showKlasseModal} toggle={klasseSletModalClose}>
                <ModalHeader>
                 <button type="button" className="close" data-dismiss="modal" onClick={klasseSletModalClose} aria-hidden="true">×</button>
                  Bekræft sletning
                </ModalHeader>
                <ModalBody>
                <div>
                  Er du sikker på at du vil slette de markerede klasser?ssss
                  <table>
                    <tbody>
                    <tr>
                      <th>Klassetrin</th>
                      <th>Klasse</th>
                      <th>Antal elever</th>
                    </tr>
                    {klasseRows}
                    </tbody>
                  </table>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container mybuttons">
                  <button id="button-modal-annuller-slet-klasse" className='button stil-primary-button' onClick={klasseSletModalClose}>Annuller</button>
                  <button id="button-modal-slet-klasse" className='button margleft10 stil-primary-button' onClick={() => this.submit(klasseSletModalClose,sletteList,sletKlasser)}>Slet klasser</button>
                </div>
              </ModalFooter>
            </Modal>
          );}
        }
      }
      return null;
    }
}

export default KlasseSletModal
