import React, {Component} from 'react'
import OprulningKlasser from "../pages/oprulning/OprulningKlasser.js";
import OprulKlasserModal from "../pages/oprulning/OprulKlasserModal.js";
import {Navigate} from "react-router-dom";
import constants from "../util/constants";
import AlertBar from "../util/AlertBar";

class OprulningMain extends Component{

  constructor(props) {
      super(props);
  }

  componentDidMount(){
    const { hentOprulKlasser } = this.props;
    hentOprulKlasser();
  }

  render(){
    const { oprulKlasser, redirect2Klasser, alerts } = this.props;

    if (redirect2Klasser === true)
      return <Navigate to={constants.urlKlasser} />;

    return (
      <div id="OprulningMainDiv" className="stil-main-content">
        <AlertBar alerts={alerts}/>
        <h2>Oprul klasser</h2>
        <OprulningKlasser oprulKlasser={oprulKlasser} oprulKlasserne={this.props.oprulKlasserne} />
        <OprulKlasserModal
          oprulKlasser={oprulKlasser}
          visOprulModal={this.props.visOprulModal}
          skjulOprulKlasser={this.props.skjulOprulKlasser}
        />
      </div>
    )
  }

}

export default OprulningMain
