import React, {Component} from 'react'
import constants from "../../util/constants";

class OpretStamdata extends Component {

  constructor(props) {
    super(props);
  }

  render(){
    const{stamdata, handleChange, idName} = this.props;

    return (
      <React.Fragment>
        <tr key="cpr">
          <td>CPR-nummer:</td>
          <td>
            <input type="text" id={idName + "-cpr"} name="cpr" value={stamdata.cpr} onChange={handleChange} maxLength='10'/>
          </td>
        </tr>
        <tr key="navn">
          <td>Navn:</td>
          <td>
            <input type="text" id={idName + "-fornavn"} name="fornavn" value={stamdata.fornavn} onChange={handleChange} maxLength='100'/>
            <input type="text" id={idName + "-efternavn"} name="efternavn" value={stamdata.efternavn} onChange={handleChange} maxLength='100'/>
            Navnebeskyttelse: <input type="checkbox" id={idName + "-beskyttet"} checked={stamdata.beskyttet} name="beskyttet" onChange={handleChange}/>
          </td>
        </tr>
        <tr key="aliasNavn" className={stamdata.beskyttet ? null : "hidden"}>
          <td>Alias:</td>
          <td>
            <input type="text" id={idName + "-aliasfornavn"} name="aliasFornavn" value={stamdata.aliasFornavn} onChange={handleChange} maxLength='100'/>
            <input type="text" id={idName + "-aliasefternavn"} name="aliasEfternavn" value={stamdata.aliasEfternavn} onChange={handleChange} maxLength='100'/>
            &nbsp;&nbsp;Angiv alias. Alias vil vises i stedet for fornavn og efternavn i Elevadministrationen.
          </td>
        </tr>
        <tr key="adresse">
          <td>Adresse:</td>
          <td>
            Vej: <input type="text" id={idName + "-vej"} name="vej" value={stamdata.vej} onChange={handleChange} maxLength='60'/>
            Postnr: <input type="text" id={idName + "-postnr"} name="postnr" value={stamdata.postnr} onChange={handleChange} maxLength='10'/>
            By: <input type="text" id={idName + "-postby"} name="postby" value={stamdata.postby} onChange={handleChange} maxLength='100'/>
          </td>
        </tr>
        <tr key="telefon">
          <td>Mobiltelefon:</td>
          <td>
            <input type="text" id={idName + "-telefonnummer"} name="telefonnummer" value={stamdata.telefonnummer} onChange={handleChange}/>
            Beskyttet: <input id={idName + "-telefonbeskyttet"} name="telefonBeskyttet" type="checkbox" checked={stamdata.telefonBeskyttet} onChange={handleChange}/>
          </td>
        </tr>
        <tr key="email">
          <td>Email:</td>
          <td>
            <input id={idName + "-email"} type="text" name="email" value={stamdata.email} onChange={handleChange} />
          </td>
        </tr>
      </React.Fragment>);
  }

}

export default OpretStamdata;