
import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ElevSletModal(props) {

    function sletEleverKnap(closeSletElevModal, selectedElevRows, sletElever){
      sletElever(selectedElevRows);
      closeSletElevModal();
    }

    const {selectedElevRows, sletElever, showSletElevModal, closeSletElevModal} = props;
    if(selectedElevRows.length <= 1){
        //enkelt elev
        return(
            <Modal isOpen={showSletElevModal} toggle={closeSletElevModal}>
                <ModalHeader>
                    <button type="button" className="close" data-dismiss="modal" onClick={closeSletElevModal} aria-hidden="true">×</button>
                    Bekræft sletning
                </ModalHeader>
                <ModalBody>
                    <div>
                    Er du sikker på, at du vil slette eleven?
                    </div>
                </ModalBody>
                <ModalFooter>
                  <div className="container mybuttons">
                    <button id="button-modal-annuller-slet-elev" className='button stil-primary-button' onClick={closeSletElevModal}>Annuller</button>
                    <button id="button-modal-slet-elev" className='button margleft10 stil-primary-button' onClick={() => sletEleverKnap(closeSletElevModal, selectedElevRows, sletElever)}>Slet elev</button>
                  </div>
                </ModalFooter>
            </Modal>
        );
    }
    else{
        //flere elever
        return(
            <Modal isOpen={showSletElevModal} toggle={closeSletElevModal}>
                <ModalHeader>
                    <button type="button" className="close" data-dismiss="modal" onClick={closeSletElevModal} aria-hidden="true">×</button>
                      Bekræft sletning
                    </ModalHeader>
                <ModalBody>
                    <div>
                      Er du sikker på, at du vil slette eleverne?
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="container mybuttons">
                      <button id="button-modal-annuller-slet-elev" className='button stil-primary-button' onClick={closeSletElevModal}>Annuller</button>
                      <button id="button-modal-slet-elev" className='button margleft10 stil-primary-button' onClick={() => sletEleverKnap(closeSletElevModal, selectedElevRows, sletElever)}>Slet elever</button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ElevSletModal
