import constants from "../util/constants.js";

export function appReducer(state = [], action) {

  // c/onsole.log('AppReducer State is now', state);
  // c/onsole.log('AppReducer Action is now', action);
  // c/onsole.log('AppReducer Previous type', state.previousType);

  switch (action.type) {

    case constants.HENT_ELEVER:
      return {
        ...state,
        active: "Elever"
      };

    case constants.HENT_KLASSER:
      return {
        ...state,
        active: action.forRediger ? "Elever" : "Klasser"
      };

    case constants.HENT_MEDARBEJDERE:
      return {
        ...state,
        active: "Medarbejdere"
      };

    case constants.OPRET_MEDARBEJDER_SIDE:
      return {
        ...state,
        active: "Medarbejdere"
      };

    case constants.OPRET_ELEV_SIDE:
      return {
        ...state,
        active: "Elever"
      };

    case constants.HENT_ANNOUNCEMENT:
      return {...state, status: constants.status_hent_announcement_start, henterAnnouncement: action.isLoading};
    case constants.HENT_ANNOUNCEMENT_SUCCES:
      return {...state, status: constants.status_hent_announcement_succes, announcement: action.announcement};
    case constants.HENT_ANNOUNCEMENT_FEJL:
      return {...state, status: constants.status_hent_announcement_fejl};

    default:
      return state;
  }
}

