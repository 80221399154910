import React, {Component} from 'react'
import {Navigate} from "react-router-dom";
import queryString from "query-string";
import constants from "../util/constants";
import OrgansiationModal from "../modals/OrganisationModal";

class ForsideMain extends Component {
  componentDidMount() {
    this.props.hentAnnouncement();
  }

  render() {
    const {login, loginClear, openSelectInstitutionModal, announcement} = this.props;
    const queryParams = queryString.parse(window.location.search);

    // Logout skal rydde login-information fra eventuel gemt state.
    if (queryParams[constants.postLogoutParam] !== undefined) {
      loginClear();
      return <Navigate to="/"/>;
    }

    if (queryParams[constants.selectInstitutionParam] !== undefined) {
      openSelectInstitutionModal();
      // Consider using a constant like this from commit 864589d : " return <Redirect to={constants.urlLogin}/>; "
      return <Navigate to="oidc/login"/>;
    }

    return (
      <div className="stil-main-content">
        {
          announcement && announcement.url && announcement.titel ?
            <div id="alert-info" className="alert alert-info">
              <table className="noborder">
                <tbody>
                <tr key={announcement.titel} className="noborder">
                  <td className="noborder">
                    <b>Vigtig info til brugerne af ElevAdministration</b>
                    <p>{announcement.titel} - <a href={announcement.url}>læs venligst her</a></p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            : null
        }

        {
          login.loggedIn ? null : (
            <div>
              <h2>Velkommen til Elevadministrationen</h2>
              <p>Elevadministrationen er et værktøj for institutioner som har et - af BUVM anerkendt - behov for at
                administrere elever i forbindelse med BUVMs obligatoriske løsninger, men som ikke har mulighed for at
                anskaffe et elevadministrativt system selv.</p>
              <p>For at logge ind på Elevadministrationen skal du bruge MitID Erhverv udstedt af den
                institution du vil administrere.</p>
            </div>
          )
        }
      </div>
    )
  }
}

export default ForsideMain;
