import { connect } from 'react-redux'
import {
  hentElever,
  elevModalCloseClick,
  setElevAsSelected,
  visElevModal,
  sletElever,
  visElevStamkortModal,
  elevStamkortModalCloseClick,
  elevRowClick,
  resetSelectedElevRows,
  closeElevOversigtVisModal
} from "../actions/elev.js";
import ElevOversigtMain from "./ElevOversigtMain";

const mapStateToProps = (state) => {
  const elever = state.elever;
  let institutionsnummer;
  let jobKoersel;

  if(state.login.bruger.institution !== null){
    institutionsnummer = state.login.bruger.institution.kode;
    jobKoersel = state.login.bruger.institution.jobKoersel
  }
  else{
    institutionsnummer = null;
    jobKoersel = null;
  }

  return {
    instnr: institutionsnummer,
    senesteEksport: jobKoersel,
    elever: elever.elever,
    status: elever.status,
    henterElever: elever.henterElever,
    hentEleverFejl: elever.hentEleverFejl,
    showElevModal: elever.showElevModal,
    showElevSletModal: elever.showElevSletModal,
    selectedElevRows: elever.selectedElevRows,
    showElevStamkortModal: elever.showElevStamkortModal,
    alerts: elever.alerts
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    hentElever: () => dispatch(hentElever()),
    elevRowClick: (rowId) => dispatch(elevRowClick(rowId)),
    setElevAsSelected: (rowId) => dispatch(setElevAsSelected(rowId)),
    visElevModal: () => dispatch(visElevModal()),
    onElevModalClose: () => dispatch(elevModalCloseClick()),
    sletElever: (elever) => dispatch(sletElever(elever)),
    visElevStamkortModal: (id) => dispatch(visElevStamkortModal(id)),
    lukElevStamkortModal: () => dispatch(elevStamkortModalCloseClick()),
    closeElevOversigtVisModal: () => dispatch(closeElevOversigtVisModal()),
    resetSelectedElevRows: () => dispatch(resetSelectedElevRows()),
  }
};

const ElevOversigtConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ElevOversigtMain);

export default ElevOversigtConnector;
