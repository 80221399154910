import constants from '../util/constants.js';

function doRensAlerts(prevType) {
  return (
      prevType === constants.HENT_MEDARBEJDER_FEJL ||
      prevType === constants.HENT_MEDARBEJDERE_FEJL||
      prevType === constants.HENT_MEDARBEJDERE_SUCCESS||
      prevType === constants.OPRET_MEDARBEJDER_FEJL ||
      prevType === constants.RET_MEDARBEJDER_FEJL ||
      prevType === constants.SLET_MEDARBEJDERE_FEJL ||
      prevType === constants.SLET_MEDARBEJDERE_SUCCES ||
      prevType === constants.MEDARBEJDER_MODAL_SHOW_CLICK ||
      prevType === constants.RESET_SELECTED_MEDARBEJDER_ROWS
  );
}
export function medarbejderReducer(state = [], action) {

  let rensAlerts = doRensAlerts(state.previousType);

  switch (action.type) {
    case constants.HENT_MEDARBEJDERE:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_medarbejdere_start,
        medarbejdere: [],
        alerts: (rensAlerts ? null : state.alerts)
      };
    case constants.HENT_MEDARBEJDERE_SUCCESS:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_medarbejdere_success,
        medarbejdere: action.medarbejdere,
        selectedMedarbejderRows: []
      };
    case constants.HENT_MEDARBEJDERE_FEJL:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_medarbejdere_fejl,
        alerts: action.alerts
      };

    case constants.HENT_MEDARBEJDER:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_medarbejder_start,
        henterMedarbejder: action.henterMedarbejder,
        alerts: null
      };
    case constants.HENT_MEDARBEJDER_SUCCESS:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_medarbejder_success,
        instnr: action.instnr,
        redigerMedarbejder: action.hentetMedarbejder,
        selectedMedarbejderRows: [],
        alerts: null
      };
    case constants.HENT_MEDARBEJDER_FEJL:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_medarbejder_fejl,
        alerts: action.alerts
      };

    case constants.OPRET_MEDARBEJDER:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_opret_medarbejder_start,
        opretterMedarbejder: action.opretterMedarbejder,
        alerts: null
      };
    case constants.OPRET_MEDARBEJDER_SUCCESS:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_opret_medarbejder_success,
        nyMedarbejder: action.nyMedarbejder,
        redigerMedarbejder: null,
        alerts: action.alerts
      };
    case constants.OPRET_MEDARBEJDER_FEJL:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_opret_medarbejder_fejl,
        alerts: action.alerts
      };

    case constants.RET_MEDARBEJDER:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_ret_medarbejder_start,
        opretterMedarbejder: action.opretterMedarbejder,
        alerts: null
      };
    case constants.RET_MEDARBEJDER_SUCCESS:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_ret_medarbejder_success,
        rettetMedarbejder: action.rettetMedarbejder,
        redigerMedarbejder: null,
        alerts: action.alerts
      };
    case constants.RET_MEDARBEJDER_FEJL:
      return {
        ...state,
        status: constants.status_ret_medarbejder_fejl,
        alerts: action.alerts
      };

    case constants.OPRET_MEDARBEJDER_SIDE:
      return {...state, previousType: action.type, alerts: null};


    case constants.SLET_MEDARBEJDERE:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_slet_medarbejdere_start,
      };
    case constants.SLET_MEDARBEJDERE_SUCCES:
      let data = [...state.medarbejdere];
      let selectedMedarbejderRows = [...state.selectedMedarbejderRows];
      for (let i = 0; i < selectedMedarbejderRows.length; i++) {
        let val = selectedMedarbejderRows[i];
        let index = data.findIndex(function (item) {
          return item.id === val
        });
        if (index !== -1) {
          data.splice(index, 1);
        }
      }
      return {
        ...state,
        previousType: action.type,
        status: constants.status_slet_medarbejdere_succes,
        visSlet: action.visSlet,
        medarbejdere: data,
        selectedMedarbejderRows: [],
        showMedarbejderModal: false,
        showMedarbejderStamkortModal: false,
        alerts: action.alerts
      };
    case constants.SLET_MEDARBEJDERE_FEJL:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_slet_medarbejdere_fejl,
        alerts: action.alerts
      };

    case constants.MEDARBEJDER_ROW_CLICK: {

      let removeElement = function (element) {
        const index = this.indexOf(element);
        if (index !== -1) {
          this.splice(index, 1);
        }
      };

      let pushIfNotExistPopIfExists = function (array, element) {
        if (!array.includes(element)) {
          array.push(element);
        } else {
          removeElement(array, element);
        }
      };

      let tempMedarbejderRows = [];
      if (state.selectedMedarbejderRows.length > 0) {
        tempMedarbejderRows = [...state.selectedMedarbejderRows];
      }
      pushIfNotExistPopIfExists(tempMedarbejderRows, action.rowId);

      return {...state, previousType: action.type, selectedMedarbejderRows: tempMedarbejderRows}
    }

    case constants.MEDARBEJDER_MODAL_SHOW_CLICK: {
      let result = {...state, previousType: action.type, showMedarbejderModal: false};
      if (state.selectedMedarbejderRows.length > 0) {
        let modalSwitch = !state.showMedarbejderModal;
        result = {...state, previousType: action.type, showMedarbejderModal: modalSwitch};
      }
      return result;
    }

    case constants.MEDARBEJDER_MODAL_CLOSE: {
      return {
        ...state,
        previousType: action.type,
        showMedarbejderModal: false
      };
    }

    case constants.RESET_SELECTED_MEDARBEJDER_ROWS: {
      return {
        ...state,
        previousType: action.type,
        selectedMedarbejderRows: []
      }
    }
    case constants.SET_MEDARBEJDER_AS_SELECTED: {
      let pushIfNotExist = function (array, element) {
        if (!array.includes(element)) {
          array.push(element);
        }
      };

      let tempMedarbejderRows = [];
      if (state.selectedMedarbejderRows.length > 0) {
        tempMedarbejderRows = [...state.selectedMedarbejderRows];
      }
      pushIfNotExist(tempMedarbejderRows, action.rowId);

      return {...state, previousType: action.type, selectedMedarbejderRows: tempMedarbejderRows}
    }

    case constants.MEDARBEJDER_STAMKORT_MODAL_SHOW_CLICK: {
      return {
        ...state,
        previousType: action.type,
        selectedMedarbejderRows: [action.medarbejderId],
        showMedarbejderStamkortModal: !state.showMedarbejderStamkortModal
      };
    }

    case constants.MEDARBEJDER_STAMKORT_MODAL_CLOSE_CLICK: {
      return {
        ...state,
        previousType: action.type,
        showMedarbejderStamkortModal: false,
        selectedMedarbejderRows: []
      };
    }

    default:
      return state;
  }
}
