import React, {Component} from "react";
import {Link} from "react-router-dom";
import constants from "../../util/constants";
import SortingChevron from "../../util/SortingChevron";
import Medarbejder from "./Medarbejder";
import EksportStatus from "../../util/EksportStatus";

class MedarbejderOversigt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSort: '',
      sortDirection: '',
    };
  }

  sortFornavn(medarbejdere) {
    let dir = 'asc';
    if (this.state.activeSort === 'fornavn' && this.state.sortDirection === 'asc') {
      medarbejdere.reverse();
      dir = 'desc';
    } else {
      medarbejdere.sort(function (a, b) {
        return MedarbejderOversigt.getFornavn(a).localeCompare(MedarbejderOversigt.getFornavn(b));
      });
    }
    this.setState({
      activeSort: 'fornavn',
      sortDirection: dir,
    });
  }

  sortEfternavn(medarbejdere) {
    let dir = 'asc';
    if (this.state.activeSort === 'efternavn' && this.state.sortDirection === 'asc') {
      medarbejdere.reverse();
      dir = 'desc';
    } else {
      medarbejdere.sort(function (a, b) {
        return MedarbejderOversigt.getEfternavn(a).localeCompare(MedarbejderOversigt.getEfternavn(b));
      });
    }
    this.setState({
      activeSort: 'efternavn',
      sortDirection: dir,
    });
  }

  sortImportStatus(medarbejdere) {
    let dir = 'asc';
    if (this.state.activeSort === 'importstatus' && this.state.sortDirection === 'asc') {
      medarbejdere.reverse();
      dir = 'desc';
    } else {
      medarbejdere.sort(function (a, b) {
        return b.senestAendret - a.senestAendret;
      });
    }
    this.setState({
      activeSort: 'importstatus',
      sortDirection: dir,
    });
  }

  sortRoller(medarbejdere) {
    let dir = 'asc';
    if (this.state.activeSort === 'roller' && this.state.sortDirection === 'asc') {
      medarbejdere.reverse();
      dir = 'desc';
    } else {
      medarbejdere.sort(function (a, b) {
        if (a.medarbejder && b.medarbejder)
          return a.medarbejder.roller.join().localeCompare(b.medarbejder.roller.join());
        return 0;
      });
    }
    this.setState({
      activeSort: 'roller',
      sortDirection: dir,
    });
  }


  static getFornavn(medarbejder) {
    return medarbejder.stamdata.beskyttet ? medarbejder.stamdata.aliasFornavn : medarbejder.stamdata.fornavn;
  }

  static getEfternavn(medarbejder) {
    return medarbejder.stamdata.beskyttet ? medarbejder.stamdata.aliasEfternavn : medarbejder.stamdata.efternavn;
  }

  render() {
    const {
      medarbejdere, senesteEksport, medarbejderRowClick, selectedMedarbejderRows, visMedarbejderModal,
      resetSelectedMedarbejderRows, setMedarbejderAsSelected, sletMedarbejdere, visMedarbejderStamkortModal, openSletMedarbejderModal
    } = this.props;
    let selectedMedarbejderRowsLength = 0;
    if (selectedMedarbejderRows !== undefined) {
      selectedMedarbejderRowsLength = Object.keys(selectedMedarbejderRows).length;
    }

    let antalMedarbejdere = 0;
    for (let i = 0; i < medarbejdere.length; i++) {
      if (medarbejdere[i].medarbejder)
        ++antalMedarbejdere;
    }

    if (antalMedarbejdere === 0)
      return (
        <div>
          <h2>Medarbejdere</h2>
          <div>
            Der er ikke oprettet medarbejdere. Brug knappen "Ny medarbejder".
          </div>
          <div className="buttongroup">
            <Link id="button-ny-medarbejder" to={constants.urlOpretMedarbejder} className="stil-primary-button">Ny
              Medarbejder</Link>
          </div>
        </div>
      );

    return (
      <div>
        <h2>Medarbejdere</h2>
        <div className="buttongroup">
          <Link id="button-ny-medarbejder" to={constants.urlOpretMedarbejder} className="stil-primary-button">Ny
            medarbejder</Link>
        </div>
        <table id={"medarbejder-table"}>
          <tbody>
          <tr>
            <th id="header-Fornavn" onClick={() => this.sortFornavn(medarbejdere)}>
              Fornavn&nbsp;<SortingChevron active={this.state.activeSort === 'fornavn'}
                                           direction={this.state.sortDirection}/>
            </th>
            <th id="header-Efternavn" onClick={() => this.sortEfternavn(medarbejdere)}>
              Efternavn&nbsp;<SortingChevron active={this.state.activeSort === 'efternavn'}
                                             direction={this.state.sortDirection}/>
            </th>
            <th id="header-rolle" onClick={() => this.sortRoller(medarbejdere)}>
              Rolle&nbsp;<SortingChevron active={this.state.activeSort === 'roller'}
                                         direction={this.state.sortDirection}/>
            </th>
            <th id="header-Importstatus" onClick={() => this.sortImportStatus(medarbejdere)}>
              <div className="standard-tooltip">
                Importstatus&nbsp;<SortingChevron active={this.state.activeSort === 'importstatus'}
                                                  direction={this.state.sortDirection}/>
                <span className="standard-tooltiptext">
                    <EksportStatus senesteEksport={senesteEksport}/>
                  </span>
              </div>
            </th>
            <th>
              <img id="button-vis-medarbejdere" title="Vis valgte medarbejdere" className="visIkonEleverTop hand"
                   src="img/ic_visibility_24px_hvid.png" onClick={visMedarbejderModal} alt="vis valgte"/>&nbsp;
              <img id="button-slet-valgte" title="Slet valgte medarbejdere" className="sletIkonEleverTop hand"
                   src="img/ic_delete_24px_hvid.png" onClick={() => openSletMedarbejderModal()} alt="slet valgte"/>&nbsp;
              <span className="selectedCounter">({selectedMedarbejderRowsLength})</span>
            </th>
          </tr>
          {medarbejdere.filter(medarbejder => medarbejder.medarbejder).map(medarbejder => {
              return (
                <Medarbejder
                  key={medarbejder.lokalId}
                  medarbejder={medarbejder}
                  sletMedarbejdere={sletMedarbejdere}
                  medarbejderRowClick={medarbejderRowClick}
                  selectedMedarbejderRows={selectedMedarbejderRows}
                  resetSelectedMedarbejderRows={resetSelectedMedarbejderRows}
                  setMedarbejderAsSelected={setMedarbejderAsSelected}
                  visMedarbejderStamkortModal={visMedarbejderStamkortModal}
                  senesteEksport={senesteEksport}
                  openSletMedarbejderModal={openSletMedarbejderModal}
                />
              );
          })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default MedarbejderOversigt;