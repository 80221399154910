import React, {Component} from 'react';
import Klasse from "./Klasse.js";
import constants from "../../util/constants.js";
import {Link} from 'react-router-dom';
import {isEmptyStr} from "../../util/util.js";
import KlasseSletModal from "../../modals/KlasseSletModal.js";
import SortingChevron from "../../util/SortingChevron";
import EksportStatus from "../../util/EksportStatus";


function OprulKnap(props) {
  const antal = props.antal;

  if (antal === 0)
    return null;

  return (
    <Link id="oprul-klasser-button" to={constants.urlOprulning} className="stil-primary-button margleft10">Oprul alle klasser</Link>
  );
}


class Klasser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateNew: false,
      opretNyDisabled: false,
      modal: false,
      sortDirection: '',
      activeSort: '',
    };
    this.toggle = this.toggle.bind(this);
    this.visNyKlasse = this.visNyKlasse.bind(this);
    this.skjulNyKlasse = this.skjulNyKlasse.bind(this);
    this.sorterTrin = this.sorterTrin.bind(this);
    this.sorterBetegnelse = this.sorterBetegnelse.bind(this);
    this.sorterAntal = this.sorterAntal.bind(this);
    this.sorterImportStatus = this.sorterImportStatus.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  skjulNyKlasse() {
    this.setState({
      showCreateNew: false,
    });
  }

  visNyKlasse() {
    this.setState({
      showCreateNew: true,
    });
  }

  componentWillReceiveProps(nextProps){

    if (!isEmptyStr(nextProps.status) && nextProps.status === constants.status_ny_klasse)
      this.visNyKlasse();

    if(this.props.status !== constants.status_hent_klasser_succes
      && nextProps.status === constants.status_hent_klasser_succes) {
      if (this.state.activeSort === '') {
        this.sorterTrin(nextProps.klasser);
      } else { //Vi sikrer at efter reload af data, så er sorteringen af klasser stadig den samme.
        switch (this.state.activeSort) {
          case 'trin':
            this.sorterTrin(nextProps.klasser, true);
            break;
          case 'bogstav':
            this.sorterBetegnelse(nextProps.klasser, true);
            break;
          case 'antal':
            this.sorterAntal(nextProps.klasser, true);
            break;
          default:
            break;
        }
      }
    }
  }

  isAscendingOrDescending(keepDir){
    return (!keepDir && this.state.sortDirection === 'asc')
      || (keepDir && this.state.sortDirection === 'desc');
  }

  sorterTrin(klasser, keepDir=false){
    let dir = 'asc';
    if(this.state.activeSort === 'trin'
      && this.isAscendingOrDescending(keepDir)){
      klasser.sort(function(a, b){
        return b.niveau - a.niveau;
      });
      dir = 'desc';
    }else {
      klasser.sort(function(a, b){
        return a.niveau - b.niveau;
      });
    }

    this.setState({
      activeSort: 'trin',
      sortDirection: dir,
    })
  }

  sorterBetegnelse(klasser, keepDir=false){
    let dir = 'asc';
    if(this.state.activeSort === 'bogstav'
      && this.isAscendingOrDescending(keepDir)){
      klasser.sort(function(a, b){
        return b.gruppebetegnelse.localeCompare(a.gruppebetegnelse);
      });
      dir = 'desc';
    }else{
      klasser.sort(function(a, b){
        return a.gruppebetegnelse.localeCompare(b.gruppebetegnelse);
      });
    }

    this.setState({
      activeSort: 'bogstav',
      sortDirection: dir,
    })
  }

  sorterImportStatus(klasser, keepDir=false){
    let dir = 'asc';
    if(this.state.activeSort === 'importstatus'
      && this.isAscendingOrDescending(keepDir)){
      klasser.sort(function(a, b){
        return a.senestAendret - b.senestAendret;
      });
      dir = 'desc';
    }else{
      klasser.sort(function(a, b){
        return b.senestAendret - a.senestAendret;
      });
    }

    this.setState({
      activeSort: 'importstatus',
      sortDirection: dir,
    })
  }

  sorterAntal(klasser, keepDir=false){
    let dir = 'asc';
    if(this.state.activeSort === 'antal'
      && this.isAscendingOrDescending(keepDir)){
      klasser.sort(function(a, b){
        return b.antalElever - a.antalElever;
      });
      dir = 'desc';
    }else{
      klasser.sort(function(a, b){
        return a.antalElever - b.antalElever;
      });
    }
    this.setState({
      activeSort: 'antal',
      sortDirection: dir,
    });
  }

  render() {
    const {klasser, instkode, gemKlasse, selectedKlasseRows, klasseRowClick, sletKlasser, showKlasseModal, klasseSletMarkeredeClick, klasseSletEnkeltRowClick, klasseSletModalClose, setKlasseAsSelected, validerKlasseFejl, hentKlasser, nyKlasse, retKlasse, klasseId, senesteEksport} = this.props;

    if (klasser === null || klasser === undefined)
      return null;

    let selectedKlasseRowsLength = 0;
    if (selectedKlasseRows !== undefined) {
      selectedKlasseRowsLength = Object.keys(selectedKlasseRows).length;
    }

    return (
      <div>
        <div className="buttongroup">
          <button type="button" id="button-ny-klasse" className="stil-primary-button button"
                  onClick={nyKlasse} disabled={this.state.showCreateNew}>Opret ny klasse
          </button>
          <OprulKnap antal={klasser.length} />
        </div>
        <table id={"klasse-table"}>
          <tbody>
          <tr>
            <th onClick={() => this.sorterTrin(klasser)}>Klassetrin&nbsp;
              <SortingChevron active={this.state.activeSort==='trin'} direction={this.state.sortDirection}/>
            </th>
            <th onClick={() => this.sorterBetegnelse(klasser)}>Klassenavn/bogstav&nbsp;
              <SortingChevron active={this.state.activeSort==='bogstav'} direction={this.state.sortDirection}/>
            </th>
            <th onClick={() => this.sorterAntal(klasser)}>Antal elever&nbsp;
              <SortingChevron active={this.state.activeSort==='antal'} direction={this.state.sortDirection}/>
            </th>
            <th onClick={() => this.sorterImportStatus(klasser)}>
              <div className="standard-tooltip">
                Importstatus&nbsp;<SortingChevron active={this.state.activeSort==='importstatus'} direction={this.state.sortDirection}/>
                <span className="standard-tooltiptext">
                  <EksportStatus senesteEksport={senesteEksport}/>
                </span>
              </div>
            </th>
            <th>
              <img id="button-slet-valgte" title="Slet valgte klasser" className="sletIkonKlasserTop hand" src="img/ic_delete_24px_hvid.png"
                   onClick={() => klasseSletMarkeredeClick()} alt="slet valgte"/>&nbsp;
              <span className="selectedCounter">({selectedKlasseRowsLength})</span>
            </th>
          </tr>
          {klasser.map(klasse => {
            return <Klasse
              klasse={klasse}
              key={klasse.id}
              gemKlasse={gemKlasse}
              instkode={instkode}
              klasseRowClick={klasseRowClick}
              sletKlasser={sletKlasser}
              senesteEksport={senesteEksport}
              klasseSletEnkeltRowClick={klasseSletEnkeltRowClick}
              setKlasseAsSelected={setKlasseAsSelected}
              selectedKlasseRows={selectedKlasseRows}
              validerKlasseFejl={validerKlasseFejl}
              hentKlasser={hentKlasser}
              retKlasse={retKlasse}
              klasseId={klasseId}
            />
          })}
          <Klasse klasse={null} key="new" gemKlasse={gemKlasse} skjulNyKlasse={this.skjulNyKlasse} instkode={instkode}
                  validerKlasseFejl={validerKlasseFejl} hentKlasser={hentKlasser} show={this.state.showCreateNew}/>
          </tbody>
        </table>
        <KlasseSletModal
          klasser={klasser}
          selectedKlasseRows={selectedKlasseRows}
          sletKlasser={sletKlasser}
          showKlasseModal={showKlasseModal}
          klasseSletModalClose={klasseSletModalClose}
        />
      </div>
    );
  }

}

export default Klasser;
