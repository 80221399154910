import constants from '../util/constants.js';

function doRensAlerts(prevType) {
  return (
    prevType === constants.HENT_KLASSER_SUCCES ||
    prevType === constants.HENT_KLASSER_FEJL ||
    prevType === constants.VALIDER_KLASSE_FEJL ||
    prevType === constants.GEM_KLASSE_FEJL ||
    prevType === constants.SLET_KLASSER_FEJL
  );
}

export function klasseReducer(state = [], action) {

  let rensAlerts = doRensAlerts(state.previousType);

  switch (action.type) {
    case constants.HENT_KLASSER:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_klasser_start,
        klasseId: null,
        henterKlasser: action.henterKlasser,
        alerts: (rensAlerts ? null : state.alerts)
      };
    case constants.HENT_KLASSER_SUCCES:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_klasser_succes,
        klasser: action.klasser
      };
    case constants.HENT_KLASSER_FEJL:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_hent_klasser_fejl,
        hentKlasserFejl: action.hentKlasserFejl,
        alerts: action.alerts
      };
    case constants.NY_KLASSE:
      return {...state, previousType: action.type, status: constants.status_ny_klasse, alerts: null};
    case constants.RET_KLASSE:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_ret_klasse,
        alerts: null,
        klasseId: action.klasseId
      };
    case constants.GEM_KLASSE:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_gem_klasse_start,
        gemmerKlasse: action.gemmerKlasse
      };
    case constants.GEM_KLASSE_SUCCES:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_gem_klasse_succes,
        nyKlasse: action.nyKlasse,
        alerts: action.alerts
      };
    case constants.GEM_KLASSE_FEJL:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_gem_klasse_fejl,
        gemKlasseFejl: action.gemKlasseFejl,
        alerts: action.alerts
      };
    case constants.VIS_SLET_KLASSER:
      return {...state, previousType: action.type, visSlet: action.visSlet, klasserSlet: action.klasserSlet};
    case constants.SKJUL_SLET_KLASSER:
      return {...state, previousType: action.type, visSlet: action.visSlet, klasserSlet: []};
    case constants.SLET_KLASSER:
      return {...state, previousType: action.type, sletterKlasser: action.sletterKlasser};
    case constants.VALIDER_KLASSE_FEJL:
      return {...state, previousType: action.type, alerts: action.alerts};
    case constants.SLET_KLASSER_SUCCES:
      let selectedKlasseRows = [...state.selectedKlasseRows];
      for (let i = 0; i < action.klasser.length; i++) {
        const index = selectedKlasseRows.indexOf(action.klasser[i]);
        if (index !== -1) {
          selectedKlasseRows.splice(index, 1);
        }
      }
      return {
        ...state,
        previousType: action.type,
        status: constants.status_slet_klasser_succes,
        klasserSlet: action.klasserSlet,
        visSlet: action.visSlet,
        selectedKlasseRows: selectedKlasseRows,
        alerts: action.alerts
      };
    case constants.SLET_KLASSER_FEJL:
      return {
        ...state,
        previousType: action.type,
        status: constants.status_slet_klasser_fejl,
        sletKlasserFejl: action.sletKlasserFejl,
        alerts: action.alerts
      };
    case constants.KLASSE_ROW_CLICK: {
      let removeElement = function (array, element) {
        const index = array.indexOf(element);
        if (index !== -1) {
          array.splice(index, 1);
        }
      };

      let pushIfNotExistPopIfExists = function (array, element) {
        if (!array.includes(element)) {
          array.push(element);
        } else {
          removeElement(array, element);
        }
      };

      let tempKlasseRows = [];
      if (state.selectedKlasseRows.length > 0) {
        tempKlasseRows = [...state.selectedKlasseRows];
      }
      pushIfNotExistPopIfExists(tempKlasseRows, action.rowId);

      return {...state, previousType: action.type, selectedKlasseRows: tempKlasseRows}
    }
    case constants.RESET_SELECTED_KLASSER_ROWS: {
      return {...state, selectedKlasseRows: []}
    }
    case constants.SET_KLASSE_AS_SELECTED: {
      let pushIfNotExist = function (array, element) {
        if (!array.includes(element)) {
          array.push(element);
        }
      }

      let tempKlasseRows = [];
      if (state.selectedKlasseRows.length > 0) {
        tempKlasseRows = [...state.selectedKlasseRows];
      }
      pushIfNotExist(tempKlasseRows, action.rowId);

      return {...state, previousType: action.type, selectedKlasseRows: tempKlasseRows}

    }


    case constants.RESET_SELECTED_KLASSE_ROWS: {
      return {...state, previousType: action.type, selectedKlasseRows: []}
    }

    case constants.KLASSE_SLET_MARKEREDE_CLICK:
    case constants.KLASSE_SLET_ENKELT_ROW_CLICK: {
      return {...state, previousType: action.type, showKlasseModal: true};
    }

    case constants.KLASSE_SLET_MODAL_CLOSE: {
      return {...state, previousType: action.type, showKlasseModal: false};
    }

    default:
      return state;
  }
}
