import {connect} from 'react-redux'
import ForsideMain from "./ForsideMain";
import {hentAnnouncement} from '../actions/vejledning'
import * as loginActions from "../actions/login";

const mapStateToProps = (state) => {
  return {
    login: state.login,
    announcement: state.app.announcement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginClear: () => dispatch(loginActions.loginClear()),
    hentAnnouncement: () => dispatch(hentAnnouncement()),
    openSelectInstitutionModal: () => dispatch(loginActions.openSelectInstitutionModal()),
  }
};

const ForsideConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForsideMain);

export default ForsideConnector;
