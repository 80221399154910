import React, {Component} from 'react'

class ElevOplysninger extends Component {

  fornavn(){
    const {stamdata} = this.props.elev;
    if(stamdata.beskyttet)
        return(
        <div className="standard-tooltip">
            {stamdata.aliasFornavn}
            <span className="standard-tooltiptext">
            {stamdata.fornavn}
            </span>
        </div>);
    else
        return stamdata.fornavn;
  }

  efternavn(){
    const {stamdata} = this.props.elev;
      if(stamdata.beskyttet)
        return(
        <div className="standard-tooltip">
            {stamdata.aliasEfternavn}
            <span className="standard-tooltiptext">
            {stamdata.efternavn}
            </span>
        </div>);
    else
        return stamdata.efternavn;
  }

  mobiltelefon(){
    const {stamdata} = this.props.elev;
    const {telefoner} = this.props.elev.stamdata;
    let mobiltelefoner = telefoner.filter(telefon => telefon.type==='Mobil');

    if (mobiltelefoner !== null && mobiltelefoner !== undefined && mobiltelefoner.length > 0) {
        if(mobiltelefoner[0].beskyttet === true){
          return(
          <div className="standard-tooltip">
              Beskyttet
            <span className="standard-tooltiptext">
                {mobiltelefoner[0].nummer}
            </span>
          </div>);
        }else if (stamdata.beskyttet){
          return(
            <div className="standard-tooltip">
              Navnebeskyttet
              <span className="standard-tooltiptext">
                {mobiltelefoner[0].nummer}
              </span>
            </div>
            );
        }else{
          return mobiltelefoner[0].nummer;
        }
    }else{
      return "";
    }
  }

  email(){
    const {stamdata} = this.props.elev;

    if(stamdata.beskyttet)
        return(
            <div className="standard-tooltip">
                Navnebeskyttet
            <span className="standard-tooltiptext">{stamdata.email}</span>
              </div>);
    else
        return stamdata.email;
  }

  adresse(){
    const {stamdata} = this.props.elev;

        if(stamdata.beskyttet)
          return(
            <div className="standard-tooltip">
                Navnebeskyttet
                <span className="standard-tooltiptext">
                    {stamdata.vej + " - " + stamdata.postnr + " " + stamdata.postby}
                </span>
            </div>);
        else
            return stamdata.vej + " - " + stamdata.postnr + " " + stamdata.postby;
  }

  render(){
    const {elev} = this.props;
    return(
      <tr>
        <td>{this.fornavn()}</td>
        <td>{this.efternavn()}</td>
        <td>{elev.elev.klasse.gruppenavn}</td>
        <td>{this.mobiltelefon()}</td>
        <td>{this.email()}</td>
        <td>{this.adresse()}</td>
        <td>{elev.uniId ? elev.uniId : ""}</td>
      </tr>
    );
  }
}

export default ElevOplysninger;
